import { Box, Typography } from '@mui/material';
import CrossedOutCell from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/CrossedOutCell';
import { instanceOf } from 'prop-types';
import React from 'react';
import { durationAndEffectiveDates } from './styles';

export default function DurationAndEffectiveDate({ row = {} }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'start' }}>
      <Box sx={durationAndEffectiveDates}>
        {(row.oldDuration || row.oldDuration === 0) && (
          <CrossedOutCell>
            <Typography component="span" sx={{ width: 'max-content' }}>
              {row.oldDuration} min
            </Typography>
          </CrossedOutCell>
        )}
        <Typography
          sx={{
            width: 'max-content',
            fontWeight: row.oldDuration || row.oldDuration === 0 ? 600 : 400,
          }}
        >
          {row?.duration !== null ? `${row?.duration} min` : '0 min'}
        </Typography>
      </Box>
    </Box>
  );
}

DurationAndEffectiveDate.propTypes = {
  row: instanceOf(Object),
};
