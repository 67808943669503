import { Box, CircularProgress, Typography } from '@mui/material';
import moment from 'moment';
import hardConstraintsWarning from '../../../../img/icons/error-icon.svg';
import WarningIconSchedule from '../../../../img/icons/warning-icon-shcedule.svg';
import { PREFERRED_TITLE } from '../../../../pages/Scheduling/constants';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import CustomDialog from '../../../../components/Dialog';
import SuccessResult from './SuccessResult';
import FailedResult from './FailedResult';

export default function RescheduleModalBackend({
  checkPossibilityData = [],
  closeDialog = () => {},
  disabledSubmit = false,
  isLoading = false,
  openDialog = false,
  submitDialog = () => {},
  updatedVisitData = {},
}) {
  const hardConstraints = checkPossibilityData?.violatedConstraints;
  const softConstraints = checkPossibilityData?.violatedSoftConstraints;

  const successResult =
  hardConstraints?.length === 0 &&
    checkPossibilityData?.visitAvailable === true;
  const successButton =  'Confirm';

  const submitAction = () => {
    submitDialog(
      checkPossibilityData?.newTravelTime ? checkPossibilityData?.newTravelTime : 15,
      checkPossibilityData?.violatedConstraints,
      checkPossibilityData?.violatedSoftConstraints
    );
  }

  return (
    <div>
      <CustomDialog
        cancelButtonName={'Cancel'}
        cancelCallback={closeDialog}
        disableOnCloseByClickOutside
        openDialog={openDialog}
        disabledSubmit={disabledSubmit}
        submitButtonName={successResult ? successButton : 'OK'}
        submitCallback={submitAction}
        title="Confirmation"
      >
        <Box>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography sx={{ ml: '30px', maxWidth: '470px' }} variant="body1">
                New Arrival Time is {moment(updatedVisitData?.arrivalTime, "YYYY-MM-DDTh:mm:ss").format('hh:mm A')}
              </Typography>
              <Typography sx={{ ml: '30px', maxWidth: '470px', mb: '15px'}} variant="body1">
                With a new travel time of {checkPossibilityData?.newTravelTime} minutes
              </Typography>
            </>
          )}
          {checkPossibilityData?.intersectWithShadowVisit ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 28px 28px 28px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: '6px' }}>
                <img src={hardConstraintsWarning} alt="" />
                <Typography sx={{ ml: '6px', maxWidth: '470px' }} variant="body1">
                  You are attempting to schedule a visit that conflicts with an existing shadow
                  visit on the caregiver&apos;s calendar.
                </Typography>
              </Box>
              <Typography sx={{ pl: '20px' }}>
                This action is restricted.{successResult ? ' Do you want to continue?' : ''}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 28px 28px 28px',
              }}
            >
              {hardConstraints?.length > 0  && (
                <Box>
                  {hardConstraints.map(title => (
                    <Box key={title} sx={{ display: 'flex', alignItems: 'center', mb: '6px'}}>
                        <img src={hardConstraintsWarning} alt="" />
                      <Typography sx={{ ml: '6px', maxWidth: '470px' }} variant="body1">
                        {title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              {softConstraints?.length > 0  && (
                <Box>
                  {softConstraints.map(title => (
                    <Box key={title} sx={{ display: 'flex', alignItems: 'center', mb: '6px' }}>
                      <img src={WarningIconSchedule} alt="" />
                      <Typography sx={{ ml: '6px', maxWidth: '470px' }} variant="body1">
                        {title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              <SuccessResult hasConstraints={checkPossibilityData?.violatedConstraints?.length}/>
            </Box>
          )}
        </Box>
      </CustomDialog>
    </div>
  );
}

RescheduleModalBackend.propTypes = {
  openDialog: bool,
  checkPossibilityData: instanceOf(Object),
  closeDialog: func,
  isLoading: bool,
  submitDialog: func,
  disabledSubmit: bool,
  updatedVisitData: instanceOf(Object),
};
