import moment from 'moment';
import {
  H12_TIME_FORMAT,
  H24_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
} from '../../../../../../../../../shared/constants';

export const dateHandler = dateString => {
  if (dateString.includes('AM') || dateString.includes('PM')) {
    return dateString;
  } else {
    return moment(dateString, H24_TIME_FORMAT).format(H12_TIME_FORMAT);
  }
};

export const getSummaryData = data => {
  const { effectiveDate, userData, visitData } = data;
  const arrivalWindow = {};

  const oldArrivalWindowKeys = Object.keys(data?.temporaryVisitData?.days ?? {});
  if (
    oldArrivalWindowKeys.length &&
    data?.temporaryVisitData?.days[oldArrivalWindowKeys[0]]?.arrivalStart
  ) {
    const oldArrivalWindow = data?.temporaryVisitData?.days[oldArrivalWindowKeys[0]];
    arrivalWindow.oldWindow = `${dateHandler(oldArrivalWindow?.arrivalStart)} - ${dateHandler(
      oldArrivalWindow?.arrivalEnd,
    )}`;
  }

  const newArrivalWindowKeys = Object.keys(data.selectedTimeSlots ?? {});
  if (
    newArrivalWindowKeys.length &&
    data.selectedTimeSlots[newArrivalWindowKeys[0]]?.arrivalStart
  ) {
    const newArrivalWindow = data.selectedTimeSlots[newArrivalWindowKeys[0]];
    arrivalWindow.newWindow = `${dateHandler(newArrivalWindow?.arrivalStart)} - ${dateHandler(
      newArrivalWindow?.arrivalEnd,
    )}`;
  }

  return {
    client: `${userData?.firstName} ${userData?.lastName}`,
    address: `${userData?.street} ${userData?.city}, ${userData?.state}, ${userData?.zipCode}, ${userData?.country}`,
    visitType: visitData?.visitTypeDetails.name,
    duration: visitData?.duration,
    recurrence: visitData?.recurrence,
    effectiveDate: moment(effectiveDate, ISO_DATE_ONLY_FORMAT).format(SHORT_DATE_FORMAT),
    arrivalWindow,
  };
};
