import { Box } from '@mui/material';
import moment from 'moment/moment';
import { CreateVisitContext } from '../../../../../../../../../../pages/ClientDetails/context';
import AffectedVisitsAccordion from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/ArrivalWindowsAccordion/AffectedVisitsAccordion';
import React, { useCallback, useContext, useMemo } from 'react';
import { H12_TIME_FORMAT } from '../../../../../../../../../../shared/constants';
import ConstantTableItem from './ArrivalWindowsTableItmes/ConstantTableItem';
import SelectableTimeSlot from './ArrivalWindowsTableItmes/SelectableTimeSlot';
import { convertDaysToNames } from '../../../../../../constants';

export default function AvailabilityWindowsBody() {
  const { availabilityScheduling, selectedTimeSlots, visitData, changeSelectedSlots } =
    useContext(CreateVisitContext);

  const changeSlots = newTimeSlot => {
    if (!availabilityScheduling) {
      return '';
    }
    changeSelectedSlots(newTimeSlot);
    return '';
  };

  const availabilitiesByTimesOfDay = useMemo(() => {
    const availabilitiesMap = {
      morning: [],
      midday: [],
      afternoon: [],
    };
    if (!availabilityScheduling?.availableArrivalWindows) {
      return availabilitiesMap;
    }
    return availabilityScheduling.timeOfDays.reduce((obj, item) => {
      const availabilitiesList = availabilityScheduling?.availableArrivalWindows.filter(
        ({ arrivalStart }) =>
          moment(arrivalStart, H12_TIME_FORMAT).isBetween(
            moment(item.from, H12_TIME_FORMAT),
            moment(item.to, H12_TIME_FORMAT),
            'minutes',
            '[)',
          ),
      );
      return Object.assign(obj, {
        [item.name]: availabilitiesList,
      });
    }, {});
  }, [availabilityScheduling]);

  const checkIsTimeSlotSelected = useCallback(
    ({ arrivalEnd, arrivalStart }) => {
      const selectedTimeSlotKeys = Object.keys(selectedTimeSlots ?? {});
      if (selectedTimeSlotKeys.length) {
        const selectedAvailability = selectedTimeSlots[selectedTimeSlotKeys[0]];
        return (
          arrivalStart === selectedAvailability.arrivalStart &&
          arrivalEnd === selectedAvailability.arrivalEnd
        );
      }
      return false;
    },
    [selectedTimeSlots],
  );
  const dayNames = [];
  for (const day in Object.keys(visitData.days)) {
    dayNames.push(convertDaysToNames[Object.keys(visitData.days)[day]]);
  }

  const sortedTimeOfDays = [];
  const nameList = ['Early Morning', 'Mid Morning', 'Morning', 'Midday', 'Afternoon', 'Evening'];
  for (const name of nameList) {
    for (const timeOfDay of availabilityScheduling.timeOfDays) {
      if (timeOfDay.name === name) {
        sortedTimeOfDays.push(timeOfDay);
      }
    }
  }

  return sortedTimeOfDays.map(timeOfDay => (
    <AffectedVisitsAccordion
      key={timeOfDay.name}
      title={`${timeOfDay.name}: ${timeOfDay.from} - ${timeOfDay.to}`}
    >
      {availabilitiesByTimesOfDay[timeOfDay.name]?.map(({ arrivalEnd, arrivalStart, weekdays }) => (
        <Box key={`${arrivalEnd}-${arrivalStart}`} sx={{ display: 'flex' }}>
          {[`${arrivalStart} - ${arrivalEnd}`, '0'].map((item, index) =>
            !index ? (
              <ConstantTableItem key={item} isArrivalWindowItem={!index} name={item} />
            ) : (
              <SelectableTimeSlot
                key={item}
                blocked={!weekdays?.length}
                fullAvailable={weekdays?.length > 0}
                onSelect={() =>
                  changeSlots({
                    arrivalEnd,
                    arrivalStart,
                  })
                }
                selected={checkIsTimeSlotSelected({
                  arrivalEnd,
                  arrivalStart,
                })}
                semiAvailable={weekdays?.includes(item)}
              />
            ),
          )}
        </Box>
      ))}
    </AffectedVisitsAccordion>
  ));
}
