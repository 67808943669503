import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useGetWeekendsSchedulingDaysQuery } from '../../../../../../../../../../api/Administration/api';
import { useGetConfigQuery } from '../../../../../../../../../../api/Config/api';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AlertContext from '../../../../../../../../../../components/Alert';
import MultilineInput from '../../../../../../../../../../components/Inputs/MultilineInput';
import Loader from '../../../../../../../../../../components/Loader';
import { MAX_LONG_TEXT_DESCRIPTION_FIELD } from '../constants';
import ArrivalTimeSelect from './ArrivalTimeSelect';
import ClientData from './ClientData';
import DaysSchedule from './DaysSchedule';
import StepTitle from './StepTitle';
import VisitTypeBlock from './VisitTypeBlock';
import { sortObjectTimeOfDays } from '../../../../../../../../../../shared/utils/common';

export default function VisitParameters({
  setVisitData = () => {},
  visitData = {
    recurrence: 'Weekly',
    changesType: 'add',
    arrivalWindowDuration: '60',
  },
  setSelectedTimeSlots = () => {},
  setCompletedSteps = () => {},
  setStepDataLoading = () => {},
}) {
  if (!visitData) {
    visitData = {
      recurrence: 'Weekly',
      changesType: 'add',
      arrivalWindowDuration: '60',
    };
  }
  const { setAlert } = useContext(AlertContext);
  const [showVisitNotes, setShowVisitNotes] = useState(false);
  const [configs, setConfigs] = useState(null);
  const {
    data: configData,
    isLoading: isLoadingConfigs,
    error: onMountOrArgChangeError,
  } = useGetConfigQuery(['visitTypes', 'timeOfDays']);

  useEffect(() => {
    if (configData) {
      const tempConfigData = { ...configData };

      let tempVisitTypes = [];
      if (tempConfigData.visitTypes) {
        tempVisitTypes = tempConfigData.visitTypes.filter(
          visitType => visitType.name !== 'Lunch Break',
        );
      }

      let tempTimeOfDays = [];
      if (tempConfigData.timeOfDays) {
        tempTimeOfDays = sortObjectTimeOfDays(tempConfigData);
      }

      setConfigs({ ...tempConfigData, visitTypes: tempVisitTypes, timeOfDays: tempTimeOfDays });
      setStepDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData]);

  useEffect(() => {
    setSelectedTimeSlots(null);
    setCompletedSteps([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: disabledWeekDays, error: getWeekdaysError } = useGetWeekendsSchedulingDaysQuery();

  useEffect(() => {
    if (onMountOrArgChangeError || getWeekdaysError) {
      const errorData = onMountOrArgChangeError || getWeekdaysError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getWeekdaysError, onMountOrArgChangeError, setAlert]);

  const changeDays = e => {
    let selectedDays = visitData?.days ? { ...visitData?.days } : {};
    if (selectedDays[e.target.name]) {
      delete selectedDays[e.target.name];
    } else {
      selectedDays[e.target.name] = {};
    }
    setVisitData({
      ...visitData,
      days: selectedDays,
    });
  };

  const onSelectChange = params => {
    if (params.field === 'visitType') {
      const visitTypeDetails = configs.visitTypes.filter(
        visitType => params.name === visitType.name,
      );
      const visitTypeId = visitTypeDetails.map(skill => skill.id)[0];
      setVisitData({
        ...visitData,
        visitType: visitTypeId,
        duration: visitTypeDetails[0].duration,
        visitTypeDetails: visitTypeDetails[0],
      });
      return '';
    }
    if (params.field === 'timeOfDays') {
      const timesOfDaysDetails = [];
      for (let value in params.value) {
        if (typeof params.value[value] === 'string') {
          timesOfDaysDetails.push(
            configs.timeOfDays.filter(day => params.value[value] === day.id)[0],
          );
        } else if (typeof params.value[value] === 'object') {
          timesOfDaysDetails.push(params.value[value]);
        }
      }
      const timesOfDayIds = timesOfDaysDetails.map(skill => skill.id);
      setVisitData({
        ...visitData,
        timesOfDays: timesOfDayIds,
        timesOfDaysDetails: timesOfDaysDetails,
      });
      return;
    }
    if (params.field === 'genderPreference') {
      const genderDetails = configs.genders.filter(gender => params.name === gender.name);
      const genderId = genderDetails[0].id;
      setVisitData({
        ...visitData,
        genders: [genderId],
        gendersDetails: genderDetails,
      });
      return '';
    }
    if (params.field === 'preferredSkills') {
      const skillsDetails = configs.skills.filter(skill => params.value.includes(skill.id));
      const skillIds = skillsDetails.map(skill => skill.id);
      setVisitData({
        ...visitData,
        skills: skillIds,
        skillsDetails: skillsDetails,
      });
      return '';
    }
    if (params.field === 'preferredLanguages') {
      const languagesDetails = configs.languages.filter(language =>
        params.value.includes(language.id),
      );
      const languageIds = languagesDetails.map(language => language.id);
      setVisitData({
        ...visitData,
        languages: languageIds,
        languagesDetails: languagesDetails,
      });
      return '';
    }
    if (params.field === 'arrivalWindowDuration') {
      setVisitData({
        ...visitData,
        arrivalWindowDuration: params.name,
      });
      return '';
    }
  };

  const changeDuration = value => {
    setVisitData({ ...visitData, duration: value });
  };

  const changeDetails = e => {
    if (e.target.value.length <= MAX_LONG_TEXT_DESCRIPTION_FIELD) {
      setVisitData({
        ...visitData,
        [e.target.id]: e.target.value,
      });
    }
  };

  if (isLoadingConfigs) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '520px',
        width: '100%',
        margin: '36px auto 0 auto',
      }}
    >
      {configs && (
        <>
          <StepTitle />
          <ClientData />
          <Box sx={{ width: '100%', height: '8px' }} />
          <DaysSchedule
            disabledDays={disabledWeekDays?.schema}
            filtersData={configs}
            handleChangeDays={changeDays}
            onSelectChange={onSelectChange}
            visitData={visitData}
          />
          <Box sx={{ width: '100%', height: '24px' }} />
          <VisitTypeBlock
            changeDuration={changeDuration}
            changeVisitType={onSelectChange}
            filtersData={configs}
            visitData={visitData}
          />
          <Box sx={{ width: '100%', height: '24px' }} />
          {visitData && (
            <ArrivalTimeSelect
              changeArrivalWindowDuration={onSelectChange}
              changeTypeOfDay={onSelectChange}
              filtersData={configs}
              visitData={visitData}
            />
          )}
          <Box sx={{ width: '100%', height: '22px' }} />
          <FormControlLabel
            sx={{ marginBottom: '12px', maxWidth: '200px' }}
            control={<Checkbox checked={showVisitNotes} />}
            onChange={() => setShowVisitNotes(!showVisitNotes)}
            label="Additional Visit Details"
          />
          {showVisitNotes && (
            <MultilineInput
              changeDetails={changeDetails}
              id="visitNotes"
              label="Additional Visit Details"
              maxLength={MAX_LONG_TEXT_DESCRIPTION_FIELD}
              value={visitData?.visitNotes}
              showHelperText
            />
          )}
          <Box sx={{ width: '100%', height: '24px' }} />
        </>
      )}
    </Box>
  );
}

VisitParameters.propTypes = {
  setVisitData: func,
  visitData: instanceOf(Object),
  setSelectedTimeSlots: func,
  setCompletedSteps: func,
  setStepDataLoading: func,
};
