import SearchIcon from '@mui/icons-material/Search';
import ClearIconDark from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ONLY_NUMBERS_INPUTS_KEYS } from '../../../shared/constants';
import { customInputFilter } from './styles';

export default function SearchFilter({
  blocked = false,
  changeValue = () => {},
  name = '',
  placeholder = '',
  customFilterStyles = {},
  initialValue = '',
}) {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);

  const onChange = e => {
    const regex = /^[0-9\b]+$|^$/;
    if (!ONLY_NUMBERS_INPUTS_KEYS.includes(name) || regex.test(e.target.value)) {
      setValue(e.target.value || '');
      changeValue(e);
    }
  };

  const clearSearchFilter = () => {
    setValue('');
    changeValue({ target: { name: name, value: '' } });
  };

  return (
    <TextField
      disabled={blocked}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value?.length > 0 && (
              <IconButton
                aria-label="Clear Search"
                sx={{ padding: '4px', marginRight: '10px' }}
                onClick={clearSearchFilter}
              >
                <ClearIconDark />
              </IconButton>
            )}
            <SearchIcon fontSize="medium" />
          </InputAdornment>
        ),
      }}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      sx={{ ...customInputFilter, ...customFilterStyles }}
      value={value}
      variant="outlined"
    />
  );
}

SearchFilter.propTypes = {
  blocked: bool,
  changeValue: func,
  initialValue: string,
  name: string,
  placeholder: string,
  customFilterStyles: instanceOf(Object),
};
