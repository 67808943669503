import { Box, Button, Collapse, Typography } from '@mui/material';
import {
  durationComponentWrapper,
  infoKeyField,
  infoRowEditableInfoWrapper,
  infoRowWrapperStyles,
  inputContainer,
  outlinedButtonStyles,
  setupActionsWrapperStyles,
} from './styles';
import EditIconButton from '../../EditIconButton';
import { useState } from 'react';
import NumericInput from '../../../../NumericInput';
import OptimizerCheck from '../../../../Optimizer/OptimizerCheck';
import moment from 'moment';

export default function DurationComponent({
  visitInfoData,
  reFetchCalendarEvents,
  temporaryVisitData,
  actualVisitData,
  updateTemporaryVisitData,
  onUpdateVisitDetails,
}) {
  const [editing, setEditing] = useState(false);
  const [optimizerData, setOptimizerData] = useState({});
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);

  const handleDurationChange = value => {
    updateTemporaryVisitData({ duration: Number(value) });
  };

  const submitFunction = async () => {
    setEditing(false);
    reFetchCalendarEvents();
  };

  const handleCancel = () => {
    updateTemporaryVisitData({ duration: actualVisitData?.duration });
    setEditing(false);
  };

  const handleApply = () => {
    if (moment(actualVisitData?.date).isAfter(moment(), 'date')) {
      setOptimizerData(temporaryVisitData);
      setOpenCheckOptimizer(true);
    } else if (moment(actualVisitData?.date).isSame(moment(), 'date')) {
      onUpdateVisitDetails(temporaryVisitData);
      setEditing(false);
    }
  };

  return (
    <Box {...(editing && { sx: durationComponentWrapper })}>
      {!editing && (
        <Box sx={infoRowWrapperStyles}>
          <Typography sx={infoKeyField}>
            {visitInfoData?.actualDuration ? 'Actual' : 'Scheduled'} Duration:
          </Typography>
          <Box sx={infoRowEditableInfoWrapper}>
            <Typography variant="body1">
              {visitInfoData?.actualDuration ?? visitInfoData?.duration}
            </Typography>
            {moment(visitInfoData?.date).isSameOrAfter(moment(), 'date') && (
              <EditIconButton onEdit={() => setEditing(true)} tooltip="Edit actual start time" />
            )}
          </Box>
        </Box>
      )}
      <Collapse in={editing} timeout={300}>
        <Box sx={inputContainer}>
          <NumericInput
            changeValue={handleDurationChange}
            isRequired
            label="Scheduled Duration"
            oneTimeVisit
            step={5}
            value={temporaryVisitData?.duration}
            additionalStyles={{ width: '120px' }}
          />
        </Box>
        <Box sx={setupActionsWrapperStyles}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCancel}
            disableElevation
            sx={outlinedButtonStyles}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!temporaryVisitData?.duration}
            variant="contained"
            onClick={handleApply}
            disableElevation
          >
            Apply
          </Button>
        </Box>
        <OptimizerCheck
          data={{ added: [], edited: [optimizerData], deleted: [] }}
          dataObjName="visitObj"
          submitFunction={submitFunction}
          open={openCheckOptimizer}
          openToggle={setOpenCheckOptimizer}
          cancelFunction={() => setOptimizerData({})}
        />
      </Collapse>
    </Box>
  );
}
