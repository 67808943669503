import { Box } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import SearchFilter from '../../../../components/FiltersComponents/Search';
import { MultiselectWithBadge } from '../../../../components/MultiselectWithBadge';
import { additionalStyles } from './styles';

function ActivityFilters({
  activityOptions = [],
  debouncedSearch = () => {},
  filterData = {},
  setFilterData = () => {},
  userOptions = [],
  client = false,
}) {
  const onSelectChange = (params) => {
    setFilterData({
      ...filterData,
      name: filterData.name,
      [params.field]: params.value,
    });
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        columnGap: 3,
        rowGap: 1,
        mb: '20px',
      }}
    >
      <Box sx={{ maxWidth: '270px', width: '100%' }}>
        <SearchFilter
          changeValue={debouncedSearch}
          name="search"
          placeholder="Search"
          customFilterStyles={{ width: '100%' }}
        />
      </Box>
      <MultiselectWithBadge
        options={activityOptions}
        placeHolder="Activity"
        id="activity"
        hasSelectAllOption
        selectedValue={filterData.activity || []}
        setValue={onSelectChange}
        additionalStyles={additionalStyles}
      />
      {/* {!client && (
        <MultiselectWithBadge
          options={userOptions}
          placeHolder="User"
          id="user"
          hasSelectAllOption
          selectedValue={filterData.user || []}
          setValue={onSelectChange}
          additionalStyles={additionalStyles}
        />
      )} */}
    </Box>
  );
}

ActivityFilters.propTypes = {
  activityOptions: instanceOf(Array),
  debouncedSearch: func,
  filterData: instanceOf(Object),
  setFilterData: func,
  userOptions: instanceOf(Array),
  client: bool,
};

export default ActivityFilters;
