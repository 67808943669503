import { useGetHolidaysQuery } from '../../../../api/Administration/api';
import {
  useGetCaregiverCalendarQuery,
  useGetCaregiverInfoQuery,
} from '../../../../api/Caregivers/api';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import { checkIfIsHoliday } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import VisitManagementProvider from '../../../../components/VisitInfoTypeProvider/VisitManagementProvider';
import CaregiverCalendarView from './CaregiverCalendarView';
import { CaregiverCalendarHeader } from './components/CaregiverCalendarHeader';
import './index.css';
import Loader from '../../../../components/Loader';

export default function CaregiverCalendar() {
  const [calendarDate, setCalendarDate] = useState(null);
  const { id } = useParams();

  const today = moment();
  const mondayDate = today.startOf('isoWeek').format(ISO_DATE_ONLY_FORMAT);
  const formattedDate = calendarDate
    ? moment(calendarDate)?.startOf('isoWeek').format(ISO_DATE_ONLY_FORMAT)
    : null;

  const {
    data: caregiverEvents,
    refetch: refetchCalendarData,
    error: getCaregiverCalendarError,
    isLoading: isLoadingCalendar,
    isFetching: isFetchingCalendar,
  } = useGetCaregiverCalendarQuery(
    {
      caregiverId: id,
      date: formattedDate || mondayDate,
    },
    { refetchOnMountOrArgChange: true },
  );

  const {
    data: caregiverProfile,
    isLoading: isCaregiverProfileLoading,
    error: getCaregiverProfileError,
  } = useGetCaregiverInfoQuery(id);

  const { setAlert } = useContext(AlertContext);
  const { data: holidays, error: getHolidaysError } = useGetHolidaysQuery();

  useEffect(() => {
    if (getHolidaysError || getCaregiverCalendarError || getCaregiverProfileError) {
      const errorData = getHolidaysError || getCaregiverCalendarError || getCaregiverProfileError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getHolidaysError, getCaregiverCalendarError, getCaregiverProfileError, setAlert]);

  const parsedEvents = caregiverEvents?.map(caregiverEvent => {
    const thisVisit = { ...caregiverEvent };
    const arrivalTime = moment(
      thisVisit.arrivalTime ? thisVisit.arrivalTime : thisVisit.arrivalStart,
    );
    const actualTimeStart = thisVisit.actualTimeStart
      ? moment(thisVisit.actualTimeStart)
      : undefined;
    const actualTimeEnd = thisVisit.actualTimeEnd ? moment(thisVisit.actualTimeEnd) : undefined;
    const start = moment(thisVisit?.date)
      .set({
        hour: actualTimeStart ? actualTimeStart.hours() : arrivalTime?.hours(),
        minute: actualTimeStart ? actualTimeStart.minutes() : arrivalTime?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .toDate();
    const end = actualTimeEnd
      ? actualTimeEnd.toDate()
      : moment(start).add(thisVisit.duration, 'minutes').toDate();
    return {
      ...thisVisit,
      arrivalTime: arrivalTime.format('YYYY-MM-DDTHH:mm:ss'),
      start,
      end,
      arrivalTimeDuration: 60,
      ...(actualTimeStart &&
        actualTimeEnd && { actualDuration: actualTimeEnd.diff(actualTimeStart, 'minutes') }),
    };
  });

  useEffect(() => {
    const now = new Date();
    const timer = setTimeout(() => {
      const currentTimeIndicator = document.querySelector('.rbc-current-time-indicator');

      currentTimeIndicator?.classList.add('custom-time-indicator');
      const currentTimeIndicator1 = document.querySelector('.custom-time-indicator');
      const currentTime = now.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
      });
      currentTimeIndicator1?.setAttribute('data-current-time', currentTime);
    }, 0);
    return () => clearTimeout(timer);
  }, [parsedEvents]);
  const calendarHeader = ({ date }) => {
    const isDateHoliday = checkIfIsHoliday(date, holidays, calendarDate);
    return (
      <CaregiverCalendarHeader
        date={date}
        holiday={isDateHoliday?.name ? date : null}
        holidayName={isDateHoliday?.name || ''}
      />
    );
  };

  const dayPropGetter = useCallback(
    date => {
      const isDateHoliday = checkIfIsHoliday(date, holidays, calendarDate);

      const dayProps = {};

      if (isDateHoliday?.name) {
        dayProps.className = 'holiday';
      }

      return dayProps;
    },
    [holidays, calendarDate],
  );

  const slotPropGetter = useCallback(
    date => {
      const isDateHoliday = checkIfIsHoliday(date, holidays, calendarDate);
      const slotTime = moment(date);
      const weekday = moment(date).weekday();
      const slotProps = {};
      let isSlotAvailable = false;
      if (!isDateHoliday) {
        let thisAvailability = caregiverProfile?.availabilities;
        if (caregiverProfile?.pendingChanges?.availabilities) {
          for (let availDate in caregiverProfile?.pendingChanges?.availabilities) {
            const availDateMoment = moment(availDate, 'YYYY-MM-DD');
            if (availDateMoment <= moment(date)) {
              thisAvailability = caregiverProfile?.pendingChanges?.availabilities[availDate];
            }
          }
        }
        let foundAvailability = false;
        for (let availability in thisAvailability?.[weekday]) {
          const startTimeHours = moment(
            thisAvailability?.[weekday]?.[availability]?.startTime,
            'h:mm:ss',
          );
          const endTimeHours = moment(
            thisAvailability?.[weekday]?.[availability]?.endTime,
            'h:mm:ss',
          );
          let hours = startTimeHours?.hours();
          let minutes = startTimeHours?.minutes();
          if (minutes === 0) {
            hours = hours - 1;
            minutes = 59;
          } else {
            minutes = minutes - 1;
          }
          const startTime = moment(date).set({
            hour: hours,
            minute: minutes,
            second: 0,
            millisecond: 0,
          });
          const endTime = moment(date).set({
            hour: endTimeHours?.hours(),
            minute: endTimeHours?.minutes(),
            second: 0,
            millisecond: 0,
          });
          if (slotTime.isBetween(startTime, endTime)) {
            foundAvailability = true;
          }
        }
        if (foundAvailability) {
          slotProps.style = {
            background: '#fff',
          };
        }
        const shiftsStart = moment(date).set({
          hour: 7,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const shiftsEnd = moment(date).set({
          hour: 20,
          minute: 59,
          second: 59,
          millisecond: 0,
        });
        const startTime = moment(date).set({
          hour: 13,
          minute: 59,
          second: 59,
          millisecond: 0,
        });
        const endTime = moment(date).set({
          hour: 16,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        if (
          slotTime.isBetween(startTime, endTime) ||
          slotTime.isBefore(shiftsStart) ||
          slotTime.isAfter(shiftsEnd)
        ) {
          slotProps.style = {
            background: '#000',
          };
        }
      }
      if (!isSlotAvailable) {
        slotProps.className = 'outside-availability';
      }

      return slotProps;
    },
    [calendarDate, caregiverProfile, holidays],
  );
  return (
    <VisitManagementProvider reFetchCalendarEvents={refetchCalendarData}>
      {isLoadingCalendar || isCaregiverProfileLoading || isFetchingCalendar ? (
        <Loader />
      ) : (
        <CaregiverCalendarView
          calendarHeader={calendarHeader}
          calendarEvents={parsedEvents}
          calendarDate={calendarDate}
          dayPropGetter={dayPropGetter}
          setCalendarDate={setCalendarDate}
          caregiverProfile={caregiverProfile}
          slotPropGetter={slotPropGetter}
        />
      )}
    </VisitManagementProvider>
  );
}
