import { COLORS } from '../../../../../shared/styles/Theme';

export const chipStartStyle = {
  position: 'absolute',
  top: '-7px',
  left: '-61px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.green[500],
  color: '#fff',
  fontSize: '11px',
};
export const chipEndStyle = {
  position: 'absolute',
  top: '95%',
  left: '-61px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.green[500],
  color: '#fff',
  fontSize: '11px',
};
export const durationStyle = {
  position: 'absolute',
  top: '25%',
  left: '-68px',
  width: '62px',
  zIndex: 1003,
  padding: '4px 6px',
  borderRadius: '4px',
  background: '#fff',
  boxShadow: '0px 0px 12px 0px #2C2D2E29',
};
export const chipArrivalStartStyle = {
  position: 'absolute',
  top: '-7px',
  right: '-61px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.brown[600],
  color: '#fff',
  fontSize: '11px',
};
export const chipArrivalTimeEndStyle = {
  position: 'absolute',
  right: '-61px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.brown[600],
  color: '#fff',
  fontSize: '11px',
};
export const chipArrivalWindowStyle = {
  position: 'absolute',
  right: '-58px',
  width: '54px',
  zIndex: 1003,
  padding: '4px 6px',
  borderRadius: '4px',
  background: '#fff',
  boxShadow: '0px 0px 12px 0px #2C2D2E29',
};
export const mainEventWrapper = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
};
export const arrivalWindowBorder = {
  border: `2px dashed ${COLORS.blue[700]}`,
  borderBottom: 'none',
  position: 'absolute',
  width: '100%',
  zIndex: 1000000,
};

export const constraintsViolationsIconsWrapper = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const teamBlockWrapperCommonStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '600',
  overflow: 'hidden',
  transition: 'height 0.3s ease',
  position: 'absolute',
  zIndex: 1,
};

export const eventCardInfo = {
  display: 'flex',
  flexDirection: 'column',
};

export const eventCardInfoTitle = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};
