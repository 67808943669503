import { Box, IconButton } from '@mui/material';
import EditIcon from '../../../../../img/icons/edit-icon.svg';
import CancelIcon from '../../../../../img/icons/iconButtons/cancel-icon.svg';
import DeleteIcon from '../../../../../img/icons/iconButtons/delete-icon.svg';
import { timeOfRequestActionsButtonStyles } from '../../../../../pages/CaregiverDetails/view/PtoAndCallouts/styles';
import { EditCareProgramContext } from '../../../../../pages/ClientDetails/context';
import { instanceOf } from 'prop-types';
import React, { useContext } from 'react';
import CustomTooltip from '../../../../../components/CustomTooltip';
import { careProgramVisitActionsWrapper } from './CareProgramManagementProvider/components/styles';

function VisitActions({ visit = {}, visitEditTypes = {} }) {
  const {
    initialCareProgramData,
    setEditedVisitData,
    setUpdatedCareProgram,
    showRemoveProgramVisit,
    updatedCareProgram,
  } = useContext(EditCareProgramContext);

  const cancelChanges = () => {
    if (visit.id && (!visit.changesType || visit.changesType === 'add')) {
      const newVisitsList = [...updatedCareProgram].filter(item => item.id !== visit.id);
      setUpdatedCareProgram(newVisitsList);
      return '';
    }
    if (visit.changesType === 'existing') {
      const updatedVisitIndex = updatedCareProgram.findIndex(({ id }) => id === visit.id);
      const initialVisitData = initialCareProgramData.find(({ id }) => id === visit.id);
      let newVisitsList = [...updatedCareProgram];
      newVisitsList[updatedVisitIndex] = initialVisitData;
      setUpdatedCareProgram(
        newVisitsList.filter((x, visitIndex) =>
          x && x.id === visit.id ? visitIndex === updatedVisitIndex : x,
        ),
      );
    }
    if (visit.id && visit.changesType === visitEditTypes.edit && visit.childId) {
      const editVisitIndex = updatedCareProgram.findIndex(({ id }) => id === visit.id);
      const initialVisitData = initialCareProgramData.find(({ id }) => id === visit.id);
      const newVisitsList = [...updatedCareProgram].filter(({ id }) => id !== visit.id);
      newVisitsList[editVisitIndex] = initialVisitData;
      setUpdatedCareProgram(newVisitsList);
    }
    return '';
  };

  const canBeCanceled =
    visit.changesType === 'existing' ||
    (visit.changesType === 'old' && visit.effectiveDateEnd) ||
    visit.changesType === 'add';
  const canBeEdited =
    visit.changesType === 'new' ||
    (visit.changesType === 'old' && !visit.effectiveDateEnd) ||
    visit.changesType === 'add';
  const canBeDeleted = visit.changesType === 'old' && !visit.effectiveDateEnd;

  return (
    <Box sx={careProgramVisitActionsWrapper}>
      {canBeEdited && (
        <CustomTooltip title="Edit visit">
          <span>
            <IconButton
              aria-label="Edit visit"
              disabled={!!visit.effectiveDateEnd}
              disableRipple
              onClick={() => {
                setEditedVisitData(visit);
              }}
              sx={timeOfRequestActionsButtonStyles}
            >
              <img src={EditIcon} alt="Edit" />
            </IconButton>
          </span>
        </CustomTooltip>
      )}
      {canBeCanceled && (
        <CustomTooltip title="Cancel Changes">
          <span>
            <IconButton
              aria-label="Cancel Changes"
              disableFocusRipple
              disableRipple
              onClick={cancelChanges}
              sx={timeOfRequestActionsButtonStyles}
            >
              <img src={CancelIcon} alt="Cancel" />
            </IconButton>
          </span>
        </CustomTooltip>
      )}
      {canBeDeleted && (
        <CustomTooltip title="Delete visit">
          <span>
            <IconButton
              aria-label="Delete visit"
              disabled={!!visit.effectiveDateEnd}
              disableRipple
              onClick={() => showRemoveProgramVisit(visit)}
              sx={timeOfRequestActionsButtonStyles}
            >
              <img src={DeleteIcon} alt="Delete" />
            </IconButton>
          </span>
        </CustomTooltip>
      )}
    </Box>
  );
}

VisitActions.propTypes = {
  visit: instanceOf(Object),
  visitEditTypes: instanceOf(Object),
};

export default React.memo(VisitActions);
