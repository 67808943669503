import { Box, Typography } from '@mui/material';
import VisitDaySchedule from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/CareProgramSummary/VisitDaySchedule';
import React from 'react';
import DurationAndEffectiveDate from './DurationAndEffectiveDate';
import VisitType from './VisitType';
import ArrivalWindow from './ArrivalWindow';

export const columns = [
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    maxWidth: 400,
    sortable: false,
    renderCell: ({ id, row }) => (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ marginRight: '8px' }}>{id}.</Typography>
            <VisitType row={row} />
          </Box>
        </Box>
      </Box>
    ),
  },
  {
    field: 'days',
    headerName: 'Days of the Week',
    flex: 1,
    minWidth: 650,
    sortable: false,
    renderCell: ({ row }) => <VisitDaySchedule row={row} />,
  },
  {
    field: 'duration',
    headerName: 'Duration',
    sortable: false,
    flex: 1,
    maxWidth: 150,
    renderCell: ({ row }) => <DurationAndEffectiveDate row={row} />,
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    sortable: false,
    flex: 1,
    maxWidth: 300,
    renderCell: ({ row }) => <ArrivalWindow row={row} />,
  },
];

export const summaryPreViewButtons = [
  { name: 'Summary', infoType: 'summaryData' },
  { name: 'As Is', infoType: 'currentVersion' },
  { name: 'To Be', infoType: 'futureVersion' },
];

export const summaryDataAccordions = [
  { dataKey: 'addedVisits', title: 'Added Visits' },
  { dataKey: 'editedVisits', title: 'Edited Visits' },
  { dataKey: 'deletedVisits', title: 'Deleted Visits' },
];
