import { sortBy, uniqBy } from 'lodash';
import moment from 'moment/moment';
import { getStatusClassName } from '../../shared/utils/common';
import { checkIfDraggable } from './constants';

export const getDndEventBoxClass = event => {
  const classNameData = { className: 'dnd-event' };
  if (event?.title === 'arrival') {
    classNameData.className = classNameData.className.concat(
      ` arrival ${event?.resourceId} ${event?.clientName} ${event?.team} arrival${event?.eventId}`,
    );
  }
  if (event?.title === 'arrivalTime') {
    classNameData.className = classNameData.className.concat(
      ` arrivalTime  arrivalTime${event?.id}   ${event?.resourceId}`,
    );
  }

  if (event?.visitTypeDetails?.name === 'Lunch Break') {
    classNameData.className = classNameData.className.concat(` lunchBreak`);
  }

  if (event?.type === 'Callout') {
    classNameData.className = classNameData.className.concat(` calloutEvent`);
  }

  if (event?.caregiverId && event?.caregiverId === 'NoCaregiver') {
    classNameData.className = classNameData.className.concat(' not-allocated');
  }

  if (
    !checkIfDraggable({
      isShadowVisit: event?.isShadowVisit,
      status: event?.status,
    }) ||
    event?.type === 'Callout'
  ) {
    classNameData.className = classNameData.className.concat(' non-draggable');
  }
  classNameData.className = getStatusClassName(classNameData.className, event);
  return classNameData;
};

export const calculateCalendarVerticalScrollPosition = ({ currentDate, firstVisit, top }) => {
  const scrollMinutes = Math.floor(top * (60 / 160));
  const startOfDay = moment(firstVisit, 'HH:mm');
  const end = moment(startOfDay).add(scrollMinutes, 'minutes');
  return moment(currentDate)
    .set({
      hour: end?.hours(),
      minute: end?.minutes(),
      second: 0,
      millisecond: 0,
    })
    .toDate();
};

export const getCaregiverConstraints = (caregiver, eventsList) => {
  const tempEventList = [...eventsList];
  const tempCaregiver = { ...caregiver };
  const caregiverEvents = tempEventList.filter(event => event.caregiverId === tempCaregiver.id);
  for (const event of caregiverEvents) {
    const knownHardConstraints = [];
    if (event.violatedHardConstraints?.length) {
      for (const constraint of knownHardConstraints) {
        if (
          event.violatedHardConstraints.includes(constraint) &&
          !tempCaregiver.violatedHardConstraints?.includes(constraint)
        ) {
          if (tempCaregiver.violatedHardConstraints) {
            tempCaregiver.violatedHardConstraints.push(constraint);
          } else {
            tempCaregiver.violatedHardConstraints = [constraint];
          }
        }
      }
    }
    const knownSoftConstraints = ['No Lunch Break'];
    if (event.violatedSoftConstraints?.length) {
      for (const constraint of knownSoftConstraints) {
        if (
          event.violatedSoftConstraints.includes(constraint) &&
          !tempCaregiver.violatedSoftConstraints?.includes(constraint)
        ) {
          if (tempCaregiver.violatedSoftConstraints) {
            tempCaregiver.violatedSoftConstraints.push(constraint);
          } else {
            tempCaregiver.violatedSoftConstraints = [constraint];
          }
        }
      }
    }
  }
  return tempCaregiver;
};

export const calculateResourceStatistics = (caregiver, eventsList, currentDate) => {
  const caregiverEvents = sortBy(
    eventsList.filter(event => event.caregiverId === caregiver.id && event.type !== 'Callout'),
    ['start'],
  ).filter(event => event.status !== 'Lunch Break');

  const currentDay = new Date(currentDate).getDay();
  const currentAvailabilities = caregiver.availabilities?.[currentDay - 1];

  const lunchBreaks = eventsList.filter(
    event => event.status === 'Lunch Break' && event.caregiverId === caregiver.id,
  );

  let totalMinutesScheduled = 0;

  if (caregiverEvents?.length && currentAvailabilities?.length) {
    const uniqueAvailabilities = uniqBy(
      currentAvailabilities,
      value => `${value.startTime}${value.endTime}`,
    );
    for (const currentAvailability of uniqueAvailabilities) {
      const caregiverShiftStart = moment(currentDate).set({
        hours: Number(currentAvailability.startTime.substring(0, 2)),
        minutes: 0,
        seconds: 0,
      });
      const caregiverShiftEnd = moment(currentDate).set({
        hours: Number(currentAvailability.endTime.substring(0, 2)),
        minutes: 0,
        seconds: 0,
      });

      let lunchBreaksTimeToExclude = 0;

      if (lunchBreaks.length) {
        for (const lunchBreak of lunchBreaks) {
          if (
            moment(lunchBreak.start).isSameOrAfter(caregiverShiftStart, 'minutes') &&
            moment(lunchBreak.start).isBefore(caregiverShiftEnd, 'minutes')
          ) {
            lunchBreaksTimeToExclude += lunchBreak.duration;
          }
        }
      }

      const caregiverShiftEvents = caregiverEvents.filter(
        event =>
          moment(event.start).isBefore(caregiverShiftEnd, 'minutes') &&
          moment(event.start).isSameOrAfter(caregiverShiftStart, 'minutes'),
      );

      if (caregiverShiftEvents.length) {
        const shiftFirstVisit = moment(caregiverShiftEvents[0].start);
        const shiftLastVisit = moment(caregiverShiftEvents[caregiverShiftEvents.length - 1].end);

        const totalShiftMinutesScheduled = caregiverShiftEvents?.length
          ? shiftLastVisit.diff(shiftFirstVisit, 'minutes') - lunchBreaksTimeToExclude
          : 0;

        totalMinutesScheduled += totalShiftMinutesScheduled;
      }
    }

    const totalMinutesBooked = caregiverEvents.reduce((acc, event) => acc + event.duration, 0);
    const totalTravelTime = caregiverEvents.reduce((acc, event) => acc + event.travelTime, 0);

    const hoursScheduled = Math.floor(totalMinutesScheduled / 60);
    const remainingMinutesScheduled = totalMinutesScheduled % 60;
    const hoursBooked = Math.floor(totalMinutesBooked / 60);
    const remainingMinutesBooked = totalMinutesBooked % 60;
    const hoursBookedWithTravelTime = Math.floor((totalMinutesBooked + totalTravelTime) / 60);
    const remainingMinutesBookedWithTravelTime = (totalMinutesBooked + totalTravelTime) % 60;
    const travelTimeHours = Math.floor(totalTravelTime / 60);
    const travelTimeRemainingMinutes = totalTravelTime % 60;

    const utilizationRate = (
      totalMinutesScheduled > 0 ? (totalMinutesBooked / totalMinutesScheduled) * 100 : 0
    ).toFixed(2);
    const utilizationRateWithTravelTime = (
      totalMinutesScheduled > 0
        ? ((totalMinutesBooked + totalTravelTime) / totalMinutesScheduled) * 100
        : 0
    ).toFixed(2);

    return {
      scheduledHours: `${hoursScheduled > 0 ? `${hoursScheduled}h ` : ''}${
        remainingMinutesScheduled > 0 ? `${remainingMinutesScheduled}min` : ''
      }`,
      utilizationRate,
      utilizationRateWithTravelTime,
      utilizationHours: `${hoursBooked > 0 ? `${hoursBooked}h ` : ''}${
        remainingMinutesBooked > 0 ? `${remainingMinutesBooked}min` : ''
      }`,
      utilizationHoursWithTravelTime: `${
        hoursBookedWithTravelTime > 0 ? `${hoursBookedWithTravelTime}h ` : ''
      }${
        remainingMinutesBookedWithTravelTime > 0 ? `${remainingMinutesBookedWithTravelTime}min` : ''
      }`,
      expectedTravelTime: `${travelTimeHours > 0 ? `${travelTimeHours}h ` : ''}${
        travelTimeRemainingMinutes > 0 ? `${travelTimeRemainingMinutes}min` : ''
      }`,
      travelTimeIndicator: (totalTravelTime / totalMinutesScheduled) * 100 >= 20,
    };
  } else
    return {
      scheduledHours: `0h`,
      utilizationRate: '0.00',
      utilizationRateWithTravelTime: '0.00',
      utilizationHoursWithTravelTime: `0h`,
      expectedTravelTime: `0h`,
      travelTimeIndicator: false,
    };
};
