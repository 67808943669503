import { Box, Typography } from '@mui/material';
import { useGetUserDataByIdQuery } from '../../../../../../../../../../api/Clients/api';
import CustomBreadcrumbs from '../../../../../../../../../../pages/ClientDetails/components/Breadcrumbs';
import {
  ClientDetailsContext,
  EditCareProgramContext,
} from '../../../../../../../../../../pages/ClientDetails/context';
import CareProgramCalendar from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramCalendar';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { H12_TIME_FORMAT, SHORT_DATE_FORMAT } from '../../../../../../../../../../shared/constants';
import AlertContext from '../../../../../../../../../../components/Alert';
import ButtonsGroup from '../../../../../../../../../../components/ButtonsGroup';
import {
  updateCareProgramInfoWrapper,
  updateCareProgramTitleWrapper,
  updateCareProgramViewWrapper,
} from '../../../UpdateCareProgram/style';
import SummaryData from './SummaryData';
import { getSummaryTabs, getTabsData } from './utils';
import moment from 'moment';
import { dateHandler } from '../utils';

function CareProgramSummary() {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { closeDialog, effectiveDate, updatedCareProgram, initialCareProgramData } =
    useContext(EditCareProgramContext);
  const { resetToInitialView } = useContext(ClientDetailsContext);
  const [showedInfoType, setShowedInfoType] = useState('summaryData');
  const { data: clientData, error } = useGetUserDataByIdQuery(id);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const tabsData = useMemo(() => getTabsData(updatedCareProgram), [updatedCareProgram]);
  const buttons = useMemo(() => getSummaryTabs(tabsData), [tabsData]);
  const careProgramVisitsData = useMemo(() => {
    const visits = tabsData[showedInfoType]?.visits;
    return showedInfoType === 'futureVersion' ? visits.filter(({ childId }) => !childId) : visits;
  }, [showedInfoType, tabsData]);

  if (tabsData?.summaryData?.editedVisits?.length) {
    tabsData.summaryData.editedVisits = tabsData.summaryData.editedVisits.map(editedVisit => {
      const initialVisitData = initialCareProgramData.find(({ id }) => id === editedVisit.id);
      if (initialVisitData) {
        const oldArrivalWindowKeys = Object.keys(initialVisitData?.days ?? {});
        let arrivalWindow = {};
        if (oldArrivalWindowKeys.length) {
          const oldArrivalWindow = initialVisitData?.days[oldArrivalWindowKeys[0]];
          arrivalWindow.oldWindow = `${dateHandler(oldArrivalWindow?.arrivalStart)} - ${dateHandler(
            oldArrivalWindow?.arrivalEnd,
          )}`;
        }
        const newArrivalWindowKeys = Object.keys(editedVisit?.days ?? {});
        if (newArrivalWindowKeys.length) {
          const newArrivalWindow = editedVisit?.days[newArrivalWindowKeys[0]];
          arrivalWindow.newWindow = `${dateHandler(newArrivalWindow?.arrivalStart)} - ${dateHandler(
            newArrivalWindow?.arrivalEnd,
          )}`;
        }
        return {
          ...editedVisit,
          ...(editedVisit.visitType !== initialVisitData?.visitType && {
            oldVisitType: initialVisitData?.visitTypeDetails?.name,
          }),
          ...(editedVisit.duration !== initialVisitData?.duration && {
            oldDuration: initialVisitData?.duration,
          }),
          arrivalWindow,
        };
      } else {
        return editedVisit;
      }
    });
  }

  return (
    <Box sx={updateCareProgramViewWrapper}>
      <Box sx={updateCareProgramTitleWrapper}>
        <CustomBreadcrumbs
          closeDialog={closeDialog}
          resetToInitialView={resetToInitialView}
          title="Update Care Program"
        />
        <Typography variant="body1">
          {`Effective Date: ${effectiveDate.format(SHORT_DATE_FORMAT)}`}
        </Typography>
      </Box>
      <Box sx={updateCareProgramInfoWrapper}>
        <Typography variant="h1">
          {clientData?.firstName} {clientData?.lastName}
        </Typography>
        <ButtonsGroup
          additionalStyles={{ flexDirection: 'column' }}
          buttons={buttons}
          changeSelected={setShowedInfoType}
          selected={showedInfoType}
        />
      </Box>
      {showedInfoType === 'summaryData' ? (
        <SummaryData summaryData={tabsData?.summaryData} />
      ) : (
        <CareProgramCalendar careProgramVisits={careProgramVisitsData} />
      )}
    </Box>
  );
}

export default React.memo(CareProgramSummary);
