import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import moment from 'moment';
import ErrorIcon from '../../img/icons/error-icon.svg';
import WarningIcon from '../../img/icons/warning-icon.svg';
import React, { useEffect, useState } from 'react';
import {
  allConstraintsList,
  customAccordionStyles,
  customAccordionSummaryStyles,
  detailSectionTitle,
  emptyConstraintsContainer,
  optimizerDateTitle,
  visitAccordionDetailsContainer,
  visitAccordionSummaryTitle,
  visitDetailsContainer,
  visitDivider,
} from './styles';
import { FULL_DATE_H12_STANDARD_FORMAT, H12_TIME_FORMAT } from '../../shared/constants';
import { groupBy, remove, sortBy } from 'lodash';

const DateAccordion = ({ data, allVisits}) => {
  const [expanded, setExpanded] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showChanges, setShowChanges] = useState(false);
  const [showSameDayChanges, setShowSameDayChanges] = useState(false);
  const [sameDayChanges, setSameDayChanges] = useState([]);
  const [caregiverViolations, setCaregiverViolations] = useState([]);
  const [visitDataToDisplay, setVisitDataToDisplay] = useState([]);
  const [editedVisitDataToDisplay, setEditedVisitDataToDisplay] = useState({});

  const checkForConstraints = () => {
    const updatedAllVisits = allVisits ? [...allVisits] : [];
    for (const visit of data?.addedVisits ?? []) {
      updatedAllVisits.push(visit);
    }
    for (const visit of data?.editedVisits ?? []) {
      const index = updatedAllVisits.findIndex(
        updatedVisit => updatedVisit?.id === visit?.id,
      );
      updatedAllVisits[index] = visit;
    }
    for (const visit of data?.deletedVisits ?? []) {
      const index = updatedAllVisits.findIndex(
        updatedVisit => updatedVisit?.id === visit?.id,
      );
      updatedAllVisits.splice(index, 1);
    }
    const differences = data?.differences ?? [];
    let tempVisitDataToDisplay = [];
    let tempEditedVisitDataToDisplay = [];
    let tempSameDayDataToDisplay = [];
    let tempCaregiverViolations = [];
    setHasError(false);
    setHasWarning(false);
    for (const difference of differences) {
      const violatedHardConstraints = difference?.changes?.violatedHardConstraints?.new ?? [];
      const violatedSoftConstraints = difference?.changes?.violatedSoftConstraints?.new ?? [];
      const caregiverViolatedHardConstraints = [];
      const caregiverViolatedSoftConstraints = [];

      const visitId = difference?.id ?? '';
      const visit = [...(data?.editedVisits ?? []), ...(data?.addedVisits ?? [])].find(
        visit => visit?.id === visitId,
      );
      tempCaregiverViolations[visit.caregiverId] = {
        violatedHardConstraints: [],
        violatedSoftConstraints: [],
      };
      for (let softViolation of violatedSoftConstraints) {
        violatedSoftConstraints.splice(violatedSoftConstraints.indexOf(softViolation), 1);
        if (softViolation === "No Lunch Break") {
          if (visit && visit.caregiverId) {
            if (tempCaregiverViolations[visit.caregiverId]) {
              caregiverViolatedSoftConstraints.push("No Lunch Break")
            }
          }
        }
      }
      if ([...caregiverViolatedSoftConstraints, ...caregiverViolatedHardConstraints].length > 0) {
        tempCaregiverViolations.push({
          caregiverName: visit?.caregiverName ?? visit?.caregiverId ?? 'Unknown Caregiver: ' + visit.caregiverId,
          caregiverViolatedHardConstraints: caregiverViolatedHardConstraints,
          caregiverViolatedSoftConstraints: caregiverViolatedSoftConstraints,
        });
        if (caregiverViolatedHardConstraints.length > 0) {
          setHasError(true);
        }
        if (caregiverViolatedSoftConstraints.length > 0) {
          setHasWarning(true);
        }
      }
      if ([...violatedHardConstraints, ...violatedSoftConstraints].length > 0) {
        tempVisitDataToDisplay.push({
          clientName: visit?.clientName ?? visit?.clientId ?? 'Unknown Client: ' + visit.id,
          visitStart: moment('arrivalTime' in visit ? visit?.arrivalTime : visit?.arrivalStart),
          visitEnd: moment('arrivalTime' in visit ? visit?.arrivalTime : visit?.arrivalStart).add(
            visit?.duration,
            'minutes',
          ),
          newViolatedHardConstraints: violatedHardConstraints,
          newViolatedSoftConstraints: violatedSoftConstraints,
        });
        if (violatedHardConstraints.length > 0) {
          setHasError(true);
        }
        if (violatedSoftConstraints.length > 0) {
          setHasWarning(true);
        }
      }

      let sameDay = false;
      let tempSameDayChanges = {};
      let nextVist = false;
      if (data?.date.substring(0, 10) === moment().format('YYYY-MM-DD')) {
        if (updatedAllVisits) {
          sameDay = true;
        }
      }
      const caregiverVisits = updatedAllVisits?.filter(
        thisVisit => thisVisit?.caregiverId === visit?.caregiverId && thisVisit?.date === visit?.date,
      );
      const sortedCaregiverVisits = sortBy(caregiverVisits, ['arrivalTime']);
      const nextVisit = sortedCaregiverVisits.find(
        thisVisit => moment(thisVisit?.arrivalTime).isAfter(moment()),
      );
      if (nextVisit?.id === visit?.id) {
        nextVist = true
      }
      
      const restOfChanges = {};
      for (const key in difference?.changes) {
        if (key == 'arrivalTime') {
          restOfChanges['Arrival Time'] = {
            new: moment(difference?.changes[key]?.new).format(H12_TIME_FORMAT),
            exisiting: moment(difference?.changes[key]?.exisiting).format(H12_TIME_FORMAT),
          };
          if (sameDay && nextVist) {
            tempSameDayChanges['Arrival Time'] = {
              new: moment(difference?.changes[key]?.new).format(H12_TIME_FORMAT),
              exisiting: moment(difference?.changes[key]?.exisiting).format(H12_TIME_FORMAT),
            };
          }
        } else if (key == 'roadTimeInMinutes') {
          restOfChanges['Travel Time'] = difference?.changes[key];
          if (sameDay && nextVist) {
            tempSameDayChanges['Travel Time'] = difference?.changes[key];
          }
        } else if (key == 'caregiverId') {
          if (difference?.changes[key]?.new == visit?.caregiverId) {
            const existingName =
              difference?.changes['caregiverName']?.exisiting ??
              difference?.changes['caregiverName']?.exisiting ??
              'Unknown Caregiver: ' + visit.caregiverId;
            restOfChanges['New Visit For Caregiver'] = { new: existingName, exisiting: 'True' };
            if (sameDay && nextVist) {
              tempSameDayChanges['New Visit For Caregiver'] = {
                new: existingName,
                exisiting: 'True',
              };
            }
          }
        }
      }
      if (Object.keys(tempSameDayChanges ?? {}).length && visit?.caregiverId !== 'NoCaregiver' && visit?.caregiverId !== 'CanceledVisit') {
        tempSameDayDataToDisplay.push({
          clientName: visit?.clientName ?? visit?.clientId ?? 'Unknown Client: ' + visit.id,
          caregiverId: visit?.caregiverId,
          caregiverName:
            visit?.caregiverName ??
            visit?.caregiverName ??
            'Unknown Caregiver: ' + visit.caregiverId,
          visitStart: moment('arrivalTime' in visit ? visit?.arrivalTime : visit?.arrivalStart),
          visitEnd: moment('arrivalTime' in visit ? visit?.arrivalTime : visit?.arrivalStart).add(
            visit?.duration,
            'minutes',
          ),
          changes: tempSameDayChanges,
        });
      }

      if (Object.keys(restOfChanges ?? {}).length) {
        if (visit?.caregiverId !== 'NoCaregiver' && visit?.caregiverId !== 'CanceledVisit') {
          tempEditedVisitDataToDisplay.push({
            clientName: visit?.clientName ?? visit?.clientId ?? 'Unknown Client: ' + visit.id,
            caregiverId: visit?.caregiverId,
            caregiverName:
              visit?.caregiverName ??
              visit?.caregiverName ??
              'Unknown Caregiver: ' + visit.caregiverId,
            visitStart: moment('arrivalTime' in visit ? visit?.arrivalTime : visit?.arrivalStart),
            visitEnd: moment('arrivalTime' in visit ? visit?.arrivalTime : visit?.arrivalStart).add(
              visit?.duration,
              'minutes',
            ),
            changes: restOfChanges,
          });
        }
      }
    }
    setEditedVisitDataToDisplay(groupBy(tempEditedVisitDataToDisplay, 'caregiverId'));
    setSameDayChanges(groupBy(tempSameDayDataToDisplay, 'caregiverId'));
    setCaregiverViolations(tempCaregiverViolations);
    setVisitDataToDisplay(tempVisitDataToDisplay);
  };

  useEffect(() => {
    if (data) {
      checkForConstraints();
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Accordion elevation={0} expanded={expanded} sx={customAccordionStyles}>
      <AccordionSummary
        onClick={() => setExpanded(!expanded)}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles(data?.type, hasError, hasWarning)}
      >
        <Box sx={visitAccordionSummaryTitle}>
          {hasWarning && <img src={WarningIcon} alt="Warning" />}
          {hasError && <img src={ErrorIcon} alt="Error" />}
          <Typography sx={optimizerDateTitle} variant="h5">
            {moment(data?.date).format('dddd, MMM D, YYYY')} - {data?.type}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={visitAccordionDetailsContainer}>
        <Box sx={detailSectionTitle}>
          <Typography variant="h5">Violations:</Typography>
        </Box>
        {visitDataToDisplay.length > 0 && (
          sortBy(visitDataToDisplay, ['visitStart']).map((visit, visitIndex) => {
            return (
              <Box
                sx={{
                  ...visitDetailsContainer,
                  marginTop: '10px',
                }}
                key={visitIndex}
              >
                <Typography variant="h6">
                  {visit.clientName} - {visit.visitStart.format(H12_TIME_FORMAT)} -{' '}
                  {visit.visitEnd.format(H12_TIME_FORMAT)}
                </Typography>
                <ul style={allConstraintsList}>
                  {visit.newViolatedHardConstraints.length > 0 ? (
                    <>
                      <Typography variant="h6" sx={{ display: 'list-item' }}>
                        New Violated Hard Constraints:
                      </Typography>
                      <ul style={{ margin: '0px', paddingLeft: '25px' }}>
                        {visit.newViolatedHardConstraints.map(
                          (hardConstraint, hardConstraintIndex) => (
                            <li style={{ listStyleType: 'upper-roman' }} key={hardConstraintIndex}>
                              <Typography variant="body1">{hardConstraint}</Typography>
                            </li>
                          ),
                        )}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                  {visit.newViolatedSoftConstraints.length > 0 ? (
                    <>
                      <Typography variant="h6" sx={{ display: 'list-item' }}>
                        New Violated Soft Constraints:
                      </Typography>
                      <ul style={{ margin: '0px', paddingLeft: '25px' }}>
                        {visit.newViolatedSoftConstraints.map(
                          (softConstraint, softConstraintIndex) => (
                            <li style={{ listStyleType: 'upper-roman' }} key={softConstraintIndex}>
                              <Typography variant="body1">{softConstraint}</Typography>
                            </li>
                          ),
                        )}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
                <Divider sx={visitDivider} />
              </Box>
            );
        }))}
        {caregiverViolations.length > 0 && (
          caregiverViolations.map((caregiverViolation, caregiverViolationIndex) => {
            return (
              <Box
                sx={{
                  ...visitDetailsContainer,
                  marginTop: '10px',
                }}
                key={caregiverViolationIndex}
              >
                <Typography variant="h6">{caregiverViolation.caregiverName}</Typography>
                <ul style={allConstraintsList}>
                  {caregiverViolation.caregiverViolatedHardConstraints.length > 0 ? (
                    <>
                      <Typography variant="h6" sx={{ display: 'list-item' }}>
                        New Violated Hard Constraints:
                      </Typography>
                      <ul style={{ margin: '0px', paddingLeft: '25px' }}>
                        {caregiverViolation.caregiverViolatedHardConstraints.map(
                          (hardConstraint, hardConstraintIndex) => (
                            <li style={{ listStyleType: 'upper-roman' }} key={hardConstraintIndex}>
                              <Typography variant="body1">{hardConstraint}</Typography>
                            </li>
                          ),
                        )}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                  {caregiverViolation.caregiverViolatedSoftConstraints.length > 0 ? (
                    <>
                      <Typography variant="h6" sx={{ display: 'list-item' }}>
                        New Violated Soft Constraints:
                      </Typography>
                      <ul style={{ margin: '0px', paddingLeft: '25px' }}>
                        {caregiverViolation.caregiverViolatedSoftConstraints.map(
                          (softConstraint, softConstraintIndex) => (
                            <li style={{ listStyleType: 'upper-roman' }} key={softConstraintIndex}>
                              <Typography variant="body1">{softConstraint}</Typography>
                            </li>
                          ),
                        )}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
                <Divider sx={visitDivider} />
              </Box>
            );
          }
        ))}
        {!visitDataToDisplay.length > 0 && !caregiverViolations.length > 0 && (
          <Box sx={emptyConstraintsContainer}>
            <Typography variant="h6">No New Constraint Violations</Typography>
          </Box>
        )}
        {Object.keys(sameDayChanges).length > 0 && (
          <Accordion elevation={0} expanded={showSameDayChanges} sx={customAccordionStyles}>
            <Box sx={{ ...detailSectionTitle, marginTop: '10px' }}>
              <Typography
                variant="h5"
                onClick={() => setShowSameDayChanges(showSameDayChanges ? false : true)}
              >
                Same Day First Visit Changes:
              </Typography>
            </Box>
            {Object.keys(sameDayChanges).length ? (
              Object.keys(sameDayChanges).map(key => {
                const visitChanges = sameDayChanges[key];
                return (
                  <Box
                    sx={{
                      ...visitDetailsContainer,
                      marginTop: '10px',
                    }}
                    key={key}
                  >
                    <Typography variant="h6">{visitChanges[0].caregiverName}</Typography>
                    <ul style={allConstraintsList}>
                      {sortBy(visitChanges, ['visitStart']).map((visitChange, visitChangeIndex) => {
                        return (
                          <li key={`${key}-${visitChangeIndex}`}>
                            <Typography variant="h6" sx={{ display: 'list-item' }}>
                              {visitChange.clientName} -{' '}
                              {visitChange.visitStart.format(H12_TIME_FORMAT)} -{' '}
                              {visitChange.visitEnd.format(H12_TIME_FORMAT)}
                            </Typography>
                            <ul style={{ margin: '0px', paddingLeft: '25px', marginTop: '10px' }}>
                              {Object.keys(visitChange.changes).map((changeKey, changeKeyIndex) => {
                                const change = visitChange.changes[changeKey];

                                return (
                                  <li
                                    style={{ listStyleType: 'upper-roman' }}
                                    key={`${visitChangeIndex}-${changeKeyIndex}-${changeKey}`}
                                  >
                                    <Typography variant="body1">
                                      <span style={{ textDecoration: 'underline' }}>
                                        {changeKey}:
                                      </span>{' '}
                                      <span style={{ color: '#FF3B30' }}>{change.exisiting}</span>{' '}
                                      {change.exisiting == 'True' ? 'exisiting' : 'to'}{' '}
                                      <span style={{ color: '#09AF00' }}>{change.new}</span>
                                    </Typography>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                    <Divider sx={visitDivider} />
                  </Box>
                );
              })
            ) : (
              <Box sx={emptyConstraintsContainer}>
                <Typography variant="h6">No First Visits Were Changed</Typography>
              </Box>
            )}
          </Accordion>
        )}

        <Accordion elevation={0} expanded={showChanges} sx={customAccordionStyles}>
          <Box sx={{ ...detailSectionTitle, marginTop: '10px' }}>
            <Typography variant="h5" onClick={() => setShowChanges(showChanges ? false : true)}>
              Changes:
            </Typography>
          </Box>
          {Object.keys(editedVisitDataToDisplay).length ? (
            Object.keys(editedVisitDataToDisplay).map(editedVisitDataKey => {
              const visitChanges = editedVisitDataToDisplay[editedVisitDataKey];

              return (
                <Box
                  sx={{
                    ...visitDetailsContainer,
                    marginTop: '10px',
                  }}
                  key={editedVisitDataKey}
                >
                  <Typography variant="h6">{visitChanges[0].caregiverName}</Typography>
                  <ul style={allConstraintsList}>
                    {sortBy(visitChanges, ['visitStart']).map((visitChange, visitChangeIndex) => {
                      return (
                        <li key={`${editedVisitDataKey}-${visitChangeIndex}`}>
                          <Typography variant="h6" sx={{ display: 'list-item' }}>
                            {visitChange.clientName} -{' '}
                            {visitChange.visitStart.format(H12_TIME_FORMAT)} -{' '}
                            {visitChange.visitEnd.format(H12_TIME_FORMAT)}
                          </Typography>
                          <ul style={{ margin: '0px', paddingLeft: '25px', marginTop: '10px' }}>
                            {Object.keys(visitChange.changes).map((changeKey, changeKeyIndex) => {
                              const change = visitChange.changes[changeKey];

                              return (
                                <li
                                  style={{ listStyleType: 'upper-roman' }}
                                  key={`${visitChangeIndex}-${changeKeyIndex}-${changeKey}`}
                                >
                                  <Typography variant="body1">
                                    <span style={{ textDecoration: 'underline' }}>
                                      {changeKey}:
                                    </span>{' '}
                                    <span style={{ color: '#FF3B30' }}>{change.exisiting}</span>{' '}
                                    {change.exisiting == 'True' ? 'exisiting' : 'to'}{' '}
                                    <span style={{ color: '#09AF00' }}>{change.new}</span>
                                  </Typography>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                  <Divider sx={visitDivider} />
                </Box>
              );
            })
          ) : (
            <Box sx={emptyConstraintsContainer}>
              <Typography variant="h6">No Updates to be Made</Typography>
            </Box>
          )}
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
};

export default DateAccordion;
