import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import arrivalWhiteIcon from '../../../../img/icons/arrival-window-visit-white.svg';
import arrivalIcon from '../../../../img/icons/arrival-window-visit.svg';
import { LOCAL_STORAGE_KEYS } from '../../../../pages/Scheduling/constants';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { visitStatusesMap } from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import { getStatusComponent } from '../../../../shared/utils/common';
import { eventCardInfo, eventCardInfoTitle, eventDetailRow } from './styles';

export default function EventCardInfo({
  arrivalStart = {},
  arrivalEnd = {},
  checkIfShowArrival = false,
  eventData = {},
}) {
  const isOnSiteStatus = eventData.status === visitStatusesMap.onSite;
  const isVerified = eventData.status === visitStatusesMap.verified;
  const savedStatus = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.oldStatus));
  const calculateStatus = () => {
    if (eventData.isShadowVisit && eventData.id === savedStatus?.uid) {
      return savedStatus?.values?.shadowStatus || eventData?.shadowStatus;
    }
    if (!eventData.status && eventData.id === savedStatus?.uid) {
      return savedStatus?.values?.status || eventData?.status;
    }
    return eventData.isShadowVisit ? eventData.shadowStatus : eventData.status;
  };

  const fontColor =
    !eventData.isShadowVisit && (isOnSiteStatus || isVerified) ? '#ffffff' : COLORS.blue[700];

  const isSetTime = arrivalStart.isSame(arrivalEnd);

  const visitType =
    eventData?.visitTypeDetails?.duration >= 180
      ? eventData?.visitTypeDetails?.name
      : !eventData?.visitTypeDetails?.duration
      ? `${eventData?.visitTypeDetails?.name} (${
          eventData?.duration > 60
            ? `${Math.floor(eventData?.duration / 60)}h ${eventData?.duration % 60}m`
            : eventData?.duration === 60
            ? `1h`
            : `${eventData?.duration}m`
        })`
      : `${eventData?.visitTypeDetails?.name} (${
          eventData?.visitTypeDetails?.duration >= 60
            ? `${eventData?.visitTypeDetails?.duration / 60}h`
            : `${eventData?.visitTypeDetails?.duration}m`
        })`;

  const arrivalTimeComponent = (
    <Box sx={{ display: 'flex' }}>
      <img
        src={!eventData.isShadowVisit && isOnSiteStatus ? arrivalWhiteIcon : arrivalIcon}
        alt="arrivalIcon"
      />
      <Typography
        sx={{
          ml: '6px',
          color: fontColor,
        }}
        variant="body2"
      >
        {isSetTime
          ? `${arrivalStart.format('h:mm A')}`
          : `${arrivalStart.format('h:mm A')} - ${arrivalEnd.format('h:mm A')}`}
      </Typography>
    </Box>
  );

  const visitDetailComponent = (title, value) => (
    <Box sx={{ display: 'flex' }}>
      <Typography sx={{ color: fontColor }} variant="body2">
        <span style={{ fontWeight: 700 }}>{title}:</span> {value}
      </Typography>
    </Box>
  );

  const isLunchBreak = eventData?.status === 'Lunch Break';

  return (
    <Box sx={eventCardInfo}>
      <Box sx={eventCardInfoTitle}>
        {!isLunchBreak && (
          <Link component={RouterLink} to={`/clients/${eventData?.clientId}`} underline="none">
            <Typography
              variant="body1"
              sx={{
                color: fontColor,
                fontWeight: '600',
                fontSize: '14px',
              }}
            >
              {eventData?.clientName}
            </Typography>
          </Link>
        )}
      </Box>
      <Box sx={{ display: 'flex', marginBottom: '2px' }}>
        {getStatusComponent(calculateStatus())}
      </Box>
      {checkIfShowArrival && arrivalTimeComponent}
      {!isLunchBreak && (
        <>
          {visitDetailComponent('Team', eventData?.teamDetails?.name ?? 'Unknown')}
          {visitDetailComponent('Recurrence', eventData?.oneTimeVisit ? 'One Time' : 'Recurring')}
          {visitDetailComponent('Arrival Type', isSetTime ? 'Set Time' : 'Window')}
          {visitDetailComponent('Visit Type', visitType)}
        </>
      )}
    </Box>
  );
}

EventCardInfo.propTypes = {
  arrivalStart: instanceOf(Object),
  arrivalEnd: instanceOf(Object),
  checkIfShowArrival: bool,
  eventData: instanceOf(Object),
};
