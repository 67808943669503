import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
// import Chevron from '../../../../img/icons/chevron-right.svg?react';
import { bool, node, number, string } from 'prop-types';
import React, { useState } from 'react';
import CounterBadge from '../../../../components/CounterBadge';
import {
  caregiverNameStyles,
  customAccordionStyles,
  customAccordionSummaryStyles,
  visitAccordionSummaryTitle,
} from './styles';

function VisitDateAccordion({
  children = <div />,
  hideIfEmpty = false,
  preferredCaregiver = '',
  tasksCount = 0,
  title = '',
}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion elevation={0} expanded={expanded} sx={customAccordionStyles}>
      <AccordionSummary
        // expandIcon={<Chevron onClick={() => setExpanded(!expanded)} />}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles}
      >
        <Box
          sx={visitAccordionSummaryTitle}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography sx={{ lineHeight: '32px', mr: '12px' }} variant="h5">
            {title}
          </Typography>
          <CounterBadge label={tasksCount.toString()} hideIfNothing />
        </Box>
        <Box sx={{ display: 'flex', gap: '24px' }}>
          {expanded && preferredCaregiver && (
            <Typography sx={caregiverNameStyles}>
              <span>Preferred Caregiver: </span>
              {preferredCaregiver}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
}

VisitDateAccordion.propTypes = {
  children: node,
  hideIfEmpty: bool,
  preferredCaregiver: string,
  tasksCount: number,
  title: string,
};

export default React.memo(VisitDateAccordion);
