import { COLORS } from '../../../../shared/styles/Theme';

export const calloutWrapper = {
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '20px',
  cursor: 'default',
};

export const calloutBadge = {
  fontWeight: 600,
  color: '#fff',
  borderRadius: '4px',
  background: '#FF402E',
  padding: '0 4px',
  textWrap: 'nowrap',
  display: 'flex',
  width: 'max-content',
};

export const chipStartStyle = {
  position: 'absolute',
  top: '-7px',
  left: '-61px',
  zIndex: 1000,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.green[500],
  color: '#fff',
  fontSize: '11px',
};

export const chipEndStyle = {
  position: 'absolute',
  top: '95%',
  left: '-61px',
  zIndex: 1000,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.green[500],
  color: '#fff',
  fontSize: '11px',
};

export const durationStyle = {
  position: 'absolute',
  top: '30%',
  left: '-68px',
  width: '62px',
  zIndex: 1000,
  padding: '4px 6px',
  borderRadius: '4px',
  background: '#fff',
  boxShadow: '0px 0px 12px 0px #2C2D2E29',
};

export const durationLabelStyles = {
  color: COLORS.black[700],
  fontSize: '12px',
  lineHeight: '16px',
};
