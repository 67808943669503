import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { infoKeyField, infoRowWrapperStyles } from '../../styles';
import { checkEnableToEditFields } from '../../utils';
import ActualTimeFields from '../ActualTimeFields';
import DateAndArrivalTimeFields from '../DateAndArrivalTimeFields';
import StatusSelectorField from '../StatusSelectorField';
import VisitPrimaryCaregiver from '../VisitPrimaryCaregiver';
import VisitShadowCaregiver from '../VisitShadowCaregiver';
import LinkItem from './LinkItem';
import DurationComponent from './DurationComponent/DurationComponent';

export default function VisitInfoFields() {
  const [unMarkingPreferredCaregiver, setUnMarkingPreferredCaregiver] = useState(false);
  const [unMarkingShadowCaregiver, setUnMarkingShadowCaregiver] = useState(false);
  const [editingPrimaryCaregiver, setEditingPrimaryCaregiver] = useState(false);
  const [editingShadowCaregiver, setEditingShadowCaregiver] = useState(false);
  const [editActualTime, setEditActualTime] = useState(false);
  const [needToConfirmStatus, setNeedToConfirmStatus] = useState(false);
  const {
    reFetchCalendarEvents,
    updateTemporaryVisitData,
    isUpdatingVisitStatus,
    editArrivalTime,
    setEditArrivalTime,
    visitInfoData,
    temporaryVisitData,
    onUpdateVisitDetails,
  } = useContext(VisitInfoTypeContext);

  const changeStatus = useCallback(
    value => {
      const newData = { status: value };
      updateTemporaryVisitData(newData);
      setNeedToConfirmStatus(true);
    },
    [updateTemporaryVisitData],
  );

  const cancelNewStatus = () => {
    setNeedToConfirmStatus(false);
    updateTemporaryVisitData({
      status: visitInfoData.status,
      actualTimeStart: visitInfoData.actualTimeStart,
      actualTimeEnd: visitInfoData.actualTimeEnd,
      cancellationReason: visitInfoData.cancellationReason,
    });
  };

  const cancelPrimaryCaregiverEditing = () => {
    setEditingPrimaryCaregiver(false);
    updateTemporaryVisitData({
      caregiverId: visitInfoData?.caregiverId,
      caregiverName: visitInfoData?.caregiverName,
    });
  };

  const cancelShadowCaregiverEditing = () => {
    setEditingShadowCaregiver(false);
    updateTemporaryVisitData({
      shadowCaregiverId: visitInfoData?.shadowCaregiverId,
      shadowCaregiverName: visitInfoData?.shadowCaregiverName,
    });
  };

  const closeNewStatusConfirmation = useCallback(() => setNeedToConfirmStatus(false), []);

  const isNotAllocatedVisit = !visitInfoData?.caregiverId;
  const enableFieldsToEditData = useMemo(
    () =>
      checkEnableToEditFields({
        editActualTime,
        editArrivalTime,
        editingPrimaryCaregiver,
        editingShadowCaregiver,
        isNotAllocatedVisit,
        needToConfirmStatus,
        unMarkingPreferredCaregiver,
        unMarkingShadowCaregiver,
        visitStatus: visitInfoData.status,
      }),
    [
      editActualTime,
      editArrivalTime,
      editingPrimaryCaregiver,
      editingShadowCaregiver,
      isNotAllocatedVisit,
      needToConfirmStatus,
      unMarkingPreferredCaregiver,
      unMarkingShadowCaregiver,
      visitInfoData.status,
    ],
  );
  const isFutureVisit = moment(visitInfoData?.date, ISO_DATE_ONLY_FORMAT).isAfter(moment(), 'day');

  const isOutOfOptimizedPeriodVisit = moment(visitInfoData?.date, ISO_DATE_ONLY_FORMAT).isAfter(
    moment().clone().startOf('week').add(13, 'days'),
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Client:</Typography>
        <LinkItem
          link={`/clients/${visitInfoData.clientId}` || ''}
          name={visitInfoData.clientName || ''}
        />
      </Box>
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Location:</Typography>
        <Typography variant="body1">{visitInfoData?.address}</Typography>
      </Box>
      <VisitPrimaryCaregiver
        cancelPrimaryCaregiverEditing={cancelPrimaryCaregiverEditing}
        caregiverName={visitInfoData?.caregiverName}
        caregiverId={visitInfoData?.caregiverId}
        editPrimaryCaregiver={() => setEditingPrimaryCaregiver(true)}
        editingPrimaryCaregiver={editingPrimaryCaregiver}
        enableToEditPrimaryCaregiver={
          enableFieldsToEditData.isPrimaryCaregiverAvailableToEdit && !isOutOfOptimizedPeriodVisit
        }
        onUnmarkPreferredCaregiver={setUnMarkingPreferredCaregiver}
        preferredCaregiverId={visitInfoData?.caregiverName}
        unMarkingPreferredCaregiver={unMarkingPreferredCaregiver}
      />
      <VisitShadowCaregiver
        cancelShadowCaregiverEditing={cancelShadowCaregiverEditing}
        caregiverName={visitInfoData?.shadowCaregiverName}
        caregiverId={visitInfoData?.shadowCaregiverId}
        editingShadowCaregiver={editingShadowCaregiver}
        enableToEditShadowCaregiver={
          enableFieldsToEditData.isShadowCaregiverAvailableToEdit && !isOutOfOptimizedPeriodVisit
        }
        onEditShadowCaregiver={() => setEditingShadowCaregiver(true)}
        onUnmarkShadowCaregiver={setUnMarkingShadowCaregiver}
        unMarkingShadowCaregiver={unMarkingShadowCaregiver}
      />
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Team:</Typography>
        <Typography variant="body1">{visitInfoData?.teamDetails?.name || ''}</Typography>
      </Box>
      <StatusSelectorField
        cancelNewStatus={cancelNewStatus}
        closeNewStatusConfirmation={closeNewStatusConfirmation}
        changeStatus={changeStatus}
        date={visitInfoData?.date}
        disabledSelect={
          (visitInfoData?.shadowCaregiverId &&
            visitInfoData?.caregiverId === visitInfoData?.shadowCaregiverId) ||
          enableFieldsToEditData.isStatusDisabledToEdit
        }
        isUpdatingVisitStatus={isUpdatingVisitStatus}
        isFutureVisit={isFutureVisit}
        isNotAllocatedVisit={isNotAllocatedVisit}
        initialStatus={
          visitInfoData?.shadowCaregiverId &&
          visitInfoData?.caregiverId === visitInfoData?.shadowCaregiverId
            ? visitInfoData?.shadowStatus
            : visitInfoData?.status
        }
        needToConfirmStatus={needToConfirmStatus}
        reFetchCalendarEvents={reFetchCalendarEvents}
        scheduledVisit={!!visitInfoData?.externalId}
        temporaryStatus={temporaryVisitData?.status}
        visitId={visitInfoData?.id}
      />
      <DateAndArrivalTimeFields
        editArrivalTime={editArrivalTime}
        enableToEditScheduledTime={
          enableFieldsToEditData.isScheduledTimeAvailableToEdit && !isOutOfOptimizedPeriodVisit
        }
        isExactTime={visitInfoData?.isExactTime}
        setEditArrivalTime={setEditArrivalTime}
        scheduledValue={visitInfoData?.arrivalTime}
      />
      <ActualTimeFields
        actualTimeEnd={visitInfoData?.actualTimeEnd ?? undefined}
        actualTimeStart={visitInfoData?.actualTimeStart ?? undefined}
        editActualTime={editActualTime}
        enableToEditActualTime={
          enableFieldsToEditData.isActualTimeAvailableToEdit && !isOutOfOptimizedPeriodVisit
        }
        temporaryVisitData={visitInfoData}
        setEditActualTime={setEditActualTime}
      />
      <DurationComponent
        visitInfoData={visitInfoData}
        reFetchCalendarEvents={reFetchCalendarEvents}
        temporaryVisitData={temporaryVisitData}
        actualVisitData={visitInfoData}
        updateTemporaryVisitData={updateTemporaryVisitData}
        onUpdateVisitDetails={onUpdateVisitDetails}
      />
    </Box>
  );
}
