import { Snackbar } from '@mui/base';
import { Alert, Box, Button, Typography } from '@mui/material';
import ErrorIcon from '../../img/icons/error-icon.svg';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { COLORS } from '../../shared/styles/Theme';
import {
  errorAlertBodyWrapper,
  errorMessageWrapper,
  textButtonStyles,
} from './styles';

export default function ErrorStacktrace({
  message = '',
  stacktrace = '',
  traceId = '',
}) {
  const [clicked, setClicked] = useState(false);

  const copyErrorMessageToClipboard = async () => {
    await navigator.clipboard.writeText(stacktrace);
    setClicked(true);
  };

  return (
    <Box sx={errorAlertBodyWrapper}>
      <img src={ErrorIcon} alt="Error" />
      <Box
        sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      >
        <Box sx={errorMessageWrapper}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            {message}
          </Typography>
        </Box>
        {!!traceId?.length && (
          <Typography
            variant="body1"
            sx={{ color: COLORS.blue[900], margin: '16px 0' }}
          >
            {`TraceId: ${traceId}`}
          </Typography>
        )}
        {!!stacktrace.length && (
          <Typography
            variant="body1"
            sx={{ color: COLORS.blue[900], margin: '16px 0' }}
          >
            Please send the stack trace to the developers.
          </Typography>
        )}
        <Snackbar
          open={clicked}
          autoHideDuration={6000}
          onClose={() => setClicked(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message="Copied to clipboard"
        >
          <Alert
            onClose={() => setClicked(false)}
            severity="success"
            sx={{ width: '100%', fontSize: '14px' }}
          >
            You have been copied error stacktrace message.
          </Alert>
        </Snackbar>
        {!clicked && stacktrace && (
          <Button
            variant="text"
            sx={textButtonStyles}
            disableFocusRipple
            disableRipple
            onClick={copyErrorMessageToClipboard}
          >
            Copy Stack Trace
          </Button>
        )}
      </Box>
    </Box>
  );
}

ErrorStacktrace.propTypes = {
  message: string,
  stacktrace: string,
  traceId: string,
};
