import { Box, Collapse, Typography } from '@mui/material';
import {
  useApplyStatusMutation,
  useCheckRestoreCancelledVisitQuery,
  useConfirmRestoreCancelledVisitMutation,
  useRestoreCancelledVisitNonOptimizedPeriodMutation,
} from '../../../api/commonApi/api';
import { bool, func, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AlertContext from '../../../components/Alert';
import Loader from '../../../components/Loader';
import {
  canRestoreCanceledVisitStatuses,
  onlyCancelAllowsVisitStatuses,
  unVerifyVisitStatuses,
  visitStatuses,
} from '../constants';
import { infoKeyField, infoRowWrapperStyles } from '../styles';
import { getBgColor } from '../utils';
import CheckReschedulingTable from './CheckReschedulingTable';
import StatusChangingConfirmation from './StatusChangingConfirmation';
import VisitStatusSelect from './StatusSelect';
import { VisitInfoTypeContext } from '../../../shared/context';
import { api } from '../../../api/index';
import OptimizerCheck from '../../Optimizer/OptimizerCheck';
import moment from 'moment';
import { CALIFORNIA_TIME_ZONE } from '../../../shared/constants';

export default function StatusSelectorField({
  cancelNewStatus = () => {},
  changeStatus = () => {},
  closeNewStatusConfirmation = () => {},
  date = '',
  disabledSelect = false,
  initialStatus = '',
  isFutureVisit = false,
  isNotAllocatedVisit = false,
  isUpdatingVisitStatus = false,
  needToConfirmStatus = false,
  reFetchCalendarEvents = () => {},
  scheduledVisit = false,
  visitId = '',
}) {
  const { setAlert } = useContext(AlertContext);
  const { temporaryVisitData } = useContext(VisitInfoTypeContext);
  const [openCheckReschedulingDialog, setOpenCheckReschedulingDialog] = useState(false);
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);
  const [optimizerData, setOptimizerData] = useState({});
  const [careprogramChanges, setCareprogramChanges] = useState({});
  const userProfile = JSON.parse(localStorage.getItem('user'));

  const {
    data: reschedulingData,
    isLoading: isLoadingReschedulingData,
    isFetching: isFetchingReschedulingData,
    error: checkVisitReschedulingError,
  } = useCheckRestoreCancelledVisitQuery(visitId, {
    refetchOnMountOrArgChange: true,
    skip: !openCheckReschedulingDialog,
  });
  const [confirmRestoreCancelledVisit, { error: confirmVisitReschedulingError }] =
    useConfirmRestoreCancelledVisitMutation();
  const [
    restoreCancelledVisitNonOptimizedPeriod,
    { error: restoreCancelledVisitNonOptimizedPeriodError },
  ] = useRestoreCancelledVisitNonOptimizedPeriodMutation();
  const [applyStatus, { isLoading: applyStatusLoading, error: updateStatusError }] =
    useApplyStatusMutation();

  useEffect(() => {
    const errorData =
      checkVisitReschedulingError ||
      confirmVisitReschedulingError ||
      restoreCancelledVisitNonOptimizedPeriodError ||
      updateStatusError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    checkVisitReschedulingError,
    confirmVisitReschedulingError,
    restoreCancelledVisitNonOptimizedPeriodError,
    updateStatusError,
    setAlert,
  ]);

  const availableVisitsOptions = useMemo(() => {
    if (
      initialStatus?.toLowerCase() === 'canceled' &&
      userProfile?.roles?.includes('ADMIN') &&
      isFutureVisit
    ) {
      return canRestoreCanceledVisitStatuses;
    }
    if (initialStatus?.toLowerCase() === 'verified' && userProfile?.roles?.includes('ADMIN')) {
      return unVerifyVisitStatuses;
    }
    if (initialStatus?.toLowerCase() !== 'verified' && (isNotAllocatedVisit || isFutureVisit)) {
      return onlyCancelAllowsVisitStatuses;
    }
    return visitStatuses;
  }, [initialStatus, isNotAllocatedVisit, isFutureVisit, userProfile]);

  const closeRescheduling = useCallback(() => {
    setOpenCheckReschedulingDialog(false);
    cancelNewStatus();
  }, [cancelNewStatus]);

  const confirmReschedulingVisit = useCallback(() => {
    if (scheduledVisit) {
      confirmRestoreCancelledVisit(reschedulingData?.requestId)
        .unwrap()
        .then(() => {
          setOpenCheckReschedulingDialog(false);
          reFetchCalendarEvents();
        });
    } else {
      restoreCancelledVisitNonOptimizedPeriod({ date, visitId })
        .unwrap()
        .then(() => {
          setOpenCheckReschedulingDialog(false);
          reFetchCalendarEvents();
        });
    }
    closeNewStatusConfirmation();
  }, [
    closeNewStatusConfirmation,
    confirmRestoreCancelledVisit,
    date,
    reFetchCalendarEvents,
    reschedulingData,
    restoreCancelledVisitNonOptimizedPeriod,
    scheduledVisit,
    visitId,
  ]);

  const onConfirmNewStatus = async () => {
    if (
      initialStatus?.toLowerCase() === 'canceled' &&
      temporaryVisitData?.status?.toLowerCase() === 'scheduled' &&
      scheduledVisit
    ) {
      setOpenCheckReschedulingDialog(true);
      return '';
    }
    if (
      initialStatus?.toLowerCase() === 'canceled' &&
      temporaryVisitData?.status?.toLowerCase() === 'scheduled' &&
      !scheduledVisit
    ) {
      confirmReschedulingVisit();
      return '';
    }
    if (
      initialStatus?.toLowerCase() === 'scheduled' &&
      temporaryVisitData?.status?.toLowerCase() === 'canceled' &&
      temporaryVisitData?.date.slice(0, 10) !== date.slice(0,10)
    ) {
      const payload = {
        id: temporaryVisitData.careprogramId,
      };
      const careProgramData = await api('GET', 'crud', 'careProgram', payload);
      if (careProgramData.error) {
        setAlert({
          careProgramData,
          type: 'error',
        });
      } else {
        const newDateChange = { canceled: true };
        let dateChanges = {};
        if (careProgramData?.data?.dateChanges) {
          dateChanges = { ...careProgramData?.data?.dateChanges };
        }
        dateChanges[date.slice(0, 10)] = newDateChange;
        careProgramData.data.dateChanges = dateChanges;
        careProgramData.data.oneTimeChange = true;
        setCareprogramChanges(careProgramData?.data);
        setOptimizerData(temporaryVisitData);
        setOpenCheckOptimizer(true);
        closeNewStatusConfirmation();
        return '';
      }
    }
    if (
      initialStatus?.toLowerCase() !== 'inprogress' &&
      temporaryVisitData?.status?.toLowerCase() === 'inprogress'
    ) {
      temporaryVisitData.actualTimeStart = moment()
        .tz(CALIFORNIA_TIME_ZONE)
        .format('YYYY-MM-DDTHH:mm:ss');
      if (temporaryVisitData.actualTimeEnd) {
        temporaryVisitData.actualTimeEnd = undefined;
      }
    }
    if (
      initialStatus?.toLowerCase() !== 'completed' &&
      temporaryVisitData?.status?.toLowerCase() === 'completed'
    ) {
      temporaryVisitData.actualTimeEnd = moment()
        .tz(CALIFORNIA_TIME_ZONE)
        .format('YYYY-MM-DDTHH:mm:ss');
      temporaryVisitData.actualDuration = moment(temporaryVisitData.actualTimeEnd).diff(
        moment(temporaryVisitData.actualTimeStart),
        'minutes',
      );
    }
    applyStatus(temporaryVisitData)
      .unwrap()
      .then(() => {
        reFetchCalendarEvents();
      });
    closeNewStatusConfirmation();
    return '';
  };

  const isReschedulingDataLoading = useMemo(
    () => isLoadingReschedulingData || isFetchingReschedulingData,
    [isLoadingReschedulingData, isFetchingReschedulingData],
  );

  if (isUpdatingVisitStatus) {
    return <Loader />;
  }

  const submitFunction = async data => {
    const response = await api('PATCH', 'crud', 'careProgram', careprogramChanges);
    if (response.error) {
      setAlert({
        response,
        type: 'error',
      });
    }
    reFetchCalendarEvents();
  };

  return (
    <Box>
      {applyStatusLoading && <Loader />}
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Status:</Typography>
        <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
          <VisitStatusSelect
            bgColor={getBgColor(temporaryVisitData?.status)}
            disabled={disabledSelect}
            initialVisitStatus={initialStatus}
            isLoading={false}
            options={availableVisitsOptions}
            selectedValue={temporaryVisitData?.status || ''}
            setValue={changeStatus}
          />
          {temporaryVisitData?.status === 'Canceled' &&
            temporaryVisitData?.cancellationReasonDetails && (
              <Typography>
                Cancellation Reason: {temporaryVisitData?.cancellationReasonDetails?.name}
              </Typography>
            )}
        </Box>
      </Box>
      <Collapse in={needToConfirmStatus} timeout={300}>
        <StatusChangingConfirmation
          cancelNewStatus={cancelNewStatus}
          confirmNewStatus={onConfirmNewStatus}
          newStatus={temporaryVisitData?.status}
          newCancellationReason={temporaryVisitData?.cancellationReason}
        />
      </Collapse>
      {openCheckReschedulingDialog && (
        <CheckReschedulingTable
          closeRescheduling={closeRescheduling}
          confirmReschedulingVisit={confirmReschedulingVisit}
          isReschedulingDataLoading={isReschedulingDataLoading}
          openCheckReschedulingDialog={openCheckReschedulingDialog}
          reschedulingData={reschedulingData}
        />
      )}
      <OptimizerCheck
        data={{ added: [], edited: [optimizerData], deleted: [] }}
        dataObjName="visitObj"
        submitFunction={submitFunction}
        open={openCheckOptimizer}
        openToggle={setOpenCheckOptimizer}
      />
    </Box>
  );
}

StatusSelectorField.propTypes = {
  cancelNewStatus: func,
  changeStatus: func,
  closeNewStatusConfirmation: func,
  date: string,
  disabledSelect: bool,
  initialStatus: string,
  isFutureVisit: bool,
  isNotAllocatedVisit: bool,
  isUpdatingVisitStatus: bool,
  needToConfirmStatus: bool,
  reFetchCalendarEvents: func,
  scheduledVisit: bool,
  visitId: string,
};
