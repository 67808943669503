import { Box, Typography } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import OptimizerCheck from '../../../../../../../../components/Optimizer/OptimizerCheck';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import moment from 'moment';
import { useGetClientTasksListQuery } from '../../../../../../../../api/CarePlan/api';
import { useParams } from 'react-router';
import AlertContext from '../../../../../../../../components/Alert';
import { v4 as uuidv4 } from 'uuid';

function CheckAvailability({
  setStepDataLoading = () => {},
  submitStep = () => {},
  visitParams = {},
  resetToInitialView = () => {},
  setVisitParams = () => {},
  setStep = () => {},
}) {
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    setStepDataLoading(true);
  }, [setStepDataLoading]);

  const { data: clientTasks, error: getClientTasksError } = useGetClientTasksListQuery(
    { clientId: id },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (getClientTasksError) {
      const errorData = getClientTasksError;

      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getClientTasksError, setAlert]);

  useEffect(() => {
    if (clientTasks) {
      const validTasks = clientTasks?.filter(clientTask => {
        const arrivalStart = moment(visitParams.arrivalStart);
        const visitEnd = moment(visitParams.arrivalEnd).add(visitParams?.duration, 'minutes');
        let dayOfWeek = moment(visitParams?.date).day() - 1;
        if (dayOfWeek === -1) {
          dayOfWeek = 6;
        }
        const validTimeOfDays = clientTask.timeOfDaysDetails?.filter(timeOfDays => {
          const timeFrom = moment(
            `${visitParams?.date.substring(0, 10)} ${timeOfDays.from}`,
            'YYYY-MM-DD H:mm',
          );
          const timeTo = moment(
            `${visitParams?.date.substring(0, 10)} ${timeOfDays.to}`,
            'YYYY-MM-DD H:mm',
          );
          return (
            (arrivalStart.isSameOrAfter(timeFrom) && arrivalStart.isBefore(timeTo)) ||
            (visitEnd.isAfter(timeFrom) && visitEnd.isSameOrBefore(timeTo))
          );
        });
        return (
          clientTask.visitTypes.includes(visitParams.visitType) &&
          validTimeOfDays.length > 0 &&
          clientTask.days?.includes(dayOfWeek)
        );
      });
      setVisitParams(prev => ({
        ...prev,
        tasks:
          validTasks?.map(x => ({
            id: uuidv4(),
            status: 'todo',
            careplanId: x.id,
          })) ?? [],
        taskDetails: validTasks,
      }));
      setOpenCheckOptimizer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientTasks]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {/* TODO: Update this? */}
      <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
        Optimized
      </Typography>
      <OptimizerCheck
        data={{
          added: [visitParams],
          edited: [],
          deleted: [],
        }}
        dataObjName="visitObj"
        submitFunction={data => {
          const visitToCreate = data?.find(visit => visit?.id === visitParams?.id);
          if (visitToCreate) {
            setVisitParams(visitToCreate);
            submitStep();
          } else {
            resetToInitialView();
          }
        }}
        cancelFunction={() => setStep(1)}
        open={openCheckOptimizer}
        openToggle={setOpenCheckOptimizer}
        returnAPIData={true}
      />
    </Box>
  );
}

CheckAvailability.propTypes = {
  setStepDataLoading: func,
  submitStep: func,
  visitParams: instanceOf(Object),
  resetToInitialView: func,
  setVisitParams: func,
  setStep: func,
};

export default React.memo(CheckAvailability);
