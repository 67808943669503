export const eventCardInfo = {
  display: 'flex',
  flexDirection: 'column',
};

export const eventCardInfoTitle = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};
