import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
// import Chevron from '../../../../../../../../../img/icons/chevron-right.svg';
import {
  customAccordionStyles,
  customAccordionSummaryStyles,
  visitAccordionSummaryTitle,
} from '../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/styles';
import { func, instanceOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from '../../../../../../../../../shared/hooks/useDebounce';
import { COLORS } from '../../../../../../../../../shared/styles/Theme';
import { createVisitSummaryFields } from './constants';

export default function VisitSummary({
  data = {},
  setVisitSummaryHeight = () => {},
  visitSummaryRef = {},
}) {
  const [expanded, setExpanded] = useState(false);
  const debouncedChangeData = useDebouncedCallback(value => {
    setVisitSummaryHeight(value);
  }, 50);
  useEffect(() => {
    if (!visitSummaryRef.current) return undefined;
    const resizeObserver = new ResizeObserver(() => {
      debouncedChangeData(visitSummaryRef?.current?.clientHeight);
    });
    resizeObserver.observe(visitSummaryRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debouncedChangeData, visitSummaryRef]);
  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      ref={visitSummaryRef}
      sx={customAccordionStyles}
      className="visit-summary"
    >
      <AccordionSummary
        // expandIcon={
        //   <Chevron onClick={() => setExpanded((prevState) => !prevState)} />
        // }
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles}
      >
        <Box sx={visitAccordionSummaryTitle} onClick={() => setExpanded(prevState => !prevState)}>
          <Typography sx={{ lineHeight: '32px', fontSize: '20px', cursor: 'pointer' }} variant="h6">
            Visit Summary
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '28px',
          }}
        >
          {Object.values(createVisitSummaryFields)?.map(fieldsGroup => (
            <Box
              key={fieldsGroup?.map(field => field.name).toString()}
              sx={{
                display: 'flex',
                paddingRight: '50px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mr: '12px',
                }}
              >
                {fieldsGroup?.map(({ name, key }) =>
                  data[key] ? (
                    <Typography
                      variant="body1"
                      key={name}
                      sx={{
                        color: COLORS.blue[500],
                        mb: '16px',
                        lineHeight: '20px',
                      }}
                    >
                      {name}:
                    </Typography>
                  ) : null,
                )}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {fieldsGroup?.map(({ name, key }) =>
                  data[key] ? (
                    <Typography
                      key={name}
                      variant="body1"
                      sx={{
                        color: COLORS.blue[900],
                        mb: '16px',
                        lineHeight: '20px',
                      }}
                    >
                      {data[key] || ''}
                    </Typography>
                  ) : null,
                )}
              </Box>
            </Box>
          ))}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {data.arrivalWindow ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', mr: '12px' }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: COLORS.blue[500],
                      mb: '16px',
                      lineHeight: '20px',
                    }}
                  >
                    Arrival Window:
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: COLORS.blue[900],
                      mb: '16px',
                      lineHeight: '20px',
                      '& .initial-arrival-window': {
                        textDecoration: data.arrivalWindow?.newWindow ? 'line-through' : 'none',
                        color: data.arrivalWindow?.newWindow ? '#C7CCD6' : 'inherit',
                        pr: '5px',
                      },
                      '& .selected-arrival-window': {
                        fontStyle: data.arrivalWindow?.newWindow ? 'italic' : 'normal',
                      },
                    }}
                  >
                    <span className="initial-arrival-window">
                      {!data.arrivalWindow?.oldWindow && !data.arrivalWindow?.newWindow
                        ? 'N/A'
                        : data.arrivalWindow?.oldWindow
                        ? `${data.arrivalWindow?.oldWindow}`
                        : ''}
                    </span>
                    <span className="selected-arrival-window">
                      {data.arrivalWindow?.newWindow
                        ? `${data.arrivalWindow?.oldWindow ? ' ' : ''}${
                            data.arrivalWindow?.newWindow
                          }`
                        : ``}
                    </span>
                  </Typography>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

VisitSummary.propTypes = {
  data: instanceOf(Object),
  setVisitSummaryHeight: func,
  visitSummaryRef: instanceOf(Object),
};
