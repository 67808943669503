import { Box } from '@mui/material';
import { getEventBoxClass } from '../../../../pages/CaregiverDetails/view/Calendar/utils';
import { func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router';
import {
  DEFAULT_MAX_WORKING_HOUR,
  DEFAULT_MIN_WORKING_HOUR,
  eventsByCalendarTypes,
} from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { isWeekend } from '../../../../shared/utils/common';
import DefaultFiveDaysCalendar from '../../../../components/Calendar';
import CustomToolbar from '../../../../components/Calendar/CustomToolbar';
import SchedulingEventComponent from '../../../../components/CalendarsEventComponent';
import SevenDaysCalendar from '../../../../components/SevenDaysCalendar';
import SevenDaysCalendarCustomToolbar from '../../../../components/SevenDaysCalendar/SevenDaysCustomToolbar';
import { maxHour, minHour } from '../../../ClientDetails/views/Calendar/utils';

export default function CaregiverCalendarView({
  calendarDate = null,
  calendarHeader = () => {},
  calendarEvents = [],
  dayPropGetter = {},
  setCalendarDate = () => {},
  slotPropGetter = {},
}) {
  const { id } = useParams();
  const eventWrapperRef = useRef();
  const { onOpenVisitInfoTypeDialog } = useContext(VisitInfoTypeContext);
  const weekendEvents = calendarEvents?.some(event => isWeekend(event.start));

  const onSelectEvent = eventData => {
    if (id !== eventData?.shadowCaregiverId) {
      onOpenVisitInfoTypeDialog(eventData);
    }
  };

  const customEvent = useCallback(
    ({ event }) => (
      <SchedulingEventComponent event={event} eventType={eventsByCalendarTypes.caregiverCalendar} />
    ),
    [],
  );

  const getMinHour = () => (minHour(calendarEvents) ? minHour(calendarEvents) - 1 : 0);

  return (
    <Box sx={{ height: 'calc(100vh - 200px)' }} ref={eventWrapperRef}>
      {!weekendEvents ? (
        <DefaultFiveDaysCalendar
          customToolbar={CustomToolbar}
          date={calendarDate}
          dateHeaderWrapper={calendarHeader}
          dayPropGetter={dayPropGetter}
          eventComponent={customEvent}
          eventStyleGetter={getEventBoxClass}
          events={calendarEvents}
          firstVisit={calendarEvents?.length > 0 ? getMinHour() : DEFAULT_MIN_WORKING_HOUR}
          lastVisit={
            calendarEvents?.length > 0 ? maxHour(calendarEvents) + 1 : DEFAULT_MAX_WORKING_HOUR
          }
          onNavigate={date => setCalendarDate(date)}
          onSelectEvent={onSelectEvent}
          slotPropGetter={slotPropGetter}
          toolbar
        />
      ) : (
        <SevenDaysCalendar
          className="client-seven-days-calendar"
          customToolbar={SevenDaysCalendarCustomToolbar}
          date={calendarDate}
          dateHeaderWrapper={calendarHeader}
          dayPropGetter={dayPropGetter}
          eventComponent={customEvent}
          eventStyleGetter={getEventBoxClass}
          events={calendarEvents}
          firstVisit={calendarEvents?.length > 0 ? getMinHour() : DEFAULT_MIN_WORKING_HOUR}
          lastVisit={
            calendarEvents?.length > 0 ? maxHour(calendarEvents) + 1 : DEFAULT_MAX_WORKING_HOUR
          }
          onNavigate={date => setCalendarDate(date)}
          onSelectEvent={onSelectEvent}
          slotPropGetter={slotPropGetter}
          toolbar
        />
      )}
    </Box>
  );
}

CaregiverCalendarView.propTypes = {
  calendarDate: instanceOf(Date),
  calendarHeader: func,
  calendarEvents: instanceOf(Array),
  dayPropGetter: instanceOf(Object),
  setCalendarDate: func,
  slotPropGetter: instanceOf(Object),
};
