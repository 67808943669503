import { Box } from '@mui/material';
import moment from 'moment';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext, useEffect, useState } from 'react';
import TeamEffectiveDateModal from '../TeamEffectiveDateModal';
import TeamsSelect from '../TeamsSelect';
import { sortBy } from 'lodash';

export default function SecondaryRegionsSelector() {
  const {
    editingProfileDataLibrary,
    profileData,
    teamDate,
    setProfileData,
    caregiverProfileBeforeEdit,
  } = useContext(CaregiverProfileContext);
  const [optionIsOpen, setOptionIsOpen] = useState(false);
  const [date, setDate] = useState(
    moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  );
  const [pendingChanges, setPendingChanges] = useState({});
  const [teamEffectiveDateOpen, setTeamEffectiveDateOpen] = useState(false);

  const changeProfileData = () => {
    setProfileData({ ...pendingChanges });
  };

  const onSelectChange = (newDetails, newIds) => {
    const secondaryTeams = { ...profileData?.pendingChanges?.secondaryTeams } || {};
    secondaryTeams[moment(date).format('YYYY-MM-DD')] = newIds;
    const secondaryTeamsDetails = { ...profileData?.pendingChanges?.secondaryTeamsDetails } || {};
    secondaryTeamsDetails[moment(date).format('YYYY-MM-DD')] = newDetails;
    const pendingChanges = {
      ...profileData?.pendingChanges,
      secondaryTeams: secondaryTeams,
      secondaryTeamsDetails: secondaryTeamsDetails,
    };
    const newProfileData = { ...profileData, pendingChanges: pendingChanges };
    setPendingChanges({ ...newProfileData });
  };

  useEffect(() => {
    const keyCheck = Object.keys(pendingChanges?.pendingChanges?.secondaryTeamsDetails ?? {});
    if (keyCheck.length === 1) {
      setPendingChanges(prev => ({
        ...prev,
        pendingChanges: {
          ...prev.pendingChanges,
          secondaryTeams: {
            [moment(date).format('YYYY-MM-DD')]: prev?.pendingChanges?.secondaryTeams[keyCheck[0]],
          },
          secondaryTeamsDetails: {
            [moment(date).format('YYYY-MM-DD')]:
              prev?.pendingChanges?.secondaryTeamsDetails[keyCheck[0]],
          },
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <TeamsSelect
        id="secondaryTeams"
        options={sortBy(editingProfileDataLibrary?.teams, ['name'])}
        placeHolder="Secondary teams"
        selectedValue={
          pendingChanges?.pendingChanges?.secondaryTeamsDetails[moment(date).format('YYYY-MM-DD')]
            ? pendingChanges?.pendingChanges?.secondaryTeamsDetails[
                moment(date).format('YYYY-MM-DD')
              ]
            : profileData?.secondaryTeamsDetails
        }
        setValue={onSelectChange}
        isOpen={optionIsOpen}
        setIsOpen={setOptionIsOpen}
        onClose={() => {
          if (
            pendingChanges?.pendingChanges?.secondaryTeamsDetails[moment(date).format('YYYY-MM-DD')]
          ) {
            setDate(moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
            setTeamEffectiveDateOpen(true);
          }
          setOptionIsOpen(false);
        }}
      />
      <TeamEffectiveDateModal
        closeEffectiveDateDialog={() => {
          setTeamEffectiveDateOpen(false);
          setProfileData({
            ...profileData,
            pendingChanges: caregiverProfileBeforeEdit.pendingChanges,
          });
          setPendingChanges({});
        }}
        openEffectiveDateDialog={teamEffectiveDateOpen && profileData?.status === 'Active'}
        submitEffectiveDateDialog={changeProfileData}
        date={teamDate}
        selectDate={setDate}
      />
    </Box>
  );
}
