import { useGetConfigQuery } from '../../../../api/Config/api';
import { bool, func, instanceOf, oneOfType } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { VisitInfoAddTaskContext } from '../../../../shared/context';
import { v4 as uuidv4 } from 'uuid';
import AlertContext from '../../../../components/Alert';
import CustomDialog from '../../../../components/Dialog';
import Content from './components/Content';
import { oneTimeTaskInitialState } from './constants';

function AddTaskProvider({
  children = [],
  hasPredefinedData = false,
  tasksListData = [],
  updateTasksList = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [taskData, setTaskData] = useState(oneTimeTaskInitialState);

  const {
    data: configData,
    isLoading: isLoadingConfigs,
    error: configError,
  } = useGetConfigQuery(['categoryTypes', 'levelsOfAssistance', 'taskTypes'], {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (configError) {
      setAlert({
        errorData: configError,
        type: 'error',
      });
    }
  }, [configError, setAlert]);

  const addNewTask = useCallback(() => {
    let taskPredefinedData = {
      ...oneTimeTaskInitialState,
    };
    setTaskData(taskPredefinedData);
    setOpenDialog(true);
  }, []);

  const applyTaskData = () => {
    const parsedTaskData = {
      ...taskData,
      id: uuidv4(),
      status: 'toDo',
    };
    const newTasksList = [...tasksListData, parsedTaskData];

    updateTasksList({ tasks: newTasksList });
    setOpenDialog(false);
  };

  const isDisabledSave = data => {
    if (taskData?.id) {
      const editableTask = tasksListData.find(task => task.id === taskData.id);
      return JSON.stringify(editableTask) === JSON.stringify(taskData);
    }
    return Object.values(data).some(item => !item?.toString().length);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const memoizedProviderValue = useMemo(
    () => ({
      addNewTask,
    }),
    [addNewTask],
  );

  return (
    <VisitInfoAddTaskContext.Provider value={memoizedProviderValue}>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={closeDialog}
        disableOnCloseByClickOutside
        disabledSubmit={isDisabledSave(taskData)}
        openDialog={openDialog}
        submitButtonName="ADD"
        submitCallback={applyTaskData}
        title={taskData.id ? 'Edit Task' : 'Add Task'}
      >
        <Content
          hasPredefinedData={hasPredefinedData}
          setTaskData={setTaskData}
          taskData={taskData}
          isLoadingConfigs={isLoadingConfigs}
          configData={configData}
        />
      </CustomDialog>
      {children}
    </VisitInfoAddTaskContext.Provider>
  );
}

AddTaskProvider.propTypes = {
  children: oneOfType([instanceOf(Array), instanceOf(Object)]),
  hasPredefinedData: bool,
  tasksListData: instanceOf(Array),
  updateTasksList: func,
};

export default React.memo(AddTaskProvider);
