import { Typography } from '@mui/material';
import React from 'react';
import AvatarGroups from '../../../../components/AvatarGroup';
import BlockedCaregiversSelector from './BlockedCaregiversSelector';
import StatusSelector from './StatusSelector';
import TeamSelector from './TeamSelector';
import TeamsInfo from './TeamsInfo'

export const getInfoComponent = ({ type, value, editMode }) => {
  if (type === 'badge') {
    return <StatusSelector />;
  }
  if (type === 'address') {
    return (
      <Typography variant="body1">
        {value?.street || ''}, {value?.city || ''}, {value?.state || ''} {value?.zipCode || ''},{' '}
        {value?.country || ''}
      </Typography>
    );
  }
  if (type === 'text') {
    return <Typography variant="body1">{value || ''}</Typography>;
  }
  if (type === 'clientType') {
    return <Typography variant="body1">{value || ''}</Typography>;
  }
  if (type === 'team') {
    return editMode ? (
      <TeamSelector />
    ) : (
      <TeamsInfo />
    );
  }
  if (type === 'blockedCaregivers') {
    const fullNameArray = Array.isArray(value)
      ? value?.map(CaregiverName => `${CaregiverName}`)
      : null;
    return editMode ? (
      <BlockedCaregiversSelector />
    ) : (
      <AvatarGroups showTooltip maxLength={5} users={fullNameArray} />
    );
  }
  return false;
};
