import { Box, Typography, CircularProgress } from '@mui/material';
import {
  useDeleteTeamByIdMutation,
  useGetTerritoryQuery,
  useUpdateTeamsByIdMutation,
  useGetTeamsQuery
} from '../../../../../../api/Administration/api';
import EditIcon from '../../../../../../img/icons/edit-icon.svg';
import DeleteIcon from '../../../../../../img/icons/iconButtons/delete-icon.svg';
import { instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';
import TableRowActionsWithIcons from '../../../../../../components/TableRowActionsWithIcons';
import AddTeamModal from '../AddTeamModal';
import ErrorModalTeam from './ErrorModalTeam';
import {
  teamColorBoxStyles,
  teamColorStyles,
  teamFontColorStyles,
} from './styles';

function TeamTableActions({ row = {} }) {
  const { setAlert } = useContext(AlertContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [teamName, setTeamName] = useState(row?.name);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [order, setOrder] = useState(parseInt(row?.order, 10) || "");
  const [color, setColor] = useState(row?.color?.color);
  const [fontColor, setFontColor] = useState(row?.color?.fontColor);
  const [territory, setTerritory] = useState(row?.territory);
  const [responseTeamMessage, setResponseTeamMessage] = useState('');
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openExistingTeamDialog, setOpenExistingTeamDialog] = useState(false)

  const { data: territoryList, error: getTerritoryListError } =
    useGetTerritoryQuery();
  const { data: locations, error: getLocationsListError, isFetching} = useGetTeamsQuery();
  const [updateTeamById, { error: updateUserByIdError, isLoading: isUpdating}] =
    useUpdateTeamsByIdMutation();
  const [deleteTeam, { error: deleteUserByIdError, isLoading: isDeleting }] =
    useDeleteTeamByIdMutation();

  useEffect(() => {
    if (updateUserByIdError || getTerritoryListError || getLocationsListError) {
      const errorData = updateUserByIdError || getTerritoryListError || getLocationsListError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [setAlert, updateUserByIdError, getTerritoryListError, getLocationsListError]);

  useEffect(() => {
    if (deleteUserByIdError?.status === 405) {
      setOpenErrorModal(true);
    }
  }, [deleteUserByIdError?.status]);

  const updateUser = () => {
    const teamExists = locations?.some(item => item.name === teamName);
    if (teamExists) {
      setOpenExistingTeamDialog(true);
    } else {
    updateTeamById({
        ...row,
        name: teamName,
        order,
        color: { color, fontColor },
        territory: territory,
        type: "team"
    });
    setOpenEditDialog(false);
    }
  };

  const deleteTeamById = () => {
    setOpenDeleteDialog(false);
    deleteTeam({
      ...row,
      name: teamName,
      order,
      color: { color, fontColor },
      territory: territory,
      type: "team"
    }).then(response => {
      if (response?.data?.responseTerritoryMessage) {
        setResponseTeamMessage(response.data.responseTeamMessage);
        setOpenSuccessDialog(true);
      }
      setOpenDeleteDialog(false);
    }).catch(error => {
      console.error("Error deleting territory:", error);
    });
    
  };
  const menuItems = useMemo(
    () => [
      {
        icon: EditIcon,
        key: 'Edit',
        name: 'Edit',
        callback: () => {
          setOpenEditDialog(true);
          setOrder(parseInt(row?.order, 10) || 0);
        },
      },
      {
        icon: DeleteIcon,
        key: 'Remove',
        name: 'Remove',
        callback: () => {
          setOpenDeleteDialog(true);
        },
      },
    ],
    [row.order],
  );
  const cancelEditTerritory = () => {
    setOpenEditDialog(false);
    setOrder(parseInt(row?.order, 10) || 0);
    setTeamName(row?.name);
    setFontColor(row?.color?.fontColor);
    setColor(row?.color?.color);
  };
  
  const isLoading = isUpdating || isDeleting || isFetching;

  return (
    <>
    {isLoading && (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999, 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'all',
        }}
      >
        <CircularProgress />
      </Box>
    )}

    <Box
      sx={{
        pointerEvents: isLoading ? 'none' : 'auto',
      }}
    >
      <Box sx={teamColorBoxStyles}>
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          <Box sx={teamColorStyles(row)} />
          <Typography sx={teamFontColorStyles(row)}>
            {row?.color?.color}
          </Typography>
        </Box>

        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={cancelEditTerritory}
          submitButtonName="Save"
          disabledSubmit={teamName?.length === 0}
          submitCallback={updateUser}
          title="Edit Team"
          openDialog={openEditDialog}
        >
          <Box sx={{ width: '100%', padding: '0 28px 20px 13px' }}>
            <AddTeamModal
              teamName={teamName}
              setTeamName={setTeamName}
              teamData={locations}
              order={order}
              setOrder={setOrder}
              color={color}
              fontColor={fontColor}
              setColor={setColor}
              setFontColor={setFontColor}
              territoryOptions={territoryList}
              territory={territory}
              setTerritory={setTerritory}
            />
          </Box>
        </CustomDialog>

        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={() => setOpenDeleteDialog(false)}
          submitButtonName="confirm"
          submitCallback={deleteTeamById}
          title="Confirmation"
          openDialog={openDeleteDialog}
        >
          <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
            <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
              The Team
              <span style={{ fontWeight: 'bold', margin: '0 4px' }}>
                {row.name}
              </span>
              will be removed. Do you want to proceed?
            </Typography>
          </Box>
        </CustomDialog>

        <CustomDialog
          submitButtonName="OK"
          submitCallback={() => setOpenSuccessDialog(false)}
          title="Unable to Remove Team"
          openDialog={openSuccessDialog}
        >         
          <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
            <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
              <span style={{ fontWeight: 'bold' }}>
                The following Clients and Caregivers are using this team:
              </span>
              <br />
              <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                <li
                  dangerouslySetInnerHTML={{
                    __html: responseTeamMessage.replace(/\n/g, '<br />'),
                  }}
                />
              </ul>
            </Typography>
          </Box>
        </CustomDialog>

        <CustomDialog
          submitButtonName="OK"
          submitCallback={() => setOpenExistingTeamDialog(false)}
          title="Team Name Conflict"
          openDialog={openExistingTeamDialog}
        >
          <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
            <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
              <span>This team name is already in use. Please select a different one.</span>   
            </Typography>
          </Box>
        </CustomDialog>

        <ErrorModalTeam
          openDeleteDialog={openErrorModal}
          setOpenDeleteDialog={setOpenErrorModal}
          errorData={deleteUserByIdError?.data?.payload}
        />

        <TableRowActionsWithIcons menuItems={menuItems} />
      </Box>
    </Box>
  </>
)}

  TeamTableActions.propTypes = {
    row: instanceOf(Object),
  };
  
  export default React.memo(TeamTableActions);
  
