import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetJobsByIdMutation } from '../../../../api/Scheduler/api';
import { func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import { getNoData } from '../../../../shared/utils/common';
import Loader from '../../../../components/Loader';
import CustomTable from '../../../../components/Table';
import CustomNavigation from '../CustomNavigation';
import SchedulingToolbar from '../SchedulingToolbar';
import Filters from './components/Filters';
import VisitVerification from './components/VisitVerification';
import {
  DEFAULT_SORT_MODAL,
  INITIAL_SCHEDULING_FILTERS,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  columns,
  statusOptions,
  violationOptions,
} from './constants';
import { visitsTableStyles } from './styles';

function VisitsTableView({
  date = {},
  eventsArray = [],
  isDateHoliday = {},
  onNavigate = () => {},
  selectedDate = {},
  teamsFilter = [],
  setTeamFilter = () => {},
  locationsList = [],
  reFetchCalendarEvents = () => {},
  reOptimize = () => {},
}) {
  const apiRef = useGridApiRef();
  const [getJobsById, { isLoading: getJobsByIdSearching }] = useGetJobsByIdMutation();
  const [visitsFilters, setVisitsFilters] = useState(INITIAL_SCHEDULING_FILTERS);
  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODAL);
  const [verificationMode, setVerificationMode] = useState(false);
  const { onOpenVisitInfoTypeDialog } = useContext(VisitInfoTypeContext);

  useEffect(() => {
    setVisitsFilters(prevValue => ({
      ...prevValue,
      status: statusOptions?.map(t => t),
      show: violationOptions?.map(t => t),
      teams: teamsFilter?.teams?.map(t => t.name),
    }));
  }, [setVisitsFilters, teamsFilter]);
  useEffect(() => {
    setSortModel(DEFAULT_SORT_MODAL);
  }, [visitsFilters]);

  const openVisitInfo = useCallback(
    visitData => {
      if (!visitData?.isShadowVisit) {
        onOpenVisitInfoTypeDialog(visitData);
      }
    },
    [onOpenVisitInfoTypeDialog],
  );
  const filterByJobID = value => {
    const jobIdSearchQuery = value?.trim()?.toLowerCase();
    const newArray = eventsArray.filter(({ externalId }) => externalId?.includes(jobIdSearchQuery));
    if (newArray.length) {
      apiRef.current.setRows(newArray);
    } else {
      getJobsById(value)
        .unwrap()
        .then(data => {
          if (data.length === 1) {
            openVisitInfo(data[0]);
          }
          apiRef.current.setRows(data);
        })
        .catch(() => {
          apiRef.current.setRows([]);
        });
    }
  };

  const debouncedSearch = useDebouncedCallback(e => {
    if (e.target.name === 'jobId') {
      filterByJobID(e.target.value);
    } else {
      setVisitsFilters({ ...visitsFilters, [e.target.name]: e.target.value });
    }
  }, 500);

  const filterEvents = useCallback(() => {
    const clientSearchQuery = visitsFilters.client?.trim()?.toLowerCase();
    const caregiverSearchQuery = visitsFilters.caregiver?.trim()?.toLowerCase();
    // const jobIdSearchQuery = visitsFilters.jobId?.trim()?.toLowerCase();

    const filteredEvents = eventsArray.filter(event => {
      const {
        caregiverName,
        clientName,
        status,
        teamDetails,
        violatedSoftConstraints,
        violatedHardConstraints,
      } = event;
      const isStatusMatched = visitsFilters?.status?.includes(status);
      const isTeamMatched = visitsFilters.teams?.includes(teamDetails.name);
      const selectedViolationOptions = visitsFilters.show;

      const isNoViolationsSelected = selectedViolationOptions?.includes('No Violations');
      const isSoftConstraintsSelected = selectedViolationOptions?.includes(
        'Soft Constraints Violations',
      );
      const isHardConstraintsSelected = selectedViolationOptions?.includes(
        'Hard Constraints Violations',
      );
      const notViolatedSoftConstraint =
        violatedSoftConstraints?.length === 0 || !violatedSoftConstraints;
      const notViolatedHardConstraint =
        violatedHardConstraints?.length === 0 || !violatedHardConstraints;
      const shouldShowVisit =
        (isNoViolationsSelected && notViolatedSoftConstraint && notViolatedHardConstraint) ||
        (isSoftConstraintsSelected && violatedSoftConstraints?.length > 0) ||
        (isHardConstraintsSelected && violatedHardConstraints?.length > 0);

      if (
        visitsFilters.status?.length === 0 ||
        visitsFilters.teams?.length === 0 ||
        visitsFilters.show?.length === 0
      ) {
        return false;
      }
      return (
        (caregiverName?.toLowerCase()?.includes(caregiverSearchQuery) ||
          caregiverSearchQuery === '') &&
        (clientName?.toLowerCase()?.includes(clientSearchQuery) || clientSearchQuery === '') &&
        // (externalId?.includes(jobIdSearchQuery) || jobIdSearchQuery === '') &&
        (visitsFilters.status?.length === 0 || isStatusMatched) &&
        (visitsFilters.team?.length === 0 || isTeamMatched) &&
        shouldShowVisit
      );
    });
    apiRef.current.setRows(filteredEvents);
  }, [apiRef, visitsFilters, eventsArray]);
  useEffect(() => {
    filterEvents();
  }, [visitsFilters, apiRef, filterEvents]);

  const updateSorting = useCallback(data => setSortModel(data[0]), []);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          mb: '30px',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <CustomNavigation
            date={date}
            isDateHoliday={isDateHoliday}
            isTable
            onNavigate={onNavigate}
            reOptimize={reOptimize}
          />
        </Box>

        <SchedulingToolbar
          selectedDate={selectedDate}
          isDateHoliday={isDateHoliday}
          verificationMode={verificationMode}
          setVerificationMode={setVerificationMode}
        />
      </Box>
      <Filters
        blockedFilters={getJobsByIdSearching}
        debouncedSearch={debouncedSearch}
        filterData={visitsFilters}
        locationsList={locationsList}
        setFilterData={setVisitsFilters}
        setTeamFilter={setTeamFilter}
        statusOptions={statusOptions}
        teamsFilter={teamsFilter}
        violationOptions={violationOptions}
      />
      {getJobsByIdSearching && <Loader />}
      <Box
        sx={{
          padding: '30px 10px 0 10px',
        }}
      >
        <CustomTable
          apiRef={apiRef}
          columns={columns}
          customStyles={visitsTableStyles}
          headerHeight={44}
          onRowClick={({ row }) => openVisitInfo(row)}
          pageSize={PAGE_SIZE}
          noRowsOverlay={() => getNoData({ title: 'No Visits for today' })}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          rows={eventsArray}
          setSortModelState={updateSorting}
          sortModel={[sortModel]}
        />
        <VisitVerification
          actualVisit={eventsArray}
          allLocations={locationsList}
          date={date}
          debouncedSearch={debouncedSearch}
          filterData={visitsFilters}
          open={verificationMode}
          setFilterData={setVisitsFilters}
          setOpen={setVerificationMode}
          setTeamFilter={setTeamFilter}
          setVerificationMode={setVerificationMode}
          statusOptions={statusOptions}
          teamsFilter={teamsFilter}
          verificationMode={verificationMode}
          violationOptions={violationOptions}
          reFetchCalendarEvents={reFetchCalendarEvents}
        />
      </Box>
    </Box>
  );
}

VisitsTableView.propTypes = {
  date: instanceOf(Object),
  onNavigate: func,
  setTeamFilter: func,
  eventsArray: instanceOf(Array),
  teamsFilter: instanceOf(Object),
  locationsList: instanceOf(Array),
  selectedDate: instanceOf(Object),
  isDateHoliday: instanceOf(Object),
  reFetchCalendarEvents: func,
  reOptimize: func,
};

export default VisitsTableView;
