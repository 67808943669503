import { Box } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import {
  timedVisitTypeIcons,
  visitTypeIcons,
} from '../../../../../../../../../../shared/constants';
import NumericInput from '../../../../../../../../../../components/NumericInput';
import CustomSelect from '../../../../../../../../../../components/Select';
import { selectVisitTypeStyles } from '../styles';
import { sortBy } from 'lodash';

export default function VisitTypeBlock({
  changeDuration = () => {},
  changeVisitType = () => {},
  filtersData = {},
  visitData = {},
}) {
  const displayedVisitTypes = filtersData?.visitTypes
    .filter(visitType => visitType.name !== 'On Demand' && visitType.name !== 'A La Carte')
    .map(visitType => {
      if (visitType.duration >= 180) {
        return visitType;
      } else {
        const hours = Math.floor(visitType.duration / 60);
        const minutes = visitType.duration % 60;
        return {
          ...visitType,
          name: `${visitType.name} (${
            visitType.duration >= 60
              ? `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`
              : `${minutes}m`
          })`,
        };
      }
    })
    .sort((a, b) => a.duration - b.duration);

  const mapVisitTypeUpdate = selectedValue => {
    const actualVisitType = filtersData?.visitTypes.find(
      visitType => visitType.id === selectedValue.id,
    );
    if (actualVisitType) {
      changeVisitType({ ...actualVisitType, field: 'visitType' });
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CustomSelect
        addStyles={selectVisitTypeStyles}
        endIcon={
          <img
            style={{ marginRight: '24px' }}
            src={visitTypeIcons[visitData?.visitTypeDetails?.name?.toLowerCase()]}
            alt={visitData?.visitTypeDetails?.name}
          />
        }
        icons={timedVisitTypeIcons}
        id="visitType"
        isLoading={false}
        isRequired
        options={displayedVisitTypes}
        placeHolder="Visit type"
        selectedValue={visitData?.visitTypeDetails?.id || ''}
        setValue={mapVisitTypeUpdate}
        withIcon
      />
      <NumericInput
        changeValue={changeDuration}
        disabled={visitData?.visitType?.name !== 'Custom visit'}
        isRequired
        label="Duration"
        oneTimeVisit
        step={5}
        value={visitData?.duration || ''}
      />
    </Box>
  );
}

VisitTypeBlock.propTypes = {
  changeDuration: func,
  changeVisitType: func,
  filtersData: instanceOf(Object),
  visitData: instanceOf(Object),
};
