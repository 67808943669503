import { Box, Typography } from '@mui/material';
import WarningIcon from '../../../../../img/icons/warning-icon.svg';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import { SHORT_DATE_FORMAT } from '../../../../../shared/constants';
import CustomDialog from '../../../../../components/Dialog';

function ClientRemovePendingChangeDialog({
  openDeactivationModal = false,
  closeDeactivationModal = () => {},
  confirmDeactivation = () => {},
  date = {},
}) {
  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={closeDeactivationModal}
      submitButtonName="Confirm"
      submitCallback={confirmDeactivation}
      title="Remove Pending Status Change"
      openDialog={openDeactivationModal}
    >
      <Box sx={{ padding: '0 25px 20px 25px', maxWidth: '100%' }}>
        <Box sx={{ display: 'flex', gap: 1, mb: '10px' }}>
          <img src={WarningIcon} alt="Warning" />
          <Typography>
            Remove Pending Status Change
          </Typography>
        </Box>
        <Typography>
          This cannot be undone. Would you like to proceed?
        </Typography>
      </Box>
    </CustomDialog>
  );
}

ClientRemovePendingChangeDialog.propTypes = {
  openDeactivationModal: bool,
  closeDeactivationModal: func,
  confirmDeactivation: func,
  date: instanceOf(Object),
};

export default ClientRemovePendingChangeDialog;
