import { Box } from '@mui/material';
import moment from 'moment';
import { bool, func, instanceOf, number, string } from 'prop-types';
import React from 'react';
import { H12_TIME_FORMAT } from '../../../../../shared/constants';
import CustomTimeSelect from '../../../../../components/CustomDateAndTimePickers/CustomTimeSelect';

export default function ArrivalWindow({
  arrivalEnd = '',
  arrivalStart = '',
  changeArrivalTimeRange = () => {},
  customStyles = {},
  gap = 0,
  isRequired = false,
  setError = () => {},
}) {
  const updateArrivalWindow = ({ startTime, endTime }) => {
    const arrivalRange = {
      arrivalStart: moment(startTime).isValid() ? startTime.format(H12_TIME_FORMAT) : '',
      arrivalEnd: moment(endTime).isValid() ? endTime.format(H12_TIME_FORMAT) : '',
    };
    changeArrivalTimeRange(arrivalRange);
  };

  const onStartChange = ({ newValue, endTimeValue }) => {
    const startTime = moment(newValue, H12_TIME_FORMAT);
    const endTime = moment(endTimeValue, H12_TIME_FORMAT);
    updateArrivalWindow({ startTime, endTime });
    if (startTime.isSameOrAfter(endTime)) {
      setError('You have been inputted wrong arrival window time');
      return false;
    }
    setError(null);
    return false;
  };

  const onEndChange = ({ newValue, startTimeValue }) => {
    const startTime = moment(startTimeValue, H12_TIME_FORMAT);
    const endTime = moment(newValue, H12_TIME_FORMAT);
    updateArrivalWindow({ startTime, endTime });
    if (endTime.isSameOrBefore(startTime)) {
      setError('You have been inputted wrong arrival window time');
      return false;
    }
    setError(null);
    return false;
  };

  return (
    <div>
      <Box sx={{ display: 'flex', mb: '10px', gap: gap ? `${gap}px` : '20px' }}>
        <CustomTimeSelect
          customStyles={customStyles}
          isRequired={isRequired}
          onSelectTimeValue={value => onStartChange({ newValue: value, endTimeValue: arrivalEnd })}
          placeHolder="Arrival Window Start"
          selectedTime={arrivalStart}
        />
        <CustomTimeSelect
          customStyles={customStyles}
          isRequired={isRequired}
          onSelectTimeValue={value =>
            onEndChange({ newValue: value, startTimeValue: arrivalStart })
          }
          placeHolder="Arrival Window End"
          selectedTime={arrivalEnd}
        />
      </Box>
    </div>
  );
}

ArrivalWindow.propTypes = {
  arrivalEnd: string,
  arrivalStart: string,
  changeArrivalTimeRange: func,
  customStyles: instanceOf(Object),
  gap: number,
  isRequired: bool,
  setError: func,
};
