import { COLORS } from '../../../../../../../../../shared/styles/Theme';

export const visitMultistepWrapper = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 60px',
  minWidth: '1420px',
  overflow: 'hidden',
};

export const visitMultistepBreadcrumbs = {
  display: 'flex',
  justifyContent: 'space-between',
  mb: '18px',
};

export const checkAvailabilityWrapperStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '40px',
  width: '100%',
  margin: 'auto',
};

export const selectVisitTypeStyles = {
  maxWidth: '250px',
  marginRight: '20px',
  '& .MuiSelect-select': {
    height: '40px !important',
  },
  '&.MuiOutlinedInput-root.MuiOutlinedInput-input': {
    paddingRight: 0,
  },
  '&.MuiInputBase-input-MuiOutlinedInput-input': {
    paddingRight: 0,
  },
};

export const checkAvailabilitySchedulePatternWrapperStyles = {
  display: 'flex',
  alignItems: 'end',
  width: '100%',
  gap: '26px',
  pb: '16px',
  mb: '16px',
};

export const customAccordionStyles = {
  backgroundColor: COLORS.blue[50],
  '&:before': {
    display: 'none',
  },
  padding: '0 32px',
  margin: '0 0 24px 0',
  '&.MuiPaper-root.MuiAccordion-root': {
    borderRadius: '10px',
  },
  '&.Mui-expanded': {
    margin: '0 0 24px 0',
  },
};

export const customAccordionSummaryStyles = {
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  margin: '12px 0',
  cursor: 'default',
  '&.MuiAccordionSummary-root, &.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '32px',
  },
  '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
    justifyContent: 'space-between',
    margin: 0,
    paddingRight: '5px',
    cursor: 'default',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
};

export const caregiverNameStyles = {
  display: 'flex',
  alignItems: 'center',
  '& span': {
    color: COLORS.blue[500],
    mr: '12px',
  },
};

export const visitAccordionSummaryTitle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  cursor: 'pointer',
};

export const visitAccordionIconButtonStyles = {
  border: `1px solid ${COLORS.blue[200]}`,
  borderRadius: '10px',
  '&.MuiIconButton-root.Mui-disabled': {
    cursor: 'default',
    '& img': {
      opacity: 0.5,
    },
  },
  '&:hover': { backgroundColor: COLORS.blue[50] },
};

export const checkAvailabilityColumnsWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '40px',
  maxWidth: '1080px',
  width: '100%',
  margin: 'auto',
};

export const checkAvailabilityCustomAlert = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  mb: '16px',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
};
