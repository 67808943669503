import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, List, ListItem, ListItemIcon, Stack, Tooltip, Typography } from '@mui/material';
import noDataIcon from '../../img/icons/no-data.svg';
import repeatedVisit from '../../img/icons/visitTypes/repeatedVisit.svg';
import moment from 'moment';
import { LOCAL_STORAGE_KEYS } from '../../pages/Scheduling/constants';
import { teamsTooltipStyles } from '../../pages/Scheduling/styles';
import React from 'react';
import {
  CALIFORNIA_TIME_ZONE,
  DAYS_MAP,
  H12_TIME_FORMAT,
  HISTORY_UPDATE_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
  timeOfDays,
  visitStatusesMap,
  visitTypeIcons,
} from '../../shared/constants';
import { COLORS } from '../styles/Theme';

export const sortArray = (array, key, reversed) =>
  [...array].sort((a, b) => {
    if (a[key] < b[key]) {
      return reversed ? 1 : -1;
    }
    if (a[key] > b[key]) {
      return reversed ? -1 : 1;
    }
    return 0;
  });

export const sortArrayOfDates = array => [...array].sort((a, b) => a.diff(b));

export const sortObjectTimeOfDays = object =>
  [...(object?.timeOfDays ? object?.timeOfDays : [])].sort(
    (a, b) => moment(a.from, H12_TIME_FORMAT).unix() - moment(b.from, H12_TIME_FORMAT).unix(),
  );

export const isItemsContainSubstring = (item, subString) =>
  item?.toLowerCase()?.indexOf(subString?.toLowerCase()) > -1;

export const filterVisitsByEffectiveDate = visits => {
  const isBefore = date => moment(date, ISO_DATE_ONLY_FORMAT).startOf('day').isBefore(moment());
  return visits?.filter(
    ({ effectiveDateEnd }) => !(effectiveDateEnd && isBefore(effectiveDateEnd)),
  );
};
export const addPropertyName = ({ type, skillsData, filtersData }) => {
  let propertyName;
  if (type === null) {
    return '';
  }
  const timesOfDayNames = filtersData?.timeOfDays?.map(({ name }) => name.toLowerCase());

  if (timesOfDayNames?.includes(type?.toLowerCase())) {
    propertyName = 'timeOfDays';
  }

  const days = DAYS_MAP?.map(({ id }) => id);

  if (days?.includes(type?.toLowerCase())) {
    propertyName = 'days';
  }

  if (filtersData?.shortVisitTypes?.includes(type)) {
    propertyName = 'visitTypes';
  }

  const skillsList = skillsData?.skills?.map(({ name }) => name);
  if (type === 'Shopping') {
    propertyName = 'Skills';
  }
  if (skillsList?.includes(type)) {
    propertyName = 'Skills';
  }
  return propertyName;
};

export const minHour = arr => {
  arr?.reduce((min, item) => {
    const timeParts = item?.arrivalStart?.split(':');
    const hours = parseInt(timeParts[0], 10);
    const isPM = timeParts[1]?.includes('PM');

    const hours24 = isPM ? hours + 12 : hours;

    return Math.min(min, hours24);
  });
};
export const maxHour = arr =>
  arr?.reduce((max, item) => {
    const timeParts = item?.arrivalEnd?.split(':');
    const hours = parseInt(timeParts[0], 10);
    const isPM = timeParts[1]?.includes('PM');
    const hours24 = isPM ? hours + 12 : hours;

    const duration = parseInt(item.duration, 10);

    const maxWithDuration = hours24 + Math.floor(duration / 60);

    return Math.max(max, maxWithDuration);
  }, 0);

const weekdaysOrder = DAYS_MAP.reduce((a, v) => ({ ...a, [v.name]: v.sortingKey }), {});

export const sortWeekdaysFirst = (a, b) => {
  const dayOrderA = a?.to !== null ? weekdaysOrder[a?.to] || 0 : weekdaysOrder[a?.from] || 0;
  const dayOrderB = b?.to !== null ? weekdaysOrder[b?.to] || 0 : weekdaysOrder[b?.from] || 0;
  return dayOrderA - dayOrderB;
};

export const sortingDayNamesArray = (a, b) => {
  const dayA = weekdaysOrder[a];
  const dayB = weekdaysOrder[b];
  return dayA - dayB;
};

const timesOfOrder = timeOfDays.reduce((a, v) => ({ ...a, [v.name]: v.sortingKey }), {});

export const sortTypeOfDaysFirst = (a, b) => {
  const dayOrderA = a?.to !== null ? timesOfOrder[a?.to] || 0 : timesOfOrder[a?.from] || 0;
  const dayOrderB = b?.to !== null ? timesOfOrder[b?.to] || 0 : timesOfOrder[b?.from] || 0;
  return dayOrderA - dayOrderB;
};
export const getIconComponent = type => (
  <img src={visitTypeIcons[type?.toLowerCase()]} alt={type} />
);
export const getBgColor = status => {
  let backgroundColor = '';
  if (status === visitStatusesMap.verified) {
    backgroundColor = '#FFFFFF';
  }
  if (status === visitStatusesMap.enRoute) {
    backgroundColor = '#BA8BFF';
  }
  if (status === visitStatusesMap.onSite) {
    backgroundColor = '#9450FF';
  }
  if (status === visitStatusesMap.canceled) {
    backgroundColor = '#FF402E';
  }
  if (status === visitStatusesMap.completed) {
    backgroundColor = '#09AF00';
  }
  if (status === visitStatusesMap.scheduled) {
    backgroundColor = '#2B77FF';
  }
  if (status === visitStatusesMap.allocated) {
    backgroundColor = '#E3E8F4';
  }
  if (status === visitStatusesMap.cannotBeAllocated) {
    backgroundColor = '#FF402E';
  }
  if (status === visitStatusesMap.notAllocated) {
    backgroundColor = '#FF402E';
  }
  if (status === visitStatusesMap.inProgress) {
    backgroundColor = '#09AF00';
  }
  return backgroundColor;
};

export const getStatusComponent = status => (
  <Typography
    variant="body2"
    sx={{
      fontWeight: 600,
      color: status === 'Verified' || status === 'Lunch Break' ? COLORS.green[700] : '#fff',
      borderRadius: '4px',
      background: getBgColor(status),
      padding: status === 'Lunch Break' ? '0px' : '0 4px',
      textWrap: 'nowrap',
    }}
  >
    {status}
  </Typography>
);
export const getLargeSizeStatusComponent = status => (
  <Typography
    variant="body2"
    sx={{
      width: '100px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600,
      color: '#fff',
      borderRadius: '30px',
      background: status === 'Verified' ? COLORS.green[700] : getBgColor(status),
      fontSize: '13px',
      textAlign: 'center',
    }}
  >
    {status}
  </Typography>
);

export const getOneTimeOrRepeatedIcon = type => {
  if (type?.toLowerCase() === 'weekly') {
    return <img src={repeatedVisit} alt="" />;
  }
  return false;
};
export const isWeekend = date => {
  const dayOfWeek = date?.getDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
};
export const roundToNearestFiveMinutes = time => {
  const minutes = time.minutes();
  const roundedMinutes = Math.round(minutes / 5) * 5;
  return moment(time).minutes(roundedMinutes).seconds(0);
};

export const roundUpFifteenMinutes = time => {
  const start = moment(time);
  const remainder = 15 - (start.minute() % 15);
  return moment(start).add(remainder, 'minutes');
};

export const convertUtcToTeamTimeZoneMomentObject = date => moment(date).tz(CALIFORNIA_TIME_ZONE);
export const getArrivalRange = data => {
  const isStartEndTimeSame = convertUtcToTeamTimeZoneMomentObject(data?.arrivalStart).isSame(
    convertUtcToTeamTimeZoneMomentObject(data?.arrivalEnd),
    'minute',
  );
  return isStartEndTimeSame
    ? `${convertUtcToTeamTimeZoneMomentObject(data?.arrivalStart).format(H12_TIME_FORMAT)}`
    : `${convertUtcToTeamTimeZoneMomentObject(data?.arrivalStart).format(
        H12_TIME_FORMAT,
      )} - ${convertUtcToTeamTimeZoneMomentObject(data?.arrivalEnd).format(H12_TIME_FORMAT)}`;
};

export const getAllPrimaryLocations = array => {
  const primaryLocations = [];
  array?.forEach(item => {
    if (item.parent === null) {
      primaryLocations.push({ id: item.id, name: item.name });
    }

    if (Array.isArray(item.children) && item.children.length > 0) {
      getAllPrimaryLocations(item.children);
    }
  });
  return primaryLocations;
};

const objectsAreEqual = (o1, o2) => {
  if (o1 === null || o1 === undefined || o2 === null || o2 === undefined) {
    return false;
  }

  if (Object.keys(o1).length !== Object.keys(o2).length) {
    return false;
  }

  return Object.keys(o1).every(p => {
    if (typeof o1[p] === 'object' && typeof o2[p] === 'object') {
      if (o1[p] === null && o2[p] === null) return true;
      if (o1[p] === null || o2[p] === null) {
        return false;
      }
      return objectsAreEqual(o1[p], o2[p]);
    }
    return o1[p] === o2[p];
  });
};

export const checkEqualArrays = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort((a, b) => a.id.localeCompare(b.id));
  const sortedArr2 = arr2.slice().sort((a, b) => a.id.localeCompare(b.id));

  return sortedArr1.every((o, idx) => objectsAreEqual(o, sortedArr2[idx]));
};

export const checkEqualStringArrays = (arr1, arr2) => {
  const array1 = arr1?.slice().sort();
  const array2 = arr2?.slice().sort();

  if (array1?.length !== array2?.length) {
    return false;
  }
  if (arr1?.length === 0 && arr2.length === 0) {
    return true;
  }
  if (array1?.length === 0) {
    return false;
  }

  if (array2?.length === 0) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
};

export const checkIfIsHoliday = (date, holidays, currentDate) => {
  const currentWeekStart = moment(currentDate).startOf('week');
  const currentWeekEnd = moment(currentDate).endOf('week');

  return holidays?.find(holiday => {
    const holidayDate = moment(holiday?.date);
    return (
      holidayDate.isBetween(currentWeekStart, currentWeekEnd, null, '[]') &&
      moment(date).isSame(holidayDate, 'day')
    );
  });
};

export const capitalizeFirstLetter = inputString => {
  if (inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
  }
  return '';
};
export const comparingFullNames = (first, second) => {
  const firstUser = `${first?.firstName} ${first?.lastName}`;
  const secondUser = `${second?.firstName} ${second?.lastName}`;
  return firstUser.localeCompare(secondUser);
};

export const getStatusChangingInfo = (clientData, isCaregiver) => {
  if (clientData?.pendingChanges?.status) {
    let info = '';
    for (let date in clientData?.pendingChanges?.status) {
      const newStatus = clientData?.pendingChanges?.status[date];
      let prePart = '';
      if (info !== '') {
        prePart = ' -- ';
      }
      info += `${prePart}${newStatus} ${moment(date, ISO_DATE_ONLY_FORMAT).format(
        SHORT_DATE_FORMAT,
      )}`;
    }
    return info;
  }
};
export const getEventBoxClass = event => {
  let className = 'default';
  if (event.visitType === 'Lunch break') {
    className += ' lunchBreak';
  }
  if (event?.title === 'arrival') {
    className += ` arrival  ${event?.region?.id} ${event?.client?.firstName} `;
  }
  if (event?.title === 'Lunch break') {
    className += ` lunchBreak`;
  }
  if (event?.status === 'Scheduled') {
    className += ' event-scheduled';
  }
  if (event?.status === 'En route') {
    className += ' event-en-route';
  }
  if (event?.status === 'On site') {
    className += ' event-on-site';
  }
  if (event?.status === 'In progress') {
    className += ' event-in-progress';
  }
  if (event?.status === 'Completed') {
    className += ' event-completed';
  }
  if (event?.status === 'Verified') {
    className += ' event-verified';
  }
  if (event?.status === 'Canceled') {
    className += ' event-canceled';
  }
  if (!event?.caregiver && event.status !== 'Canceled') {
    className += ' without-caregiver';
  }

  return {
    className,
  };
};
export const isValidEmail = email => /\S+@\S+\.\S+/.test(email);
export const isValidText = text => /^[A-Za-z_-][A-Za-z0-9\s_-]*$/.test(text);

export const getNoData = ({ title, styles }) => (
  <Stack height="100%" alignItems="center" justifyContent="center" sx={styles}>
    <img src={noDataIcon} alt="" />
    <Typography sx={{ mt: '5px' }} component="span">
      {title}
    </Typography>
  </Stack>
);
export const checkIfShowArrivalWindow = status => {
  let showArrivalWindow = false;

  if (
    [
      visitStatusesMap.allocated,
      visitStatusesMap.cannotBeAllocated,
      visitStatusesMap.scheduled,
      visitStatusesMap.enRoute,
      visitStatusesMap.onSite,
      visitStatusesMap.notAllocated,
    ].includes(status)
  ) {
    showArrivalWindow = true;
  }
  return showArrivalWindow;
};

export const combineISODateString = ({ date, time }) => `${date} ${time}`;

export const convertDateToUTC = ({ date, dateFormat, dateTimeZone }) =>
  moment.tz(date, dateFormat || null, dateTimeZone).toISOString();

export const parseVisitTime = visitData =>
  visitData
    .map(eventData => ({
      ...eventData,
      arrivalTime: eventData?.arrivalTime
        ? convertUtcToTeamTimeZoneMomentObject(eventData?.arrivalTime).format(H12_TIME_FORMAT)
        : null,
      arrivalStart: eventData?.arrivalStart
        ? convertUtcToTeamTimeZoneMomentObject(eventData?.arrivalStart).format(H12_TIME_FORMAT)
        : null,
      arrivalEnd: eventData?.arrivalEnd
        ? convertUtcToTeamTimeZoneMomentObject(eventData?.arrivalEnd).format(H12_TIME_FORMAT)
        : null,
      actualTimeStart:
        eventData?.actualTimeStart && eventData?.actualTimeStart !== 'NOT STARTED'
          ? convertUtcToTeamTimeZoneMomentObject(eventData?.actualTimeStart).format(H12_TIME_FORMAT)
          : null,
      actualTimeEnd:
        eventData?.actualTimeEnd && eventData?.actualTimeEnd !== 'NOT ENDED'
          ? convertUtcToTeamTimeZoneMomentObject(eventData?.actualTimeEnd).format(H12_TIME_FORMAT)
          : null,
    }))
    .map(event => ({
      ...event,
      scheduledTime: event?.arrivalTime || event?.arrivalEnd,
    }));

export const violationTooltipComponent = ({ violations, icon, status, violationType }) => {
  const tooltipContent = () => (
    <div>
      {violations?.map((t, index) => (
        <List
          // eslint-disable-next-line react/no-array-index-key
          key={`${t}+ ${index}`}
          style={{
            marginBottom: index === violations.length - 1 ? '0' : '12px',
          }}
        >
          <ListItem sx={{ padding: '6px', fontSize: '14px' }}>
            <ListItemIcon sx={{ minWidth: '16px' }}>
              <FiberManualRecordIcon
                sx={{ color: COLORS.blue[700], width: '8px', height: '8px' }}
              />
            </ListItemIcon>
            {t}
          </ListItem>
        </List>
      ))}
    </div>
  );
  const tooltipColor = () => {
    if (!status) {
      return '#ffffff';
    }
    if (
      [
        visitStatusesMap.cannotBeAllocated,
        visitStatusesMap.scheduled,
        visitStatusesMap.enRoute,
        visitStatusesMap.inProgress,
      ].includes(status) &&
      violationType === 'soft'
    ) {
      return COLORS.yellow[800];
    }
    if (
      [
        visitStatusesMap.cannotBeAllocated,
        visitStatusesMap.scheduled,
        visitStatusesMap.enRoute,
        visitStatusesMap.inProgress,
      ].includes(status) &&
      violationType === 'hard'
    ) {
      return COLORS.red[700];
    }
    return '#ffffff';
  };
  return (
    <Box sx={{ display: 'flex', zIndex: 10 }}>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              ...teamsTooltipStyles,
            },
          },
        }}
        title={tooltipContent(violations)}
        placement="right"
      >
        <Box sx={{ display: 'flex' }}>
          <img style={{ paddingRight: '4px' }} src={icon} alt="" />
          <Typography sx={{ color: tooltipColor() }}>{violations?.length}</Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};
export const mergeArraysWithActivity = (array, activity) => {
  if (!Array.isArray(array)) {
    return [];
  }
  return array.map(record => ({ ...record, activity }));
};

export const getStatusClassName = (className, event) => {
  const savedStatus = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.oldStatus));
  const calculateStatus = () => {
    if (event.isShadowVisit && event.id === savedStatus?.uid) {
      return savedStatus?.values?.shadowStatus || event?.shadowStatus;
    }
    if (!event.status && event.id === savedStatus?.uid) {
      return savedStatus?.values?.status || event?.status;
    }
    return event.isShadowVisit ? event.shadowStatus : event.status;
  };
  const status = calculateStatus();
  if (status === 'Scheduled') {
    return className.concat(` event-scheduled visit${event?.eventId} `);
  }
  if (status === 'EnRoute') {
    return className.concat(` event-en-route visit${event?.eventId} `);
  }
  if (status === 'OnSite') {
    return className.concat(` event-on-site visit${event?.eventId} `);
  }
  if (status === 'InProgress') {
    return className.concat(` event-in-progress visit${event?.eventId} `);
  }
  if (status === 'Completed') {
    return className.concat(` event-completed visit${event?.eventId}`);
  }
  if (status === 'Verified') {
    return className.concat(` event-verified visit${event?.eventId} `);
  }
  if (status === 'Canceled') {
    return className.concat(` event-canceled visit${event?.eventId}`);
  }
  return className;
};
export const getTerritoryAndTeamCounts = data => {
  const territories = data?.filter(item => item?.teams);
  const teams = data?.filter(team => team?.territory);
  const territoryCount = territories?.length;
  const teamCount = teams?.length;

  return { territoryCount, teamCount };
};
export const combineTeamsAndTerritory = locations =>
  locations?.reduce((acc, location) => {
    acc?.push(location);
    if (location?.teams) {
      acc?.push(...location.teams);
    }
    return acc;
  }, []);

export const checkIsDayDisabled = (day, disabledDays) => {
  if (day === 'Sunday' && !disabledDays.sundaySwitchedOn) return true;
  if (day === 'Saturday' && !disabledDays.saturdaySwitchedOn) return true;
  return false;
};
// shared/utils/processCareProgramChanges.js

export const processCareProgramChanges = (event, skillsData, filtersData, index) => {
  const changes = JSON.parse(event?.changes);
  const collectionsArray = changes
    ?.filter(change => change.type === 'collection')
    ?.map(change => change.changes)
    ?.flat();
  const ArrPrimitive = [];
  const ArrCollection = [];
  const filterObjectTypeArray = collectionsArray?.filter(change => change.type === 'object');
  const changesArray = filterObjectTypeArray?.map(change => change.changes);

  const days = changesArray
    ?.flat()
    ?.filter(t => t.propertyName === 'days')
    ?.map(change => change.changes)
    ?.flat();

  const visitTypes =
    changesArray
      ?.flat()
      ?.filter(t => t.type === 'object')
      ?.map(change => change.changes)
      ?.flat()
      ?.filter(property => property.propertyName === 'description') || [];
  ArrPrimitive.push(...visitTypes);
  changesArray?.forEach(change =>
    // eslint-disable-next-line array-callback-return
    change.map(arr => {
      if (arr.type === 'primitive') {
        ArrPrimitive.push(arr);
      } else {
        ArrCollection.push(arr);
      }
    }),
  );
  const ArrPrimitiveFromObject = [];
  const ArrCollectionFromObject = [];
  const ArrayOfCollectionOnly = ArrCollection?.filter(change => change.type === 'collection')
    ?.map(change => change.changes)
    ?.flat();
  ArrayOfCollectionOnly?.forEach(change => {
    if (change.type === 'primitive') {
      ArrPrimitiveFromObject?.push(change);
    } else {
      ArrCollectionFromObject?.push(change);
    }
  });
  const addPropertyNameToArray = ArrPrimitiveFromObject?.map(change => ({
    ...change,
    propertyName:
      change?.operation === 'insert'
        ? addPropertyName({ type: change?.to, skillsData, filtersData })
        : addPropertyName({ type: change?.from, skillsData, filtersData }),
  }));

  const primitiveFromObject = ArrCollectionFromObject?.map(change => change.changes)
    ?.map(k => k)
    ?.flat();
  const newArray = primitiveFromObject?.filter(
    element => element.propertyName !== 'carePlanTaskId',
  );
  const concatPrimitives = ArrPrimitive?.concat(newArray);
  const children = concatPrimitives?.concat(addPropertyNameToArray);
  const addId = children.map((change, inde) => ({
    ...change,
    id: inde + 1,
  }));
  const mergedArray = addId.concat(days);
  const hasNonNoChange = mergedArray.some(change => change?.operation !== 'noChange');
  if (!hasNonNoChange) {
    return null;
  }
  if (mergedArray.length === 0) {
    return null;
  }
  return {
    ...event,
    changes: mergedArray,
    dateCreated: moment(event.dateCreated).format(HISTORY_UPDATE_FORMAT),
    id: index + 1,
  };
};

export const processCarePlanChangesChanges = (event, skillsData, filtersData, index) => {
  try {
    JSON.parse(event?.changes);
  } catch (e) {
    return [];
  }
  const changes = JSON.parse(event?.changes);
  const collectionsArray = changes
    ?.filter(change => change.type === 'collection')
    ?.map(change => change.changes)
    ?.flat();
  const ArrPrimitive = [];
  const ArrCollection = [];
  const filterObjectTypeArray = collectionsArray?.filter(change => change?.type === 'object');
  const changesArray = filterObjectTypeArray?.map(change => change.changes);
  changesArray?.forEach(change =>
    // eslint-disable-next-line array-callback-return
    change?.map(arr => {
      if (arr.type === 'primitive') {
        ArrPrimitive.push(arr);
      } else {
        ArrCollection.push(arr);
      }
    }),
  );
  const ArrayOfCollectionOnly = ArrCollection?.filter(change => change.type === 'collection')
    ?.map(change => change.changes)
    ?.flat();

  const addPropertyNameToArray = ArrayOfCollectionOnly?.map(change => ({
    ...change,
    propertyName:
      change?.operation === 'insert'
        ? addPropertyName({ type: change?.to, skillsData, filtersData })
        : addPropertyName({ type: change?.from, skillsData, filtersData }),
  }));

  const ArrayOfCollections = [];

  const days = addPropertyNameToArray
    ?.filter(t => t.propertyName === 'days')
    ?.sort(sortWeekdaysFirst);
  if (days) {
    ArrayOfCollections.push(...days);
  }
  const carePlanTimesOfDay = addPropertyNameToArray
    ?.filter(t => t.propertyName === 'timeOfDays')
    ?.sort(sortTypeOfDaysFirst);
  if (timeOfDays) {
    ArrayOfCollections.push(...carePlanTimesOfDay);
  }

  const visitTypes = addPropertyNameToArray
    ?.filter(t => t.propertyName === 'visitTypes')
    ?.sort(sortTypeOfDaysFirst);
  if (visitTypes) {
    ArrayOfCollections.push(...visitTypes);
  }

  const children = ArrPrimitive?.concat(ArrayOfCollections);
  const addId = children?.map((change, inde) => ({
    ...change,
    id: inde + 1,
  }));
  return {
    ...event,
    changes: addId,
    dateCreated: moment(event.dateCreated).format(HISTORY_UPDATE_FORMAT),
    id: index + 1,
  };
};
