import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment/moment';
import { instanceOf, number } from 'prop-types';
import React from 'react';
import { arrivalWindowLabelStyles, chipArrivalStartStyle, chipArrivalWindowStyle } from '../styles';

export default function WithoutArrivalTime({
  arrivalStart = {},
  arrivalWindowStartPosition = 0,
  event = {},
}) {
  return (
    <Box>
      <Chip
        label={moment(arrivalStart).format('h:mm A')}
        sx={{
          ...chipArrivalStartStyle,
          top: moment(event.start).isAfter(arrivalStart)
            ? `-${arrivalWindowStartPosition + 10}px`
            : `${-arrivalWindowStartPosition - 10}px`,
        }}
      />
      <Box
        sx={{
          ...chipArrivalWindowStyle,
          top: `${-arrivalWindowStartPosition + 15}px`,
          width: '75px',
          right: '-80px',
        }}
      >
        <Typography sx={arrivalWindowLabelStyles}>Exact Time</Typography>
      </Box>
    </Box>
  );
}

WithoutArrivalTime.propTypes = {
  arrivalStart: instanceOf(Object),
  arrivalWindowStartPosition: number,
  event: instanceOf(Object),
};
