import { Dialog } from '@mui/material';
import {
  useGetCareProgramTableQuery,
  useUpdateCareProgramMutation,
  useAddCareProgramMutation,
} from '../../../../../../api/CareProgram/api';
import moment from 'moment';
import { EditCareProgramContext } from '../../../../../../pages/ClientDetails/context';
import { instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { TransitionGrow } from '../../../../../../shared/constants';
import { filterVisitsByEffectiveDate } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import Loader from '../../../../../../components/Loader';
import { UPDATE_PROGRAM_POPUPS } from '../../constants';
import CreateCareProgramVisitDialog from './components/CreateCareProgramVisit';
import SmallDialogs from './components/SmallDialogs';
import UpdateCareProgramDialog from './components/UpdateCareProgram';
import { getTabsData } from './components/CreateCareProgramVisit/CreateVisitMultistep/CareProgramSummary/utils';
import OptimizerCheck from '../../../../../../components/Optimizer/OptimizerCheck';
import { v4 as uuidv4 } from 'uuid';

const INITIAL_INNER_MODAL = UPDATE_PROGRAM_POPUPS.effectiveDate;
export const ERROR_INITIAL_STATE = {};

export default function CareProgramManagement({ children = {} }) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);

  const [
    updateCareProgram,
    { isLoading: isUpdating, isSuccess, status, error: updateCareProgramError },
  ] = useUpdateCareProgramMutation();

  const [
    addCareProgram,
    { isLoading: isAdding, isSuccess: isAddSuccess, status: addStatus, error: addCareProgramError },
  ] = useAddCareProgramMutation();

  const {
    data: careProgramData,
    isLoading: isCareProgramLoading,
    isFetching,
    error: getCareProgramTableError,
    refetch: refetchCareProgram,
  } = useGetCareProgramTableQuery(id);

  const initialNextDayDate = moment()
    .add(1, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const [error, setError] = useState(ERROR_INITIAL_STATE);
  const [innerDialogType, setInnerDialogType] = useState(null);
  const [updatedCareProgram, setUpdatedCareProgram] = useState([]);
  const [initialCareProgramData, setInitialCareProgramData] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(initialNextDayDate);
  const [removedVisit, setRemovedVisit] = useState(null);
  const [editedVisit, setEditedVisit] = useState(null);
  const [editedCarePrograms, setEditedCarePrograms] = useState([]);
  const [addedCarePrograms, setAddedCarePrograms] = useState([]);
  const [deletedCarePrograms, setDeletedCarePrograms] = useState([]);
  const [checkData, setCheckData] = useState(null);
  const [openCheckOptomizer, setOpenCheckOptomizer] = useState(false);

  useEffect(() => {
    if (updateCareProgramError || getCareProgramTableError) {
      const errorData = updateCareProgramError || getCareProgramTableError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [updateCareProgramError, getCareProgramTableError, setAlert, addCareProgramError]);

  useEffect(() => {
    if (status === 'fulfilled' && isSuccess) {
      setAlert({
        errorData: {
          message: 'Care program updates have been saved successfully.',
        },
        type: 'success',
      });
    }
  }, [isSuccess, setAlert, status]);

  useEffect(() => {
    if (status === 'fulfilled' && isAddSuccess) {
      setAlert({
        errorData: {
          message: 'Care program additions have been saved successfully.',
        },
        type: 'success',
      });
    }
    // eslint-disable-next-line
  }, [isAddSuccess, setAlert, addStatus]);

  useEffect(() => {
    if (careProgramData) {
      const filteredCareProgramVisits = filterVisitsByEffectiveDate(careProgramData);
      const parsedCareProgram = [...filteredCareProgramVisits];
      setUpdatedCareProgram(filteredCareProgramVisits);
      setInitialCareProgramData(parsedCareProgram);
    }
  }, [careProgramData, setInitialCareProgramData, setUpdatedCareProgram]);

  const closeDialog = useCallback(() => {
    if (error) {
      setError(ERROR_INITIAL_STATE);
    }
    if (removedVisit) {
      setRemovedVisit(null);
    }
    if (
      [
        UPDATE_PROGRAM_POPUPS.effectiveDate,
        UPDATE_PROGRAM_POPUPS.programView,
        UPDATE_PROGRAM_POPUPS.careProgramSummary,
      ].includes(innerDialogType) ||
      !updatedCareProgram.length
    ) {
      setOpenDialog(false);
    } else {
      setInnerDialogType(UPDATE_PROGRAM_POPUPS.programView);
    }
  }, [error, innerDialogType, removedVisit, updatedCareProgram?.length]);

  const sendToDb = async () => {
    const postVisits = addedCarePrograms;
    const patchVisits = [...deletedCarePrograms, ...editedCarePrograms];
    for (let visit in patchVisits) {
      if (patchVisits[visit]?.oneTimeChange) {
        delete patchVisits[visit].oneTimeChange;
      }
    }
    if (patchVisits.length) {
      await updateCareProgram(patchVisits);
    }
    if (postVisits.length) {
      await addCareProgram(postVisits);
    }
    refetchCareProgram();
  };

  const saveCareProgramData = useCallback(() => {
    const tabsData = getTabsData(updatedCareProgram);
    for (let visitType in tabsData.summaryData) {
      for (let visit in tabsData.summaryData[visitType]) {
        delete tabsData.summaryData[visitType][visit].arrivalWindowDuration;
        delete tabsData.summaryData[visitType][visit].changesType;
        delete tabsData.summaryData[visitType][visit].updateId;
        if (tabsData.summaryData[visitType][visit].effectiveDateStart) {
          if (moment.isMoment(tabsData.summaryData[visitType][visit].effectiveDateStart)) {
            tabsData.summaryData[visitType][visit].effectiveDateStart =
              tabsData.summaryData[visitType][visit].effectiveDateStart.format(
                'YYYY-MM-DDTh:mm:ss',
              );
            tabsData.summaryData[visitType][visit].effectiveDateStart =
              tabsData.summaryData[visitType][visit].effectiveDateStart.slice(0, 10) + 'T00:00:00';
          }
          if (moment.isMoment(tabsData.summaryData[visitType][visit].effectiveDateEnd)) {
            tabsData.summaryData[visitType][visit].effectiveDateEnd =
              tabsData.summaryData[visitType][visit].effectiveDateEnd.format('YYYY-MM-DDTh:mm:ss');
            tabsData.summaryData[visitType][visit].effectiveDateEnd =
              tabsData.summaryData[visitType][visit].effectiveDateEnd.slice(0, 10) + 'T00:00:00';
          }
          if (visitType === 'addedVisits') {
            tabsData.summaryData[visitType][visit].id = uuidv4();
            tabsData.summaryData[visitType][visit].visitId = uuidv4();
          }
        }
      }
    }

    setEditedCarePrograms(tabsData.summaryData.editedVisits);
    setAddedCarePrograms(tabsData.summaryData.addedVisits);
    setDeletedCarePrograms(tabsData.summaryData.deletedVisits);
    const allVisits = {
      edited: tabsData.summaryData.editedVisits,
      added: tabsData.summaryData.addedVisits,
      deleted: tabsData.summaryData.deletedVisits,
    };
    setCheckData(allVisits);
    setOpenCheckOptomizer(true);
    closeDialog();
  }, [closeDialog, updatedCareProgram, updateCareProgram, addCareProgram]);

  const openUpdateProgram = useCallback(() => {
    setUpdatedCareProgram(initialCareProgramData);
    setEffectiveDate(initialNextDayDate);
    setInnerDialogType(INITIAL_INNER_MODAL);
    setOpenDialog(true);
  }, [initialCareProgramData, initialNextDayDate]);

  const showProgramVisitsView = () => {
    setInnerDialogType(UPDATE_PROGRAM_POPUPS.programView);
  };

  const showProgramVisitsSummary = useCallback(() => {
    setInnerDialogType(UPDATE_PROGRAM_POPUPS.careProgramSummary);
  }, []);

  const showAddNewProgramVisit = () => {
    setInnerDialogType(UPDATE_PROGRAM_POPUPS.addNewVisit);
  };

  const showRemoveProgramVisit = visit => {
    setRemovedVisit(visit);
    setInnerDialogType(UPDATE_PROGRAM_POPUPS.removeProgram);
  };

  const setEditedVisitData = visit => {
    setEditedVisit(visit ? visit : null);
    setInnerDialogType(UPDATE_PROGRAM_POPUPS.addNewVisit);
  };

  const memoizedProviderValue = useMemo(
    () => ({
      closeDialog,
      editedVisit,
      effectiveDate,
      error,
      initialCareProgramData,
      innerDialogType,
      openUpdateProgram,
      plan: initialCareProgramData?.plan,
      removedVisit,
      saveCareProgramData,
      setEditedVisitData,
      setEffectiveDate,
      setError,
      setInitialCareProgramData,
      setRemovedVisit,
      setUpdatedCareProgram,
      showAddNewProgramVisit,
      showProgramVisitsSummary,
      showProgramVisitsView,
      showRemoveProgramVisit,
      updatedCareProgram,
      clientId: id,
    }),
    [
      closeDialog,
      editedVisit,
      effectiveDate,
      error,
      initialCareProgramData,
      innerDialogType,
      openUpdateProgram,
      removedVisit,
      saveCareProgramData,
      showProgramVisitsSummary,
      updatedCareProgram,
      id,
    ],
  );

  return (
    <EditCareProgramContext.Provider value={memoizedProviderValue}>
      {(isCareProgramLoading || isFetching || isUpdating || isAdding) && <Loader />}
      <Dialog open={openDialog} fullScreen TransitionComponent={TransitionGrow}>
        <SmallDialogs />
        <CreateCareProgramVisitDialog />
        <UpdateCareProgramDialog />
      </Dialog>
      {children}
      <OptimizerCheck
        data={checkData}
        dataObjName="careProgramObj"
        submitFunction={sendToDb}
        open={openCheckOptomizer}
        openToggle={setOpenCheckOptomizer}
      />
    </EditCareProgramContext.Provider>
  );
}

CareProgramManagement.propTypes = {
  children: instanceOf(Object),
};
