import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { IconButton } from '@mui/material';
import { func, string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../shared/styles/Theme';
import CustomTooltip from '../../../components/CustomTooltip';

export default function EditIconButton({ onEdit = () => {}, tooltip = '' }) {
  return (
    <CustomTooltip title={tooltip}>
      <IconButton disableRipple onClick={onEdit} sx={{ color: COLORS.blue[300], p: 0 }}>
        <CreateOutlinedIcon sx={{ fontSize: 20 }} />
      </IconButton>
    </CustomTooltip>
  );
}

EditIconButton.propTypes = {
  onEdit: func,
  tooltip: string,
};
