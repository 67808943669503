import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { bool, func, number, string } from 'prop-types';
import React, { useCallback } from 'react';
import { CONVERT_TO_UTC_FORMAT, H12_TIME_FORMAT } from '../../../../shared/constants';
import CustomTimePicker from '../../../../components/CustomDateAndTimePickers/CustomTimePicker';
import NumericInput from '../../../../components/NumericInput';
import ScheduledTimeFields from './ScheduledTimeFields';
import { columnTitle, customInputsStyles, visitInfoManagementColumnStyles } from './styles';

function VisitTimeManagement({
  actualDuration = undefined,
  actualTimeEnd = undefined,
  actualTimeStart = undefined,
  arrivalTime = '',
  arrivalTimeEnd = '',
  arrivalTimeStart = '',
  duration = 0,
  isExactTime = false,
  updateTemporaryVisitData = () => {},
  date = '',
}) {
  const changeActualDuration = useCallback(
    value =>
      updateTemporaryVisitData({
        actualEnd: moment(actualTimeStart).add(+value, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
        actualDuration: +value,
      }),
    [actualTimeStart, updateTemporaryVisitData],
  );

  const changeActualStartTime = useCallback(
    timeValue => {
      if (timeValue?.isValid()) {
        const newTimeValue = moment(
          `${date?.substring(0, 10)} ${timeValue.format(H12_TIME_FORMAT)}`,
          CONVERT_TO_UTC_FORMAT,
        );
        updateTemporaryVisitData({
          actualStart: newTimeValue.format('YYYY-MM-DDTHH:mm:ss'),
          actualEnd:
            actualDuration && actualDuration >= 0
              ? newTimeValue.add(actualDuration, 'minutes').format('YYYY-MM-DDTHH:mm:ss')
              : undefined,
        });
      }
    },
    [actualDuration, date, updateTemporaryVisitData],
  );

  const changeActualEndTime = useCallback(
    timeValue => {
      if (timeValue?.isValid()) {
        const newTimeValue = moment(
          `${date?.substring(0, 10)} ${timeValue.format(H12_TIME_FORMAT)}`,
          CONVERT_TO_UTC_FORMAT,
        );
        const start = moment(actualTimeStart);
        const durationDiff = newTimeValue.diff(start, 'minutes');
        if (durationDiff > 0) {
          updateTemporaryVisitData({
            actualEnd: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
            duration: durationDiff,
          });
        }
      }
    },
    [actualTimeStart, date, updateTemporaryVisitData],
  );

  return (
    <Box sx={{ display: 'flex', gap: '24px' }}>
      <ScheduledTimeFields
        arrivalTime={arrivalTime}
        arrivalTimeEnd={arrivalTimeEnd}
        arrivalTimeStart={arrivalTimeStart}
        disableAllFields
        duration={duration}
        exactTimeVisitEnd={moment(arrivalTime)
          .add(duration, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss')}
        exactTimeVisitStart={arrivalTimeStart}
        isExactTime={isExactTime}
      />
      <Box sx={visitInfoManagementColumnStyles}>
        <Typography sx={columnTitle}>Actual Time</Typography>
        <CustomTimePicker
          customStyles={[customInputsStyles]}
          disabled
          label="Start Time"
          onChangeValue={changeActualStartTime}
          value={actualTimeStart ? moment(actualTimeStart) : actualTimeStart}
          views={['hours', 'minutes']}
          withIcon
          displayDefaultPlaceholder={false}
        />
        <CustomTimePicker
          customStyles={[customInputsStyles]}
          disabled
          label="End Time"
          isPMPlaceholder
          onChangeValue={changeActualEndTime}
          value={actualTimeEnd ? moment(actualTimeEnd) : actualTimeEnd}
          views={['hours', 'minutes']}
          withIcon
          displayDefaultPlaceholder={false}
        />
        <NumericInput
          additionalStyles={customInputsStyles}
          changeValue={changeActualDuration}
          disabled
          label="Visit Duration, min"
          step={5}
          value={actualDuration && actualDuration >= 0 ? actualDuration : ''}
        />
      </Box>
    </Box>
  );
}

VisitTimeManagement.propTypes = {
  actualDuration: number,
  actualTimeEnd: string,
  actualTimeStart: string,
  arrivalTime: string,
  arrivalTimeEnd: string,
  arrivalTimeStart: string,
  duration: number,
  isExactTime: bool,
  updateTemporaryVisitData: func,
  date: string,
};

export default React.memo(VisitTimeManagement);
