import { COLORS } from '../../shared/styles/Theme';

export const visitEventInfoWrapper = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
};

export const arrivalWindowWrapper = {
  height: '100%',
  fontSize: '13px',
  fontWeight: 400,
  border: `2px dashed ${COLORS.blue[700]}`,
  background: 'linear-gradient(0deg, rgba(43, 119, 255, 0.2), rgba(43, 119, 255, 0.2));',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const getArrivalWindowAreaStyles = (arrivalWindowHeight, arrivalWindowStartPosition) => ({
  height: `${arrivalWindowHeight}px`,
  border: `2px dashed ${COLORS.blue[700]}`,
  borderBottom: 'none',
  position: 'absolute',
  top: `${-arrivalWindowStartPosition}px`,
  width: '100%',
  zIndex: 1,
});

export const arrivalWindowLabelStyles = {
  color: COLORS.black[700],
  fontSize: '12px',
  lineHeight: '16px',
};

export const durationLabelStyles = {
  color: COLORS.black[700],
  fontSize: '12px',
  lineHeight: '16px',
};

export const lunchBrakeWrapper = {
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '100%',
  padding: '20px',
  cursor: 'default',
};

export const lunchBrakeTitleStyles = {
  ml: '10px',
  fontSize: '15px',
  fontWeight: 400,
  color: COLORS.blue[900],
};

export const travelTimeStyles = ({ travelTime, travelTimeLessThanMinimal }) => ({
  position: 'absolute',
  background: 'repeating-linear-gradient(135deg, #EFEBE9, #EFEBE9 6px, #fff 5px, #fff 12px)',
  padding: '6px 10px',
  height: travelTimeLessThanMinimal ? '28px' : `${travelTime * 2}px`,
  display: 'flex',
  fontSize: '13px',
  color: COLORS.blue[900],
  fontWeight: 400,
  top: travelTimeLessThanMinimal ? '-28px' : `-${travelTime * 2}px`,
  width: '100%',
});

export const chipStartStyle = {
  position: 'absolute',
  top: '-7px',
  left: '-61px',
  zIndex: 1000,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.green[500],
  color: '#fff',
  fontSize: '11px',
};
export const chipEndStyle = {
  position: 'absolute',
  top: '95%',
  left: '-61px',
  zIndex: 1000,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.green[500],
  color: '#fff',
  fontSize: '11px',
};
export const durationStyle = {
  position: 'absolute',
  top: '30%',
  left: '-68px',
  width: '62px',
  zIndex: 1000,
  padding: '4px 6px',
  borderRadius: '4px',
  background: '#fff',
  boxShadow: '0px 0px 12px 0px #2C2D2E29',
};
export const chipArrivalStartStyle = {
  position: 'absolute',
  top: '-7px',
  right: '-61px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.brown[600],
  color: '#fff',
  fontSize: '11px',
};
export const chipArrivalTimeEndStyle = {
  position: 'absolute',
  right: '-61px',
  zIndex: 1003,
  padding: ' 3px 6px 2px 6px',
  borderRadius: '30px',
  background: COLORS.brown[600],
  color: '#fff',
  fontSize: '11px',
};
export const chipArrivalWindowStyle = {
  position: 'absolute',
  right: '-98px',
  width: '92px',
  zIndex: 1003,
  padding: '4px 6px',
  borderRadius: '4px',
  background: '#fff',
  boxShadow: '0px 0px 12px 0px #2C2D2E29',
};

const customSelectStyles = {
  minWidth: '100px',
  flex: '1 0 0',
  fontSize: '15px',
  height: '42px',
  color: `${COLORS.blue[500]}`,
  borderRadius: '8px',
  '&.MuiSvgIcon-root': {
    fill: `${COLORS.blue[300]}`,
    width: '20px',
    height: '20px',
    top: 11,
  },
  '&.MuiOutlinedInput-root': {
    border: 'none',
    backgroundColor: COLORS.blue[50],
    '.MuiOutlinedInput-input': {
      padding: '10px 12px',
      paddingRight: '28px',
    },
    fieldset: {
      border: 'none',
    },
  },
  '&.MuiOutlinedInput-root.has-items': {
    backgroundColor: COLORS.yellow[200],
    color: COLORS.blue[900],
  },
};

const customSelectItem = {
  display: 'flex',
  fontSize: '15px',
  color: `${COLORS.blue[900]}`,
  backgroundColor: '#ffffff',
  '&.select-all-item': {
    borderBottom: `1px solid ${COLORS.blue[200]}`,
    paddingBottom: '8px',
  },
  '&:hover, &:active, &:focus, ': {
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: `${COLORS.blue[50]}`,
  },
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active,  &.Mui-selected:focus': {
    backgroundColor: '#ffffff',
  },
};

const violatedSoftConstraintsStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  pr: '2px',
  mb: '7px',
};
const showTeamStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '600',
  overflow: 'hidden',
  transition: 'height 0.3s ease',
  position: 'absolute',
  zIndex: 1,
};

export { customSelectItem, customSelectStyles, showTeamStyles, violatedSoftConstraintsStyles };
