import { COLORS } from '../../../../shared/styles/Theme';

export const customTableStyles = [
  {
    '& .MuiDataGrid-virtualScroller': {
      padding: '1px',
      overflowX: 'auto',
    },
    '& .MuiDataGrid-row': {
      padding: '36px 0',
    },
    '.MuiDataGrid-row:hover': {
      backgroundColor: 'transparent',
      cursor: 'initial',
    },
  },
];

export const lastUpdateCellWrapper = {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'start',
  gap: '40px',
  paddingRight: '10px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  '& .MuiChip-root': {
    maxHeight: '24px',
  },
};

export const lastUpdateActionsWrapper = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  flexWrap: 'nowrap',
};

export const continueButtonStyles = {
  color: COLORS.blue[500],
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: 'transparent',
    color: COLORS.blue[300],
    fontSize: '13px',
  },
};

export const rejectButtonStyles = { color: COLORS.red[500], fontSize: '13px' };
