import { Box, Typography } from '@mui/material';
import { bool, number, string } from 'prop-types';
import React from 'react';
import CustomTimeSelect from '../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import NumericInput from '../../../../components/NumericInput';
import {
  columnTitle,
  customInputsStyles,
  visitDetailsCustomSelectStyles,
  visitInfoManagementColumnStyles,
} from './styles';
import moment from 'moment';
import { H12_TIME_FORMAT } from '../../../../shared/constants';

export default function ScheduledTimeFields({
  arrivalTime = '',
  arrivalTimeEnd = '',
  arrivalTimeStart = '',
  disableAllFields = false,
  duration = 0,
  exactTimeVisitEnd = '',
  exactTimeVisitStart = '',
  isExactTime = false,
}) {
  return (
    <Box sx={visitInfoManagementColumnStyles}>
      <Typography sx={columnTitle}>Scheduled Time</Typography>
      {isExactTime && (
        <CustomTimeSelect
          customStyles={visitDetailsCustomSelectStyles}
          disabled={disableAllFields}
          isRequired
          placeHolder="Visit Start Time"
          selectedTime={moment(exactTimeVisitStart).format(H12_TIME_FORMAT)}
        />
      )}
      {isExactTime && (
        <CustomTimeSelect
          customStyles={visitDetailsCustomSelectStyles}
          disabled={disableAllFields}
          isRequired
          placeHolder="Visit End Time"
          selectedTime={moment(exactTimeVisitEnd).format(H12_TIME_FORMAT)}
        />
      )}
      {!isExactTime && (
        <CustomTimeSelect
          customStyles={visitDetailsCustomSelectStyles}
          disabled={disableAllFields}
          isRequired
          placeHolder="Arrival Window Start"
          selectedTime={moment(arrivalTimeStart).format(H12_TIME_FORMAT)}
        />
      )}
      {!isExactTime && (
        <CustomTimeSelect
          customStyles={visitDetailsCustomSelectStyles}
          disabled={disableAllFields}
          isRequired
          placeHolder="Arrival Window End"
          selectedTime={moment(arrivalTimeEnd).format(H12_TIME_FORMAT)}
        />
      )}
      {!isExactTime && (
        <CustomTimeSelect
          customStyles={visitDetailsCustomSelectStyles}
          disabled={disableAllFields}
          isRequired
          placeHolder="Visit Start Time"
          selectedTime={moment(arrivalTime).format(H12_TIME_FORMAT)}
        />
      )}
      <NumericInput
        additionalStyles={customInputsStyles}
        disabled={disableAllFields}
        label="Visit Duration, min"
        step={5}
        value={duration || 0}
      />
    </Box>
  );
}

ScheduledTimeFields.propTypes = {
  arrivalTime: string,
  arrivalTimeEnd: string,
  arrivalTimeStart: string,
  disableAllFields: bool,
  duration: number,
  exactTimeVisitEnd: string,
  exactTimeVisitStart: string,
  isExactTime: bool,
};
