import { Box, Typography } from '@mui/material';
import moment from 'moment/moment';
import { transformAvailabilityRows } from '../../../../../pages/CaregiverDetails/view/Availability/utils';
import CustomCheckbox from '../../../../../pages/ClientDetails/components/CreateTask/components/Checkbox/Checkbox';
import { func, instanceOf, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { H12_TIME_FORMAT } from '../../../../../shared/constants';
import { COLORS } from '../../../../../shared/styles/Theme';
import { checkIsDayDisabled } from '../../../../../shared/utils/common';
import CustomTimeSelect from '../../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import ErrorAlert from '../../../../../components/ErrorAlert';
import { LOCAL_STORAGE_KEYS } from '../../../../Scheduling/constants';

export default function MultiplyModeContent({
  disabledDays = {},
  error = '',
  selectedDays = [],
  setError = () => {},
  setSelectedDays = () => {},
  setStartTimeFirst = () => {},
  setStartTimeSecond = () => {},
  setEndTimeFirst = () => {},
  setEndTimeSecond = () => {},
  startTimeFirst = '',
  startTimeSecond = '',
  endTimeFirst = '',
  endTimeSecond = '',
}) {
  const handleCheckboxChange = id => {
    if (selectedDays.includes(id)) {
      setSelectedDays(prevValue => prevValue.filter(value => value !== id));
    } else setSelectedDays(prevValue => [...prevValue, id]);
  };

  const handleStartTimeChangeFirst = useCallback(
    newValue => {
      const selectedTime = moment(newValue, H12_TIME_FORMAT);
      const end = moment(endTimeFirst, H12_TIME_FORMAT);
      setStartTimeFirst(newValue);
      if (!!endTimeFirst.length && selectedTime.isSameOrAfter(end)) {
        setError('You have been inputted wrong start time');
        return false;
      }
      if (error) {
        setError('');
      }
      return false;
    },
    [error, endTimeFirst, setError, setStartTimeFirst],
  );

  const handleStartTimeChangeSecond = useCallback(
    newValue => {
      const selectedTime = moment(newValue, H12_TIME_FORMAT);
      const end = moment(endTimeSecond, H12_TIME_FORMAT);
      setStartTimeSecond(newValue);
      if (!!endTimeSecond.length && selectedTime.isSameOrAfter(end)) {
        setError('You have been inputted wrong start time');
        return false;
      }
      if (error) {
        setError('');
      }
      return false;
    },
    [error, endTimeSecond, setError, setStartTimeSecond],
  );

  const handleEndTimeChangeFirst = useCallback(
    newValue => {
      const selectedTime = moment(newValue, H12_TIME_FORMAT);
      const start = moment(startTimeFirst, H12_TIME_FORMAT);
      setEndTimeFirst(newValue);
      if (!!startTimeFirst.length && selectedTime.isSameOrBefore(start)) {
        setError('You have been inputted wrong end time');
        return false;
      }
      if (error) {
        setError('');
      }
      return false;
    },
    [error, setEndTimeFirst, setError, startTimeFirst],
  );

  const handleEndTimeChangeSecond = useCallback(
    newValue => {
      const selectedTime = moment(newValue, H12_TIME_FORMAT);
      const start = moment(startTimeSecond, H12_TIME_FORMAT);
      setEndTimeSecond(newValue);
      if (!!startTimeSecond.length && selectedTime.isSameOrBefore(start)) {
        setError('You have been inputted wrong end time');
        return false;
      }
      if (error) {
        setError('');
      }
      return false;
    },
    [error, setEndTimeSecond, setError, startTimeSecond],
  );

  const weekdays = useMemo(() => transformAvailabilityRows(), []);
  const timeValuesList = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.timeValuesList));
  const firstShiftTimeValues = timeValuesList?.filter(time => moment(time, H12_TIME_FORMAT) <= moment("2:00 PM", H12_TIME_FORMAT));
  const secondShiftTimeValues = timeValuesList?.filter(time => moment(time, H12_TIME_FORMAT) >= moment("4:00 PM", H12_TIME_FORMAT) && moment(time, H12_TIME_FORMAT) <= moment("9:00 PM", H12_TIME_FORMAT));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0 28px 28px 28px',
      }}
    >
      <Typography sx={{ color: COLORS.blue[900] }}>First Shift 7 AM - 2 PM</Typography>
      <Box sx={{ display: 'flex', gap: '20px', mb: '20px' }}>
        <CustomTimeSelect
          isRequired
          onSelectTimeValue={handleStartTimeChangeFirst}
          placeHolder="Start Time"
          selectedTime={startTimeFirst}
          timeValues={firstShiftTimeValues}
        />
        <CustomTimeSelect
          isRequired
          onSelectTimeValue={handleEndTimeChangeFirst}
          placeHolder="End time"
          selectedTime={endTimeFirst}
          timeValues={firstShiftTimeValues}
        />
      </Box>
      <Typography sx={{ color: COLORS.blue[900] }}>Second Shift 4 PM - 9 PM</Typography>
      <Box sx={{ display: 'flex', gap: '20px', mb: '20px' }}>
        <CustomTimeSelect
          isRequired
          onSelectTimeValue={handleStartTimeChangeSecond}
          placeHolder="Start Time"
          selectedTime={startTimeSecond}
          timeValues={secondShiftTimeValues}
        />
        <CustomTimeSelect
          isRequired
          onSelectTimeValue={handleEndTimeChangeSecond}
          placeHolder="End time"
          selectedTime={endTimeSecond}
          timeValues={secondShiftTimeValues}
        />
      </Box>
      {error && <ErrorAlert error={error} />}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {weekdays.map(({ id, workingDays }) => {
          const isDayDisabledSuAndSa = checkIsDayDisabled(workingDays, disabledDays);

          return (
            <CustomCheckbox
              key={workingDays}
              checked={selectedDays?.includes(id)}
              isDisabled={isDayDisabledSuAndSa}
              label={
                <Box>
                  <Typography sx={{ color: COLORS.blue[900] }}>{workingDays}</Typography>
                </Box>
              }
              onValueChange={() => handleCheckboxChange(id)}
            />
          );
        })}
      </Box>
    </Box>
  );
}

MultiplyModeContent.propTypes = {
  disabledDays: instanceOf(Object),
  error: string,
  selectedDays: instanceOf(Array),
  setError: func,
  setSelectedDays: func,
  setStartTimeFirst: func,
  setStartTimeSecond: func,
  setEndTimeFirst: func,
  setEndTimeSecond: func,
  startTimeFirst: string,
  startTimeSecond: string,
  endTimeFirst: string,
  endTimeSecond: string,
};