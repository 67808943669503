import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import styles from './style.module.css';
import { WEEK_DAYS } from '../../shared/constants';

const formatKeyName = key => {
  if (!key || typeof key !== 'string') return '';
  return key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
};

const getDayNameById = id => {
  const day = WEEK_DAYS.find(day => day.id === id);
  return day ? day.name : id;
};

const formatValue = (value, fieldKey, details = [], idField = 'id', nameField = 'name') => {
  
  if (fieldKey === 'days' && value) {
    if (Array.isArray(value)) {
      return value.map(getDayNameById).join(', ');
    } else if (typeof value === 'object') {
    
      return Object.keys(value)
        .map(dayKey => {
          const dayId = Number(dayKey); 
          const day = getDayNameById(dayId); 
          const { arrivalStart, arrivalEnd } = value[dayKey];
          return `${day} (Start: ${arrivalStart}, End: ${arrivalEnd})`;
        })
        .join(', ');
    }
  }

  if (fieldKey === 'oneTimeAvailabilities' && value) {
    return value
      .map(item => {
        const startDate = item.effectiveStartDate;
        const startTime = item.startTime;
        const endTime = item.endTime;
        return `Effective Start Date: ${startDate}, Start Time: ${startTime}, End Time: ${endTime}`;
      })
      .join(', ');
  }

  if (fieldKey === 'availabilities' && value) {
    return Object.values(value)
      .filter(availabilities => Array.isArray(availabilities)) 
      .map(availabilities => {
        return availabilities
          .map(item => {
            const startDate = item.effectiveStartDate;
            const startTime = item.startTime;
            const endTime = item.endTime;
            return `Effective Start Date: ${startDate}, Start Time: ${startTime},  End Time: ${endTime}`;
          })
          .join(', ');
      })
      .join('; ');
  }
  

  if (Array.isArray(value)) {
    return value
      .map(item => {
        if (item && item[idField] && item[nameField]) {
          return item[nameField];
        }
        return JSON.stringify(item); 
      })
      .join(', ');
  }

  if (typeof value === 'object' && value !== null) {
    if (value?.name) {
      return value?.name.toString(); 
    }
    return Object.entries(value)
      .map(([key, val]) => `${key}: ${formatValue(val, key, details, idField, nameField)}`)
      .join(', ');
  }

  return value?.toString() || 'None';
};



const getHistoryList = arr => (
  <ul className={styles.bulletList}>
    {arr?.data?.map((item, index) => {
      const key = `${arr.action}-${index}-${arr.id}`;

      if (arr.action === 'edited') {
        return (
            <li 
                key={key} 
                className={`${styles.bulletListItem} ${!item?.newValue || Object.values(item?.newValue).includes('None') 
                    ? styles.removedType 
                    : ''}`} 
            >
                <span className={styles.boldWeight}>Record details edited: </span>
                <ul>
                    {Object.keys(item?.newValue || {}).map(fieldKey => (
                        <li key={fieldKey}>
                            {formatKeyName(fieldKey)} was updated from 
                            <span className={styles.oldTime}>
                                {formatValue(item?.oldValue?.[fieldKey], fieldKey) || 'None'}
                            </span> 
                            to 
                            <span className={styles.newTime}>
                                {formatValue(item?.newValue?.[fieldKey], fieldKey) || 'None'}
                            </span>
                        </li>
                    ))}
                </ul>
            </li>
        );
    }
      
      if (arr.action === 'removed') {
        return (
            <li key={key} className={`${styles.bulletListItem} ${styles.removedType}`}>
                <span className={styles.boldWeight}>Record details removed: </span>
                <ul> 
                    {Object.keys(item?.oldValue || {}).map(fieldKey => (
                        <li key={fieldKey}>
                            {formatKeyName(fieldKey)}: 
                            <span className={styles.removedType}>
                                {formatValue(item?.oldValue?.[fieldKey], fieldKey) || 'None'}
                            </span>
                        </li>
                    ))}
                </ul>
            </li>
        );
    }
      
    if (arr.action === 'added') {
      return (
          <li key={key} className={`${styles.bulletListItem} ${styles.addedType}`}>
              <span className={styles.boldWeight}>Record details added: </span>
              <ul>
                  {Object.keys(item?.newValue || {}).map(fieldKey => (
                      <li key={fieldKey}>
                          {formatKeyName(fieldKey)}: 
                          <span className={styles.newTime}>
                              {formatValue(item?.newValue?.[fieldKey], fieldKey) || 'None'}
                          </span>
                      </li>
                  ))}
              </ul>
          </li>
      );
  }

      return null;
    })}
  </ul>
);

export default function HistoryBulletList({ changes = [] }) {
  return <Box sx={{ paddingLeft: '20px' }}>{getHistoryList(changes)}</Box>;
}

HistoryBulletList.propTypes = {
  changes: instanceOf(Object),
};
