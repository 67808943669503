import { Box, Button } from '@mui/material';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import HorizontalItemsSelector from '../../../../../../../../../../components/HorizontalItemsSelector';
import { checkAvailabilitySchedulePatternWrapperStyles } from '../styles';
import { DAYS_MAP } from '../../../../../../../../../../shared/constants';
import { sortObjectTimeOfDays } from '../../../../../../../../../../shared/utils/common';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function CheckAvailabilitySchedulePattern({
  disabledRecheckAvailability = false,
  disabledDays = {},
  filtersData = {},
  handleChangeDays = () => {},
  handleChangeTimeOfDay = () => {},
  isFiltersLoading = false,
  isLoading = false,
  recheckAvailability = () => {},
  visitData = {},
}) {
  return (
    !isFiltersLoading && (
      <Box sx={checkAvailabilitySchedulePatternWrapperStyles}>
        <HorizontalItemsSelector
          disabled={isLoading}
          disabledDays={disabledDays}
          extendingProperties={{ minWidth: '60px' }}
          gap="8px"
          handleChange={handleChangeDays}
          options={DAYS_MAP.filter(day => day.id < 5).map(day => ({
            name: day.id,
            value: day.shortName,
          }))}
          selectedDays={Object.keys(visitData?.days).map(day => parseInt(day))}
          title="Recurrence Pattern"
        />
        <HorizontalItemsSelector
          disabled={isLoading}
          extendingProperties={{ minWidth: '112px' }}
          gap="8px"
          handleChange={handleChangeTimeOfDay}
          options={sortObjectTimeOfDays(filtersData).map(({ name }) => ({
            name,
            value: name,
          }))}
          selectedDays={visitData?.timesOfDaysDetails?.map(({ name }) => name)}
          title="Time of Day"
        />
        <Button
          color="primary"
          variant="contained"
          onClick={recheckAvailability}
          className="re-check-availability-button"
          disabled={disabledRecheckAvailability}
          disableElevation
        >
          <RefreshIcon sx={{ minWidth: '20px', minHeight: '20px' }} />
        </Button>
      </Box>
    )
  );
}

CheckAvailabilitySchedulePattern.propTypes = {
  disabledRecheckAvailability: bool,
  disabledDays: instanceOf(Object),
  filtersData: instanceOf(Object),
  handleChangeDays: func,
  handleChangeTimeOfDay: func,
  isFiltersLoading: bool,
  isLoading: bool,
  recheckAvailability: func,
  visitData: instanceOf(Object),
};
