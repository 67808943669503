import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';
import { parseVisitTime } from '../../shared/utils/common';

export const SCHEDULER_API_KEY = 'schedulerApi';
const schedulerApi = createApi({
  reducerPath: SCHEDULER_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    getScheduler: builder.query({
      query: (date) => ({
        url: `crud/visits/`,
        method: 'GET',
        params: { 
          index: "date-index",
          pk: "date",
          pkValue: `${date}T00:00:00`,
          sk: "active",
          skValue: "True"
         },
      }),
    }),
    dragAndDrop: builder.mutation({
      query: (payload) => ({
        url: `scheduler/calendar/update`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'scheduler', id: 'ALL' }],
    }),
    checkDragAndDropPossibility: builder.mutation({
      query: (payload) => ({
        url: `check/caregivers`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    updateScheduler: builder.mutation({
      query: (visit) => ({
        url: `crud/visits`,
        method: 'PATCH',
        body: visit,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),

    addCallOut: builder.mutation({
      query: (payload) => ({
        url: `crud/paidTimeOff`,
        method: 'POST',
        body: payload,
      }),
      
    }),
    applyCallOut: builder.mutation({
      query: ({ requestId }) => ({
        url: `callout/${requestId}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: () => [
        { type: 'caregiverAvailability', id: 'ALL' },
        { type: 'scheduler', id: 'ALL' },
        { type: 'allTimeOffRequests', id: 'ALL' },
      ],
    }),
    cancelCallOut: builder.mutation({
      query: ({ requestId }) => ({
        url: `callout/${requestId}/cancel`,
        method: 'POST',
      }),
    }),
    getAllTimeOffRequests: builder.query({
      query: (payload) => ({
        url: 'crud/paidtimeoff',
        method: 'GET',
        params: payload,
      }),
    }),
    getAllCaregiverAvailabilities: builder.query({
      query: ({ caregiver}) => ({
        url: '/crud/caregivers',
        method: 'GET',
        params: { id: caregiver},
      }),
      providesTags: () => [{ type: 'caregiverAvailability', id: 'ALL' }],
    }),
    addOneTimeAvailability: builder.mutation({
      query: (payload) => ({
        url: `crud/caregivers`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'caregiverAvailability', id: 'ALL' },
        { type: 'scheduler', id: 'ALL' },
      ],
    }),
    verifyVisit: builder.mutation({
      query: (payload) => ({
        url: 'crud/visits/',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'scheduler', id: 'ALL' }],
    }),
    reloadSchedulerData: builder.mutation({
      query: (payload) => ({
        url: 'scheduler/schedule',
        method: 'POST',
        body: payload,
      }),
    }),
    checkCarePlanChanges: builder.mutation({
      query: (payload) => ({
        url: 'careplan/change/check',
        method: 'POST',
        body: payload,
      }),
    }),
    getJobsById: builder.mutation({
      query: (jobId) => ({
        url: `visit/info/scheduled/pattern/${jobId}`,
        method: 'GET',
      }),
      transformResponse(response) {
        return parseVisitTime(response);
      },
    }),
  }),
});
export const {
  useApplyCallOutMutation,
  useCancelCallOutMutation,
  useAddCallOutMutation,
  useGetAllCaregiverAvailabilitiesQuery,
  useAddOneTimeAvailabilityMutation,
  useGetSchedulerQuery,
  useUpdateSchedulerMutation,
  useGetJobsByIdMutation,
  useCheckDragAndDropPossibilityMutation,
  useVerifyVisitMutation,
  useReloadSchedulerDataMutation,
  useCheckCarePlanChangesMutation,
  useGetAllTimeOffRequestsQuery,
} = schedulerApi;

export default schedulerApi;
