import { Box } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { timedVisitTypeIcons, visitTypeIcons } from '../../../../../../../../shared/constants';
import NumericInput from '../../../../../../../../components/NumericInput';
import CustomSelect from '../../../../../../../../components/Select';
import { selectVisitTypeStyles } from '../styles';

function VisitTypeBlock({
  changeDuration = () => {},
  changeVisitType = () => {},
  filtersData = {},
  visitParams = {},
}) {
  const displayedVisitTypes = filtersData?.visitTypes
    .filter(
      visitType =>
        visitType.name !== 'Extended Care (4h)' &&
        visitType.name !== 'Extended Care (3h)' &&
        visitType.name !== 'Quick Assist',
    )
    .map(visitType => {
      if (visitType.duration >= 180) {
        return visitType;
      } else if (visitType.duration === 0) {
        return {
          ...visitType,
          name: `${visitType.name} (Custom)`,
        };
      } else {
        const hours = Math.floor(visitType.duration / 60);
        const minutes = visitType.duration % 60;
        return {
          ...visitType,
          name: `${visitType.name} (${
            visitType.duration >= 60
              ? `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`
              : `${minutes}m`
          })`,
        };
      }
    })
    .sort((a, b) => b.name.localeCompare(a.name))
    .sort((a, b) => {
      if (a.duration === 0) {
        return 1;
      } else if (b.duration === 0) {
        return -1;
      } else return a.duration - b.duration;
    });

  const mapVisitTypeUpdate = selectedValue => {
    const actualVisitType = filtersData?.visitTypes.find(
      visitType => visitType.id === selectedValue.id,
    );
    if (actualVisitType) {
      changeVisitType({ ...actualVisitType, field: 'visitType' });
    }
  };

  const hourValue = visitParams?.duration ? Math.floor(visitParams.duration / 60) : 0;
  const minuteValue = visitParams?.duration ? visitParams.duration % 60 : 0;

  const handeHourChange = value => {
    changeDuration(minuteValue + parseInt(value) * 60);
  };

  const handeMinuteChange = value => {
    changeDuration(hourValue * 60 + parseInt(value));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CustomSelect
        addStyles={selectVisitTypeStyles}
        endIcon={
          <img
            style={{ marginRight: '32px' }}
            src={visitTypeIcons[visitParams?.visitType?.name?.toLowerCase()]}
            alt={visitParams?.visitType?.name}
          />
        }
        icons={timedVisitTypeIcons}
        id="visitType"
        isLoading={false}
        isRequired
        options={displayedVisitTypes}
        placeHolder="Visit type"
        selectedValue={visitParams?.visitType ?? ''}
        setValue={mapVisitTypeUpdate}
        withIcon
      />
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <NumericInput
          changeValue={handeHourChange}
          disabled={
            visitParams?.visitTypeDetails?.name !== 'On Demand' &&
            visitParams?.visitTypeDetails?.name !== 'A La Carte'
          }
          isRequired
          label="Hours"
          oneTimeVisit
          step={1}
          value={hourValue}
          additionalStyles={{ width: '120px' }}
        />
        <NumericInput
          changeValue={handeMinuteChange}
          disabled={
            visitParams?.visitTypeDetails?.name !== 'On Demand' &&
            visitParams?.visitTypeDetails?.name !== 'A La Carte'
          }
          isRequired
          label="Minutes"
          oneTimeVisit
          step={5}
          value={minuteValue}
          additionalStyles={{ width: '120px' }}
        />
      </Box>
    </Box>
  );
}

VisitTypeBlock.propTypes = {
  changeDuration: func,
  changeVisitType: func,
  filtersData: instanceOf(Object),
  visitParams: instanceOf(Object),
};

export default React.memo(VisitTypeBlock);
