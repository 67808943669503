import { Box, Chip, Typography } from '@mui/material';
import arrivalIcon from '../../../../img/icons/arrival-window-visit-red.svg';
import {
  calloutBadge,
  calloutWrapper,
  chipEndStyle,
  chipStartStyle,
  durationLabelStyles,
  durationStyle,
} from './styles';
import moment from 'moment';
import PropTypes, { instanceOf } from 'prop-types';
import { formattedTime } from '../../../../pages/Scheduling/constants';

export default function CalloutEvent({ hovered, setHovered, event }) {
  return (
    <Box
      sx={calloutWrapper}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Typography variant="body2" sx={calloutBadge}>
        Callout
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <img width={16} height={16} src={arrivalIcon} alt="arrivalIcon" />
        <Typography sx={{ ml: '6px', color: '#FF402E' }} variant="body2">
          {`${moment(event.start).format('h:mm A')} - ${moment(event.end).format('h:mm A')}`}
        </Typography>
      </Box>
      {hovered && (
        <Box>
          <Chip label={moment(event.start).format('h:mm A')} sx={chipStartStyle} />
          <Chip label={moment(event.end).format('h:mm A')} sx={chipEndStyle} />
          <Box sx={durationStyle}>
            <Typography sx={durationLabelStyles}>
              Duration {formattedTime(moment(event.end).diff(moment(event.start), 'minutes'))}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

CalloutEvent.propTypes = {
  hovered: PropTypes.bool,
  setHovered: PropTypes.func,
  event: instanceOf(Object),
};
