import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Checkbox, Divider, MenuItem, Select, Typography } from '@mui/material';
import { bool, func, instanceOf, node, number, oneOfType, string } from 'prop-types';
import React from 'react';
import SelectRenderValue from './SelectRenderValue';
import {
  customPaperStyles,
  customSelectItem,
  customSelectStyles,
  requiredLabelAsterisk,
} from './style';

export default function CustomSelect({
  addStyles = {},
  disabled = false,
  endIcon = <div />,
  hasSelectAllOption = false,
  icons = null,
  id = '',
  isLoading = false,
  isMultiselect = false,
  isRequired = false,
  options = [],
  placeHolder = '',
  selectedValue = '' || [],
  setValue = () => {},
  withIcon = false,
  onClose = () => {},
}) {
  const handleChange = event => {
    const {
      target: { value },
    } = event;
    if (!isMultiselect) {
      const selectedValue = options.find(option => option.id === value);
      setValue({ field: id, id: selectedValue.id, name: selectedValue.name });
      return '';
    }
    if (isMultiselect && value[value.length - 1] === 'all') {
      setValue({
        field: id,
        value: selectedValue.length === options.length ? [] : options,
      });
      return '';
    }
    let duplicateRemoved = [];
    value.forEach(item => {
      if (duplicateRemoved.findIndex(option => option === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter(x => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setValue({ field: id, value: duplicateRemoved });
    return '';
  };
  const selectedIds = isMultiselect && selectedValue ? selectedValue.map(item => item.id) : '';
  const selectInitialValue = isMultiselect ? [] : '';

  return (
    <Select
      endAdornment={withIcon ? endIcon : null}
      IconComponent={ExpandMoreIcon}
      className={'has-selected-items'}
      disabled={disabled || isLoading}
      displayEmpty
      multiple={isMultiselect}
      onChange={handleChange}
      sx={{
        ...customSelectStyles,
        ...(isRequired ? requiredLabelAsterisk : {}),
        ...addStyles,
      }}
      MenuProps={{
        PaperProps: {
          sx: customPaperStyles,
        },
      }}
      renderValue={() => (
        <SelectRenderValue
          isMultiselect={isMultiselect}
          options={options}
          placeHolder={placeHolder}
          selectedValue={selectedValue}
        />
      )}
      value={isMultiselect ? selectedIds : selectedValue.id || selectInitialValue}
      {...(onClose && { onClose })}
    >
      {hasSelectAllOption && (
        <MenuItem
          disabled={isLoading}
          name="all"
          value="all"
          disableRipple
          className="select-all-item"
          sx={{
            ...customSelectItem,
          }}
        >
          <Checkbox checked={options.length > 0 && options.length === selectedValue?.length} />
          All
        </MenuItem>
      )}

      {hasSelectAllOption && <Divider sx={{ mt: 0 }} />}
      {options?.map((option, index) => (
        <MenuItem
          key={index}
          disabled={isLoading}
          disableRipple
          sx={{ ...customSelectItem }}
          value={option.id}
        >
          {isMultiselect && (
            <Checkbox
              checked={
                !selectedValue.length
                  ? false
                  : selectedValue.findIndex(item => item.id === option.id) >= 0
              }
              sx={{
                padding: '5px 9px',
              }}
            />
          )}
          {withIcon && icons ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                '& img': {
                  margin: 'auto',
                },
              }}
            >
              <Typography sx={{ width: '90%', cursor: 'pointer' }}>{option.name}</Typography>
              {icons[option.name.toLowerCase()] && (
                <img src={icons[option.name.toLowerCase()]} alt={option.name} />
              )}
            </Box>
          ) : (
            <Box>{option.name}</Box>
          )}
        </MenuItem>
      ))}
    </Select>
  );
}

CustomSelect.propTypes = {
  addStyles: instanceOf(Object),
  disabled: bool,
  endIcon: node,
  hasSelectAllOption: bool,
  icons: instanceOf(Object),
  id: string,
  isLoading: bool,
  isMultiselect: bool,
  isRequired: bool,
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: oneOfType([instanceOf(Array), string, instanceOf(Object), number]),
  setValue: func,
  withIcon: bool,
  onClose: func,
};
