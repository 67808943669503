import { COLORS } from '../../../../../../../../shared/styles/Theme';

export const customAccordionStyles = {
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  '&:last-of-type, &.Mui-expanded:last-of-type': {
    borderRadius: 0,
  },
  borderBottom: `1px solid ${COLORS.blue[200]}`,
  borderRadius: 0,
};

export const customAccordionSummaryStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row-reverse',
  padding: 0,
  cursor: 'default',
  margin: '16px 0',
  '&.MuiAccordionSummary-root, &.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '48px',
  },
  '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
    justifyContent: 'space-between',
    margin: 0,
    paddingRight: '20px',
    cursor: 'default',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
};

export const visitAccordionSummaryTitle = {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  ml: '5px',
};
