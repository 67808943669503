import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetAllTimeOffRequestsQuery } from '../../../../api/Scheduler/api';
import moment from 'moment';
import { func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SEARCH_INPUT_DEBOUNCE_DELAY } from '../../../../shared/constants';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import { getNoData } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import CustomTable from '../../../../components/Table';
import FiltersPanel from './components/FiltersPanel';
import {
  DEFAULT_SORTING_MODEL,
  INITIAL_FILTERS,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  TIME_OFF_REQUESTS_STATUSES,
  columns,
} from './constants';
import { SchedulerTimeOffRequestsContext } from './context';
import { customTableStyles } from './styles';
import Loader from '../../../../components/Loader';

export default function PtoAndCallouts({
  teamsArray = [],
  teamsFilter = {},
  setTeamFilter = () => {},
}) {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);

  const [sortOption, setSortOption] = useState(DEFAULT_SORTING_MODEL);
  const [timeOffRequestsFilters, setTimeOffRequestsFilters] = useState(INITIAL_FILTERS);
  const [statusChangingLoading, setStatusChangingLoading] = useState(false);
  const [timeOffRequestsList, setTimeOffRequestsList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeOffRequestsFilters(prevValue => ({
      ...prevValue,
      status: TIME_OFF_REQUESTS_STATUSES?.map(status => status),
      teams: teamsFilter?.teams?.map(team => team?.name),
      datesRange: {
        startDate: moment(),
        endDate: moment().add(1, 'months'),
      },
    }));
  }, [setTimeOffRequestsFilters, teamsFilter]);

  const { data: timeOffRequestsListRaw, error } = useGetAllTimeOffRequestsQuery();

  useEffect(() => {
    setTimeOffRequestsList(timeOffRequestsListRaw);
    setIsLoading(false);
  }, [timeOffRequestsListRaw]);

  useEffect(() => {
    if (timeOffRequestsListRaw) {
      let tempTimeOffRequestsList = timeOffRequestsListRaw.filter(item =>
        timeOffRequestsFilters?.status.includes(item.status),
      );
      tempTimeOffRequestsList = tempTimeOffRequestsList.filter(item =>
        timeOffRequestsFilters?.teams?.length
          ? timeOffRequestsFilters?.teams.some(
              team =>
                item.caregiverPrimaryTeams.map(primTeam => primTeam?.name).includes(team) ||
                item.caregiverSecondaryTeams.map(primTeam => primTeam?.name).includes(team),
            )
          : false,
      );
      if (timeOffRequestsFilters?.caregiver?.length) {
        tempTimeOffRequestsList = tempTimeOffRequestsList.filter(item =>
          item.caregiverName.toLowerCase().includes(timeOffRequestsFilters.caregiver.toLowerCase()),
        );
      }
      if (
        timeOffRequestsFilters?.datesRange?.startDate &&
        timeOffRequestsFilters?.datesRange?.endDate
      ) {
        tempTimeOffRequestsList = tempTimeOffRequestsList.filter(
          item =>
            (moment(item.from).isSameOrAfter(
              timeOffRequestsFilters?.datesRange?.startDate,
              'date',
            ) &&
              moment(item.from).isSameOrBefore(
                timeOffRequestsFilters?.datesRange?.endDate,
                'date',
              )) ||
            (moment(item.to).isSameOrAfter(timeOffRequestsFilters?.datesRange?.startDate, 'date') &&
              moment(item.to).isSameOrBefore(timeOffRequestsFilters?.datesRange?.endDate, 'date')),
        );
      }
      setTimeOffRequestsList([...tempTimeOffRequestsList]);
    }
  }, [timeOffRequestsFilters]);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const debouncedSearch = useDebouncedCallback(e => {
    setTimeOffRequestsFilters({
      ...timeOffRequestsFilters,
      [e.target.name]: e.target.value,
    });
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const memoizedProviderValue = useMemo(
    () => ({
      setStatusChangingLoading,
    }),
    [setStatusChangingLoading],
  );
  const updateSorting = useCallback(item => setSortOption(item[0]), []);

  return (
    <SchedulerTimeOffRequestsContext.Provider value={memoizedProviderValue}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          <FiltersPanel
            debouncedSearch={debouncedSearch}
            filterData={timeOffRequestsFilters}
            setFilterData={setTimeOffRequestsFilters}
            teamsArray={teamsArray}
            teamsFilter={teamsFilter}
            setTeamFilter={setTeamFilter}
          />
          <Box sx={{ padding: '0 40px' }}>
            <CustomTable
              apiRef={apiRef}
              columns={columns}
              customStyles={customTableStyles}
              headerHeight={44}
              noRowsOverlay={() => getNoData({ title: 'No PTO or Callouts found' })}
              pageSize={PAGE_SIZE}
              setSortModelState={updateSorting}
              sortModel={[sortOption]}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              rows={timeOffRequestsList}
            />
          </Box>
        </Box>
      )}
    </SchedulerTimeOffRequestsContext.Provider>
  );
}

PtoAndCallouts.propTypes = {
  teamsArray: instanceOf(Array),
  teamsFilter: instanceOf(Object),
  setTeamFilter: func,
};
