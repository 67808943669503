import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetCaregiverHistoryQuery } from '../../../../api/Caregivers/api';
import { useGetCaregiverTimeOffRequestsHistoryQuery } from '../../../../api/TimeOffRequest/api';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { HISTORY_UPDATE_FORMAT, SEARCH_INPUT_DEBOUNCE_DELAY } from '../../../../shared/constants';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import { mergeArraysWithActivity } from '../../../../shared/utils/common';
import Activity from '../../../../components/Activity';
import { INITIAL_ACTIVITY_FILTERS } from '../../../../components/Activity/constants';
import AlertContext from '../../../../components/Alert';
import { activityOptions } from './constants';

function CaregiverActivity() {
  const { id } = useParams();
  const apiRef = useGridApiRef();
  const [visitsFilters, setVisitsFilters] = useState(INITIAL_ACTIVITY_FILTERS);
  const { setAlert } = useContext(AlertContext);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const {
    data: caregiverHistoryData,
    isLoading: isCaregiverHistoryLoading,
    error: getCaregiverHistoryError,
  } = useGetCaregiverHistoryQuery(id);
  const {
    data: caregiverTimeOffRequestsHistoryData,
    isLoading: isCaregiverTimeOffRequestsHistoryLoading,
    error: getCaregiverTimeOffRequestsHistoryError,
  } = useGetCaregiverTimeOffRequestsHistoryQuery(id);

  useEffect(() => {
    if (getCaregiverHistoryError || getCaregiverTimeOffRequestsHistoryError) {
      const errorData = getCaregiverHistoryError || getCaregiverTimeOffRequestsHistoryError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getCaregiverHistoryError, getCaregiverTimeOffRequestsHistoryError, setAlert]);

  const availabilityData =
    caregiverHistoryData
      ?.filter(history =>
        history.data.some(item => {
          const oldAvailabilities = item.oldValue?.availabilities;
          const newAvailabilities = item.newValue?.availabilities;
          return oldAvailabilities || newAvailabilities;
        }),
      )
      .map(history => ({
        action: history.action,
        dateChanged: history.dateChanged,
        updatedBy: history.updatedBy,
        activity: history.activity,
        data: history.data
          .map(item => {
            const oldAvailabilities = item.oldValue?.availabilities;
            const newAvailabilities = item.newValue?.availabilities;

            const filteredItem = {};

            if (oldAvailabilities) {
              filteredItem.oldValue = { availabilities: oldAvailabilities };
            }

            if (newAvailabilities) {
              filteredItem.newValue = { availabilities: newAvailabilities };
            }

            if (oldAvailabilities || newAvailabilities) {
              filteredItem.name = item?.name;
            }

            return filteredItem.oldValue || filteredItem.newValue ? filteredItem : null;
          })
          .filter(item => item !== null),
      })) || [];

  const filteredHistoryData =
    caregiverHistoryData
      ?.filter(history =>
        history.data.some(item => {
          const oldAvailabilities = item.oldValue?.availabilities;
          const newAvailabilities = item.newValue?.availabilities;
          return !(oldAvailabilities || newAvailabilities);
        }),
      )
      .map(history => ({
        action: history.action,
        dateChanged: history.dateChanged,
        updatedBy: history.updatedBy,
        activity: history.activity,
        data: history.data
          .map(item => {
            const oldAvailabilities = item.oldValue?.availabilities;
            const newAvailabilities = item.newValue?.availabilities;

            const filteredItem = {};

            if (!oldAvailabilities) {
              filteredItem.oldValue = { ...item.oldValue };
            }

            if (!newAvailabilities) {
              filteredItem.newValue = { ...item.newValue };
            }

            if (!(oldAvailabilities || newAvailabilities)) {
              filteredItem.name = item?.name;
            }
            return filteredItem.name || filteredItem.oldValue || filteredItem.newValue
              ? filteredItem
              : null;
          })
          .filter(item => item !== null),
      })) || [];

  const mergedList = [
    ...mergeArraysWithActivity(filteredHistoryData, 'Profile Updates'),
    ...mergeArraysWithActivity(availabilityData, 'Availability'),
    ...mergeArraysWithActivity(caregiverTimeOffRequestsHistoryData, 'PTO&CallOuts'),
  ];

  const updatedEvents = mergedList
    ?.map((item, index) => {
      return {
        ...item,
        dateChanged: moment(item.dateChanged).format(HISTORY_UPDATE_FORMAT),
        id: index + 1,
      };
    })
    .filter(Boolean);

  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateChanged);
    const dateB = new Date(b.dateChanged);
    return dateB - dateA;
  });

  useEffect(() => {
    setFilteredEvents(sortedUpdatedEvents);
  }, [caregiverHistoryData, caregiverTimeOffRequestsHistoryData]);

  useEffect(() => {
    setVisitsFilters(prevValue => ({
      ...prevValue,
      activity: activityOptions.map(name => name),
    }));
  }, [setVisitsFilters]);

  const debouncedSearch = useDebouncedCallback(e => {
    setVisitsFilters({ ...visitsFilters, [e.target.name]: e.target.value });
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const filterEvents = useCallback(() => {
    if (!updatedEvents) return [];

    return updatedEvents.filter(item => {
      const { data, activity } = item;

      if (visitsFilters.activity && !visitsFilters.activity?.includes(activity)) {
        return false;
      }

      return data.some(change => {
        const { oldValue, newValue } = change;

        const combinedValues = [
          ...(oldValue ? Object.values(oldValue) : []),
          ...(newValue ? Object.values(newValue) : []),
        ];

        return combinedValues.some(val => {
          if (typeof val !== 'string') {
            val = String(val);
          }
          return val.toLowerCase().includes(visitsFilters.search.toLowerCase());
        });
      });
    });
  }, [updatedEvents, visitsFilters]);

  useEffect(() => {
    const filtered = filterEvents();
    setFilteredEvents(filtered);
  }, [visitsFilters]);

  return (
    <Box sx={{ mt: '10px' }}>
      <Activity
        filterData={visitsFilters}
        setFilterData={setVisitsFilters}
        debouncedSearch={debouncedSearch}
        data={filteredEvents}
        isLoading={isCaregiverHistoryLoading || isCaregiverTimeOffRequestsHistoryLoading}
        activityOptions={activityOptions.map(name => name)}
        apiRef={apiRef}
      />
    </Box>
  );
}

export default CaregiverActivity;
