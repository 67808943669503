import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import { comparingFullNames, getStatusComponent } from '../../../../../../shared/utils/common';
import VisitCaregiverInfo from '../VisitCaregiverInfo';
import VisitRow from './VisitRow';
import VisitSelectCheckbox from './VisitSelectCheckbox';
import VisitType from './VisitType';

const calculateActualDuration = (row) => {
  const { duration, actualDuration } = row;
  const differenceModule = Math.abs(actualDuration - duration);
  const calculateBackgroundColor = () => {
    if (differenceModule < 6) {
      return COLORS.green[800];
    }
    if (differenceModule > 5 && differenceModule < 11) {
      return COLORS.yellow[800];
    }
    if (differenceModule > 10) {
      return COLORS.red[800];
    }
    return '';
  };
  if (actualDuration > duration) {
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Typography>{actualDuration} min</Typography>
        <Typography sx={{ color: calculateBackgroundColor() }}>
          +{differenceModule} min
        </Typography>
      </Box>
    );
  }
  if (actualDuration < duration) {
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Typography>{actualDuration} min</Typography>
        <Typography sx={{ color: calculateBackgroundColor() }}>
          -{differenceModule} min
        </Typography>
      </Box>
    );
  }
  return <Typography>{actualDuration} min</Typography>;
};
export const reschedulingVisitColumns = (locationId) => [
  {
    field: 'id',
    renderHeader: () => <VisitSelectCheckbox locationId={locationId} />,
    colSpan: (row) => (row.row.type === 'visitNote' ? 9 : 1),
    sortable: false,
    width: 1,
    renderCell: ({ row }) => <VisitRow row={row} />,
  },
  {
    field: 'externalId',
    headerName: 'Job ID',
    colSpan: (row) => (row.row.type === 'visitNote' ? 8 : 1),
    minWidth: 140,
    renderCell: ({ row }) => <Typography>{row.externalId || ''}</Typography>,
    sortable: false,
  },
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    minWidth: 200,
    sortable: false,
    renderCell: ({ value, row }) => <VisitType value={value} row={row} />,
  },
  {
    field: 'client',
    headerName: 'Client',
    minWidth: 240,
    sortable: false,
    renderCell: ({ row }) => (
      <Typography
        variant="body1"
        sx={{
          color: COLORS.blue[700],
          fontWeight: '600',
          fontSize: '14px',
        }}
      >
        {row?.clientName}
      </Typography>
    ),
  },
  {
    field: 'caregiver',
    headerName: 'Caregivers',
    minWidth: 240,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <VisitCaregiverInfo visitData={row} />,
    sortComparator: (v1, v2) => comparingFullNames(v1, v2),
  },
  {
    field: 'scheduledDuration',
    headerName: 'Scheduled Duration',
    sortable: false,
    minWidth: 100,
    maxWidth: 160,
    flex: 1,
    renderCell: ({ row }) => <Typography>{row?.duration} min</Typography>,
  },
  {
    field: 'actualTimeStart',
    headerName: 'Actual Start Time',
    sortable: false,
    minWidth: 110,
    maxWidth: 160,
    flex: 1,
    renderCell: ({ row }) => <Typography>{moment(row?.actualTimeStart).format("h:mm A")}</Typography>,
  },
  {
    field: 'actualDuration',
    headerName: 'Actual Duration',
    sortable: false,
    minWidth: 130,
    maxWidth: 160,
    flex: 1,
    renderCell: ({ row }) => calculateActualDuration(row),
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    width: 160,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {getStatusComponent(row?.status)}
      </Box>
    ),
  },
];
