import { Box, Typography } from '@mui/material';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { userInfoFieldsMap } from './constants';
import { detailsInfoTitle } from './styles';
import { getInfoComponent } from './utils';

function InfoView({ isEdit = false, clientData = {} }) {
  return (
    <>
      {userInfoFieldsMap.map(({ name, type, key }) => (
        <Box key={name} sx={{ display: 'flex', marginBottom: '24px' }}>
          <Typography
            sx={{
              ...detailsInfoTitle,
              alignItems: key === 'blockedCaregivers' ? 'center' : 'baseline',
            }}
          >
            {name}:
          </Typography>
          {getInfoComponent({
            type,
            value: key === 'all' ? clientData : clientData[key],
            editMode: isEdit,
          })}
        </Box>
      ))}
    </>
  );
}
InfoView.propTypes = {
  isEdit: bool,
  clientData: instanceOf(Object),
};

export default React.memo(InfoView);
