import { Box } from '@mui/material';
import warningIcon from '../../../../../../../../img/icons/warning-icon.svg';
import moment from 'moment';
import { TimeOffRequestsContext } from '../../../../../../../../pages/CaregiverDetails/context';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useContext } from 'react';
import {
  H12_TIME_FORMAT,
  MAX_TASK_DETAILS_FIELD_LENGTH,
  SHORT_DATE_FORMAT,
} from '../../../../../../../../shared/constants';
import CustomDialogAlert from '../../../../../../../../components/CustomDialogAlert';
import ErrorAlert from '../../../../../../../../components/ErrorAlert';
import MultilineInput from '../../../../../../../../components/Inputs/MultilineInput';
import CustomSelect from '../../../../../../../../components/Select';
import CustomCheckbox from '../Checkbox';
import ChooseDatesRows from './ChooseDatesRows';
import ContentWrapper from './Wrapper';

export default function SettingsView({
  error = null,
  isStartDateExpired = false,
  requestData = {},
  setError = {},
  setRequestData = () => {},
}) {
  const { timeOffRequestsTypes } = useContext(TimeOffRequestsContext);

  const onSelectChange = params => {
    const thisRequestType = timeOffRequestsTypes.ptoTypes.find(ptoType => ptoType.id === params.id);
    setRequestData({
      ...requestData,
      ptoType: thisRequestType.id,
      ptoTypeDetails: thisRequestType,
    });
  };

  const changeDetails = e => {
    if (e.target.value.length <= MAX_TASK_DETAILS_FIELD_LENGTH) {
      setRequestData({ ...requestData, [e.target.id]: e.target.value });
    }
  };

  const checkDatesValidation = ({
    isAllDay,
    requestDateStart,
    requestDateEnd,
    requestTimeStart,
    requestTimeEnd,
  }) => {
    if (error) {
      setError(null);
    }
    if (isAllDay && requestDateEnd.isBefore(requestDateStart, 'day')) {
      setError('You have been inputted wrong time off request dates');
      return '';
    }
    if (!requestDateStart || !requestDateEnd || !requestTimeStart || !requestTimeEnd) {
      return '';
    }
    const startDate = moment(
      `${requestDateStart.format(SHORT_DATE_FORMAT)} ${requestTimeStart.format(H12_TIME_FORMAT)}`,
    );
    const endDate = moment(
      `${requestDateEnd.format(SHORT_DATE_FORMAT)} ${requestTimeEnd.format(H12_TIME_FORMAT)}`,
    );
    if (endDate.isSameOrBefore(startDate, 'minutes')) {
      setError('You have been inputted wrong time off request dates or time');
      return '';
    }
    return '';
  };

  const setAllDay = e => {
    if (requestData.requestDateStart && requestData.requestDateEnd) {
      checkDatesValidation({
        isAllDay: e.target.checked,
        requestDateStart: requestData.requestDateStart,
        requestDateEnd: requestData.requestDateEnd,
      });
    }
    setRequestData({
      ...requestData,
      allDay: e.target.checked,
      ...(e.target.checked && {
        requestTimeEnd: null,
        requestTimeStart: null,
      }),
    });
  };

  const changeRequestDate = ({ requestDateStart, requestDateEnd }) => {
    const currentDayOfTheWeek = moment().day();
    const numberOFDaystoAdd = 7 - currentDayOfTheWeek + 7;
    const optomizedPeriodEndDate = moment()
      .add(numberOFDaystoAdd, 'days')
      .set({ hour: 23, minute: 59, second: 59 });
    let newStatus = 'Pending';
    setRequestData({ ...requestData, requestDateStart, requestDateEnd, status: newStatus });
    if (requestDateStart && requestDateEnd) {
      checkDatesValidation({
        isAllDay: requestData?.allDay,
        requestDateStart,
        requestDateEnd,
        requestTimeStart: requestData.requestTimeStart,
        requestTimeEnd: requestData.requestTimeEnd,
      });
    }
  };

  const changeRequestTime = ({ requestTimeStart, requestTimeEnd }) => {
    const checkedStartTime = moment(requestTimeStart).isValid() ? requestTimeStart : null;
    const checkedEndTime = moment(requestTimeEnd).isValid() ? requestTimeEnd : null;

    setRequestData({
      ...requestData,
      requestTimeStart: checkedStartTime,
      requestTimeEnd: checkedEndTime,
    });
    if (requestTimeStart && requestTimeEnd) {
      checkDatesValidation({
        isAllDay: requestData?.allDay,
        requestDateStart: requestData.requestDateStart,
        requestDateEnd: requestData.requestDateEnd,
        requestTimeStart: checkedStartTime,
        requestTimeEnd: checkedEndTime,
      });
    }
  };

  return (
    <ContentWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <CustomSelect
          id="requestType"
          isLoading={false}
          isMultiselect={false}
          options={timeOffRequestsTypes.ptoTypes}
          placeHolder="Request Type"
          selectedValue={requestData?.ptoTypeDetails?.id}
          setValue={onSelectChange}
        />
        <MultilineInput
          changeDetails={changeDetails}
          id="details"
          label="Details"
          maxLength={MAX_TASK_DETAILS_FIELD_LENGTH}
          value={requestData?.details}
          showHelperText
        />
        <CustomCheckbox checked={!!requestData?.allDay} label="All day" onValueChange={setAllDay} />
        {error && <ErrorAlert customStyles={{ margin: 0 }} error={error || ''} />}
        {isStartDateExpired && (
          <CustomDialogAlert
            icon={warningIcon}
            showAlert={isStartDateExpired}
            title="The original date cannot be processed because it is today or in the past. Please specify a new date."
            type="warning"
          />
        )}
        <ChooseDatesRows
          changeRequestDate={changeRequestDate}
          changeRequestTime={changeRequestTime}
          requestData={requestData}
        />
      </Box>
    </ContentWrapper>
  );
}

SettingsView.propTypes = {
  error: string,
  isStartDateExpired: bool,
  requestData: instanceOf(Object),
  setError: func,
  setRequestData: func,
};
