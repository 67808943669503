import { Box, Stack } from '@mui/material';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import VisitInfoManagement from '../VisitInfoManagement';
import VisitTasksManagement from '../VisitTasksManagement';
import VisitTimeManagement from '../VisitTimeManagement';
import { visitInfoManagementWrapper } from '../VisitTimeManagement/styles';
import CaregiverAndDate from './CaregiverAndDate';

export default function VisitDetailsInfo({
  addVisitTask = () => {},
  initialVisitData = {},
  setShowedTasksType = () => {},
  showedTasks = [],
  showedTasksType = '',
  temporaryVisitData = {},
  updateTemporaryVisitData = () => {},
  isLoadingConfigs = false,
}) {
  return (
    <Stack>
      <CaregiverAndDate
        disabledAll
        initialVisitData={initialVisitData}
        isExactTime={temporaryVisitData?.isExactTime}
        team={temporaryVisitData?.team}
        updateTemporaryVisitData={updateTemporaryVisitData}
        visitDate={temporaryVisitData?.date}
        visitStatus={temporaryVisitData?.status}
        teamId={temporaryVisitData?.team}
        teamName={temporaryVisitData?.teamDetails?.name}
        caregiverId={temporaryVisitData?.caregiverId}
        caregiverName={temporaryVisitData?.caregiverName}
        isPreferredCaregiver={temporaryVisitData?.isPreferredCaregiver}
        shadowCaregiverId={temporaryVisitData?.shadowCaregiverId}
        shadowCaregiverName={temporaryVisitData?.shadowCaregiverName}
      />
      <Box sx={visitInfoManagementWrapper}>
        <VisitTimeManagement
          actualDuration={temporaryVisitData?.actualDuration}
          actualTimeEnd={temporaryVisitData?.actualTimeEnd}
          actualTimeStart={temporaryVisitData?.actualTimeStart}
          arrivalTime={temporaryVisitData?.arrivalTime}
          arrivalTimeEnd={temporaryVisitData?.arrivalEnd}
          arrivalTimeStart={temporaryVisitData?.arrivalStart}
          duration={temporaryVisitData?.duration}
          isExactTime={temporaryVisitData?.isExactTime}
          updateTemporaryVisitData={updateTemporaryVisitData}
          visitStatus={temporaryVisitData?.status}
          date={temporaryVisitData?.date}
        />
        <VisitInfoManagement
          disabled={!initialVisitData?.caregiverId}
          updateTemporaryVisitData={updateTemporaryVisitData}
          visitNotes={temporaryVisitData?.visitNotes}
          visitDetails={temporaryVisitData?.visitDetails}
        />
      </Box>
      <VisitTasksManagement
        addVisitTask={addVisitTask}
        notAllocated={!initialVisitData?.caregiverId}
        tasks={showedTasks}
        title={temporaryVisitData?.visitDetails?.name}
        visitStatus={temporaryVisitData?.status}
        showedTasksType={showedTasksType}
        setShowedTasksType={setShowedTasksType}
        isLoadingConfigs={isLoadingConfigs}
      />
    </Stack>
  );
}

VisitDetailsInfo.propTypes = {
  addVisitTask: func,
  initialVisitData: instanceOf(Object),
  setShowedTasksType: func,
  showedTasks: instanceOf(Array),
  showedTasksType: string,
  temporaryVisitData: instanceOf(Object),
  updateTemporaryVisitData: func,
  isLoadingConfigs: bool,
};
