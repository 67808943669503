import { Box } from '@mui/material';
import moment from 'moment';
import { bool, func, instanceOf, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { H12_TIME_FORMAT } from '../../../../../shared/constants';
import CustomTimePicker from '../../../../../components/CustomDateAndTimePickers/CustomTimePicker';

export default function ActualTimes({
  changeTimeRange = () => {},
  customStyles = [],
  disabledStartTime = false,
  disabledEndTime = false,
  endTimeLabel = '',
  endTimeValue = '',
  gap = 0,
  isRequired = false,
  setError = () => {},
  startTimeLabel = '',
  startTimeValue = '',
}) {
  const [startValue, changeStartValue] = useState(moment(startTimeValue, H12_TIME_FORMAT));
  const [endValue, changeEndValue] = useState(
    endTimeValue ? moment(endTimeValue, H12_TIME_FORMAT) : undefined,
  );
  useEffect(() => {
    changeStartValue(moment(startTimeValue, H12_TIME_FORMAT));
    changeEndValue(endTimeValue ? moment(endTimeValue, H12_TIME_FORMAT) : undefined);
  }, [endTimeValue, startTimeValue]);

  const updateArrivalWindow = ({ startTime, endTime }) => {
    const arrivalRange = {
      newStartTime: startTime.isValid() ? startTime.format(H12_TIME_FORMAT) : '',
      newEndTime: endTime && endTime.isValid() ? endTime.format(H12_TIME_FORMAT) : null,
    };
    changeTimeRange(arrivalRange);
  };

  const onStartChange = ({ newValue, endTime }) => {
    updateArrivalWindow({ startTime: newValue, endTime });
    if (newValue.isSameOrAfter(endTime)) {
      setError('You have been inputted wrong actual time');
      return false;
    }
    setError(null);
    return false;
  };

  const onEndChange = ({ newValue, startTime }) => {
    updateArrivalWindow({ startTime, endTime: newValue });
    if (newValue.isSameOrBefore(startTime)) {
      setError('You have been inputted wrong arrival window time');
      return false;
    }
    setError(null);
    return false;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        mb: '10px',
        gap: gap ? `${gap}px` : '20px',
        width: '100%',
      }}
    >
      <CustomTimePicker
        isRequired={isRequired}
        customStyles={customStyles}
        disabled={disabledStartTime}
        label={startTimeLabel}
        onChangeValue={(newValue, context) =>
          context.validationError ? {} : onStartChange({ newValue, endTime: endValue })
        }
        timeMinutesStep={1}
        value={startValue}
        views={['hours', 'minutes']}
        withIcon
      />
      <CustomTimePicker
        isRequired={isRequired}
        customStyles={customStyles}
        disabled={disabledEndTime}
        label={endTimeLabel}
        isPMPlaceholder
        onChangeValue={(newValue, context) => {
          return context.validationError ? {} : onEndChange({ newValue, startTime: startValue });
        }}
        timeMinutesStep={1}
        value={endValue ?? undefined}
        views={['hours', 'minutes']}
        withIcon
      />
    </Box>
  );
}

ActualTimes.propTypes = {
  changeTimeRange: func,
  customStyles: instanceOf(Array),
  disabledStartTime: bool,
  disabledEndTime: bool,
  endTimeLabel: string,
  endTimeValue: string,
  gap: number,
  isRequired: bool,
  setError: func,
  startTimeLabel: string,
  startTimeValue: string,
};
